import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import FeaturedNews from "./../compornents/FeaturedNews";
import NewsGrid from "./../compornents/NewsGrid";
import { api } from "../Settings";

class News extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentDidMount () {
        axios.get(api.getNews).then(response => {

        //console.log(response);
          this.setState({
            data: response.data
          })
        })
    }

    render(){
        const { data } = this.state;
        return (
            <HelmetProvider>
                <div>
                <Helmet>
                <title> DPN - News </title>
                </Helmet>
                <Header/>
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8 relative z-0">
                    <div className="lg:w-3/4">
                        {
                            data.components &&
                            <FeaturedNews data={data.components.FeaturedNews} />
                        }
                        <NewsGrid />
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
                <Footer />
                </div>
            </HelmetProvider>
        )
    }
}

export default News;
