import React, { Component } from 'react';
import {  FaRegComments, FaRegHandshake } from "react-icons/fa";
import { MdOutlineExplore,MdPeople, MdOutlineForum } from "react-icons/md";
import { BiImages } from "react-icons/bi";
import { BsNewspaper, BsCalendarEvent } from "react-icons/bs";
import {GiWorld} from "react-icons/gi";
import { AiOutlineLogout, AiOutlineHome } from "react-icons/ai";
import { NavLink  } from "react-router-dom";

import logo from './../assets/logo_mini.png';
import { GrCertificate, GrRestaurant } from 'react-icons/gr';


const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
let userName = userData && userData.details.first_name ? `${userData.details.first_name}` : ''; 
let userImage = userData && userData.base_account_url ? `${userData.base_account_url}/getUserAvatar.php?userid=${userData.details.user_id}&access_token=${ASSCESS_TOKEN}` : logo; 

const navigation = [
    {
        label: "Home",
        url: "/",
        icon: <AiOutlineHome />
    },
    {
        url: "/explore-dilmah",
        label: "Explore Dilmah",
        icon: <MdOutlineExplore />
    },
    {
        url: "/partners",
        label: "Partners",
        icon: <FaRegHandshake />
    },
    {
        url: "/collections",
        label: "Digital Assets",
        icon:  <BiImages />
    },
    {
        url: "/dilmah-universe",
        label: "Dilmah Universe",
        icon:  <GiWorld />
    },
   /*  {
        url: "/people",
        label: "People",
        icon: <MdPeople />
    }, */
    {
        url: "/news",
        label: "News",
        icon: <BsNewspaper />
    },
    {
        url: "/events",
        label: "Events",
        icon: <BsCalendarEvent />
    },
    {
        url: "/forum",
        label: "Forum",
        icon: <MdOutlineForum />
    },
    {
        url: "/recipe",
        label: "Recipe",
        icon: <GrRestaurant className='stroke-gray-500'  />
    },
    {
        url: "/awards-and-certification",
        label: "Awards & Certification",
        icon: <GrCertificate className='stroke-gray-500'  />
    },
    {
        url: "/faq",
        label: "FAQs",
        icon: <FaRegComments />
    },
    {
        url: "/logout",
        label: "Log Out",
        type: "action",
        icon: <AiOutlineLogout />
    },
  ]; 
  

class Drawer extends Component {

    constructor (props) {
        super(props)
        this.state = {
            show: props.show
        }

        this.closeDrawer = this.closeDrawer.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {show: props.show };
    }

    
    closeDrawer() {
        this.props.drawerCallback({ show: false });
        this.setState({ show: false });
    }

    render() {


        return (
            <div id="drawer-navigation" className={`fixed z-40 h-screen p-4 overflow-y-auto bg-white w-80  transition-transform left-0 top-0   ${this.state.show ? "translate-x-0" : "-translate-x-full"}`} tabIndex="-1" aria-labelledby="drawer-navigation-label"> 
                <div className="flex items-center mb-4">
                    <div className="nav-logo min-w-[64px] w-[64px] h-[64px] rounded-full bg-no-repeat bg-contain border border-primary bg-center"  style={{ backgroundImage: `url("${userImage}")` }}></div>
                    <h5 className="text-base font-semibold text-body ml-4">{userName}</h5>
                </div>
            <button onClick={this.closeDrawer}  type="button" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation" className="text-primary bg-transparent hover:bg-gray-200 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center" >
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                <span className="sr-only">Close menu</span>
            </button>
        <div className="py-4 overflow-y-auto">
            <ul className="space-y-2">
                {navigation.map((item, index) => {
                    return (
                        <NavLink  to={item.url} key={index}  className={({ isActive }) =>  isActive ? 'text-primary  flex items-center p-2 text-base font-normal rounded-lg group bg-primary/20' : 'flex items-center p-2 text-base font-normal text-gray-900 rounded-lg hover:bg-gray-100 group' }>
                            <div className="flex justify-center">
                            <div className="menu-icon text-2xl">
                                <div className="w-6 h-6 text-gray-500 transition duration-75">
                                    {item.icon}
                                </div>
                            </div>
                            </div>
                            <div className="ml-3">
                            {item.label}
                            </div>
                        </NavLink>
                    );
                })}
            </ul>
        </div>
        </div>
        );
    }
}
  
export default Drawer;