import React, { Component } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation  } from "react-router-dom";
import {  BiChevronsRight } from "react-icons/bi";
import {  BsPlayCircle, BsFileEarmarkMusic, BsPlus, BsFillBellFill, BsPinAngle, BsPinAngleFill } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroller';
import { api } from "../Settings";
import ApiError from "./../compornents/ApiError";
import EmptyData from "./EmptyData";
import Loader from "./../compornents/Loader";
import { FiEdit } from 'react-icons/fi';

const dayjs = require('dayjs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}

class PartnerFeeds extends Component {

    constructor (props) {
        super(props);
        this.state = {
            data: {},
            settings: {},
            feeds: [],
            total:0,
            hasMore: true,
            loading: false,
            isLoading: false,
            isError: false,
            loadedCount:0,
            pageSize: 25
        }

        this.getDiff = this.getDiff.bind(this);
        this.getData = this.getData.bind(this);
    }

    componentDidMount () {

        const { navigation } = this.props;
        const { pathname  } = this.props.location;
        const that = this;
        that.setState({ isLoading: true, isError: false })

        this.getData(0, true);

        axios.get(api.getPartnerFeeds).then(response => {
          this.setState({
            data: response.data
          })
          
        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }

            

        });

    }

    getData(count, reset){

        const that = this;
        const { navigation } = this.props;
        const { pathname  } = this.props.location;

           
        if (this.state.loading) {
            return;
        }

        if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
            this.setState({ hasMore: false })
            return;
        }else{
            this.setState({ hasMore: true })
        }
        
        this.setState({ loading: true })

        if(count === 0 ){
            this.setState({ loadedCount: 0  })
        }

        if(reset){
          that.setState({ isLoading: true, isError: false })
        }

        axios.get(api.getPartnerFeedItems, { params: { lt:  this.state.pageSize,  st: this.state.loadedCount, settings: this.state.settings } }).then( response => {
           /*  this.setState({
                feeds:  response.data.items
            }) */

            //Set total
            const tot = response.data.total ? response.data.total : response.data.items.length;
            this.setState({ total: tot });
            this.setState({ settings: response.data.settings });

            this.setState({ isLoading: false });

            this.setState({ feeds: [ ...this.state.feeds, ...response.data.items ] },() => {

                this.setState({ isLoading: false, loading: false });
  
            //    if(!reset){
                    const newCount = this.state.pageSize + this.state.loadedCount;
                    //console.log(newCount);
                    this.setState({ loadedCount: newCount })
             //   }
  
            });
        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }

            
            that.setState({ isLoading: false, isError: true , loading: false })
        });
        
    }

    getDiff(param) {

        if( dayjs(param).isValid()){
            const evtDate = dayjs(param);
            const today = dayjs();
    
            let hours = today.diff(evtDate, 'hours');
            const days = Math.floor(hours / 24);
            hours = hours - (days * 24);
    
            if(days === 0){
                return ( <>{hours} hours ago</>);
            }else{
                return dayjs(param).format('MMMM DD, YYYY h:mm A');
            }
        }
    }

    
    render() {
        const { data, feeds, isLoading, isError, total, hasMore } = this.state;
        return (
            <>
             { data &&
                    <div className="text-body">
                        <h3 className="text-2xl md:text-3xl mt-4 md:mt-0 mb-8 font-semibold">{data.title}</h3>
                        <div className="items  md:grid md:gap-2 grid-cols-2 lg:block">
                {isLoading ? <Loader /> : <>     
                    {
                        total === 0 && !isError &&
                        <EmptyData/>
                    }
                    {                
                        feeds && feeds.length > 0 && total > 0 &&
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.getData}
                            initialLoad={false}
                            hasMore={hasMore}
                            loader={<div className="loader" key={0}><Loader /></div>}
                        >
                            {   
                                feeds.map((item, index) => {
                                    const thumbnails = item.thumbnails;
                                    return (
                                    <div key={index} className="mb-8 relative ">
                                        <div className={`flex items-center mb-4  ${ item.pin ? " rounded-l-[48px] border-t bg-primary/10" : ""}`}>
                                            {
                                                item.type && item.type === 'dmc' && !item.avatar &&
                                                <div className="user-icon min-w-[50px] w-[50px] h-[50px] rounded-full bg-primary mr-3 bg-no-repeat bg-cover bg-center text-white justify-center flex items-center text-xl"><BsFillBellFill/></div>
                                            }
                                            {
                                                (!item.type || item.type !== 'dmc') &&
                                                <div className="user-icon min-w-[50px] w-[50px] h-[50px] rounded-full bg-gray-500 mr-3 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${item.avatar}&access_token=${ASSCESS_TOKEN}")` }}></div>
                                            }
                                            <span className="block">
                                                <span className="user-name font-semibold text-base ">{item.author}</span>
                                                <span className="block updated-time text-xs"> {this.getDiff(item.updatedTime)}</span>
                                            </span>
                                        </div>
                                        <div className="item-content text-sm">
                                                <span className="my-2 font-semibold text-base  md:text-lg">{item.title}</span>
                                                <div>
                                                    <p dangerouslySetInnerHTML={{__html : item.description}}></p>
                                                </div>
                                                {item.cover_image &&
                                                <div className={`mt-4`}>
                                                    <span className="bg-primary/20 bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden" >
                                                        <img alt="" src={`${item.cover_image}&access_token=${ASSCESS_TOKEN}&width=480&height=0`} className="w-full h-auto"/>
                                                    </span>
                                                </div>
                                                }
                                                {!item.cover_image && thumbnails && thumbnails.length > 0 &&
                                                <div className={`mt-4 grid  gap-2  ${thumbnails.length >= 2 ? "grid-cols-2" : "grid-cols-1"}`}>
                                                    { thumbnails.length === 3 && 
                                                            thumbnails.map((thumb, index) => {
                                                                
                                                                return (
                                                                index <= 1 &&
                                                                <span key={index} className="bg-primary/20 h-0 pt-full bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden" style={{ backgroundImage: `url("${thumb.path}&access_token=${ASSCESS_TOKEN}&width=480&height=0")` }}>
                                                                    {thumb.type == "video" &&
                                                                        <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                            <BsPlayCircle className='text-white text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>
                                                                        </span>
                                                                    }
                                                                    {thumb.type == "audio" &&
                                                                        <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                            <BsFileEarmarkMusic className='text-white text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>
                                                                        </span>
                                                                    }
                                                                    {index === 1 &&
                                                                        <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                        <span className='text-white text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center'>{item.mediaCount}<BsPlus className='text-4xl'/></span>
                                                                        </span>
                                                                    }
                                                                </span>
                                                                );
                                                            })
                                    
                                                    }
                                                    { thumbnails.length !== 3 && thumbnails.length !== 1 && 
                                                            thumbnails.map((thumb, index) => {
                                                                
                                                                return (
                                                                index <= 3 &&
                                                                <span key={index} className="bg-primary/20 h-0 pt-full bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden" style={{ backgroundImage: `url("${thumb.path}&access_token=${ASSCESS_TOKEN}&width=480&height=0")` }}>
                                                                {/*  {thumb.type == "video" &&
                                                                        <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                            <BsPlayCircle className='text-white text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>
                                                                        </span>
                                                                    }
                                                                    {thumb.type == "audio" &&
                                                                        <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                            <BsFileEarmarkMusic className='text-white text-5xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'/>
                                                                        </span>
                                                                    } */}
                                                                    {index === 3 &&
                                                                        <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                        <span className='text-white text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center'>{item.mediaCount}<BsPlus className='text-4xl'/></span>
                                                                        </span>
                                                                    }
                                                                </span>
                                                                );
                                                            })
                                                    }
                                                    {  thumbnails.length === 1 && 
                                                            thumbnails.map((thumb, index) => {
                                                                
                                                                return (
                                                                index <= 3 &&
                                                                <span key={index} className="bg-primary/20 bg-no-repeat bg-cover bg-center rounded-lg relative overflow-hidden" >
                                                                <img alt="" src={`${thumb.path}&access_token=${ASSCESS_TOKEN}&width=480&height=0`} className="w-full h-auto"/>
                                                                </span>
                                                                );
                                                            })
                                                    }
                                                </div>
                                                }
                                                {
                                                    item.id &&
                                                    <div className="actions flex items-center mt-4">
                                                    {/*   <a href="#" className="share text-sm flex items-center mr-8" ><GiShare className="text-primary mr-1" />{data.shareLabel}</a>
                                                        <a href="#" className="download text-sm flex items-center"><FiDownload  className="text-primary mr-1"  />{data.downloadLabel}</a> */}
                                                        <Link to={`/collections/${item.id}`} className="download text-sm flex items-center text-primary">Read more <BiChevronsRight className='text-lg'/></Link>
                                                    </div>
                                                }
                                        </div>
                                        {
                                            item.pin &&
                                            <BsPinAngleFill className='absolute -top-2 -right-2 text-primary text-lg' />
                                        }
                                        {
                                            EDIT_ACCESS && item.editLink &&
                                            <a href={item.editLink.replace("ITEM_ID", item.hash)}  target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                                        }
                                    </div>
                                    );
                                })
                            }
                        </InfiniteScroll>
                    }
                </>}
                {isError && <ApiError />}
                </div>
            </div>
            }
            </>
        );
    }
}

export default withParams(PartnerFeeds);