import React, { Component, createRef } from 'react';
import Field from './Field';
import axios from 'axios';
import { NavLink  } from "react-router-dom";
import { api } from "../Settings";
import ReCAPTCHA  from "react-google-recaptcha";
import Loader from "./../compornents/Loader";
import logo from './../assets/logo.png';
import { BiLoaderAlt } from 'react-icons/bi';
import { MdArrowBackIos } from 'react-icons/md';


class Form extends Component {
    state = {
        fieldsorg: [
            {
                id: 'name',
                type: 'input',
                label: 'Enter name',
                config: {
                    dataType: 'text',
                    placeholder: 'Your Name'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                errorMessage: 'Enter Valid data',
                touched: false
            },
            {
                id: 'street',
                type: 'textarea',
                label: 'Enter Address',
                config: {
                    dataType: 'text',
                    placeholder: 'Street'
                },
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                errorMessage: 'Enter Valid data',
                touched: false
            },
            {
                id: 'zipCode',
                type: 'input',
                label: 'Enter zipcode',
                config: {
                    dataType: 'text',
                    placeholder: 'ZIP Code'
                },
                value: '',
                validation: {
                    required: true,
                    minLength: 5,
                    maxLength: 5,
                },
                valid: false,
                errorMessage: 'Enter Valid data',
                touched: false
            },
            {
                id: 'country',
                type: 'select',
                label: 'Enter country',
                config: {
                    dataType: 'text',
                    placeholder: 'Country'
                },
                options: [
                    {
                        value: 'USA',
                        displayValue: 'United state of america'
                    },
                    {
                        value: 'UK',
                        displayValue: 'United kingdom'
                    },
                    {
                        value: 'IN',
                        displayValue: 'India'
                    }
                ],
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                errorMessage: 'Enter Valid data',
                touched: false
            },
            {
                id: 'email',
                type: 'input',
                label: 'Enter email',
                config: {
                    dataType: 'email',
                    placeholder: 'Your E-Mail'
                },
                value: '',
                validation: {
                    required: true,
                   
                    isEmail: true
                },
                valid: false,
                errorMessage: 'Enter Valid data',
                touched: false
            },
        ],
        fields: [
            {
                id: '1',
                name: 'fn',
                label: 'First name',
                type: 'text',
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            {
                id: '2',
                name: 'ln',
                label: 'Last name',
                type: 'text',
                value: '',
                validation: {
                    required: true
                },
                valid: false,
                touched: false
            },
            {
                id: '3',
                name: 'ue',
                label: 'E-mail',
                type: 'email',
                value: '',
                validation: {
                    required: true,
                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                },
                valid: false,
                touched: false
            },
            {
                id: 'country',
                label: 'Country',
                type: 'select',
                options: [],
                value: '',
                validation: {
                    required: false
                },
                touched: false
            },
            {
                id: '4',
                name: 'psw',
                label: 'Password',
                type: 'password',
                value: '',
                validation: {
                    pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                    required: true
                },
                valid: false,
                touched: false,
                errorMessage: 'Use at least 8 characters, Password should contain numbers and letters.'
            },
            {
                id: '5',
                name: 'cpsw',
                label: 'Confirm password',
                type: 'password',
                value: '',
                validation: {
                    pattern: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                    required: true
                },
                valid: false,
                touched: false
            },

        ],
        settings: null,
        isSubmitting: false,
        isLoading: false,
        formValid: false,
        successMessage: '',
        apiError: '',
        token: null,
        countries: []
    }

    constructor (props) {
        super(props);
        this.recaptchaRef = createRef();
    }

    componentDidMount() {

        
        
        const that = this;
        this.setState({ isLoading: true });

        axios.get(api.getSettings).then(response => {
            console.log( response.data);
            this.setState({
              settings: response.data
            })
        })



        axios.get(api.getCountryList)
        .then(response => {
            const countries = response.data.items;
            console.log(countries);

            this.state.fields.map((field, index) => {
                if(field.id === 'country'){
                    field.options = countries;
                    console.log(field);
                }

            })

            this.setState({ countries: countries });
            setTimeout(()=>{
                console.log(this.state);
            }, 3000)
        
        }).catch(function (error) {
            that.setState({ isLoading: false })
        });


        axios.get(api.getRegistrationAddtionalFields)
        .then(response => {
            const customFields = response.data.data;
            //setTimeout(()=>{
                this.setState({ fields : [ ...this.state.fields, ...customFields ]  });
                this.setState({ isLoading: false });
            //}, 3000)
        
        }).catch(function (error) {
            that.setState({ isLoading: false })
        });

    }

    fieldChange = (event, field, index) => {

       
        const updatedField = { ...field };
        updatedField.value = event.target.value;
        updatedField.valid = this.checkValidity(updatedField);
        const updatedFields = [...this.state.fields];
        updatedFields.splice(index, 1, updatedField);
        let formValid = true;
        for (let field of updatedFields) {
            if (!field.valid) {
                formValid = false;
            }
        }
        this.setState({
            fields: updatedFields,
            formValid: formValid
        })

        console.log( field);
    }

    checkValidity = (field) => {
        const rules = field.validation;
        const value = field.value;
        let isValid = true;

        console.log(rules); 
        if (!rules) {
            return true;
        }
        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }
        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }
        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }
        if (rules.pattern) {
            isValid = rules.pattern.test(value) && isValid
        }
        if (field.name === 'cpsw') {
          
            const pwdItem = this.state.fields.filter((field, index) => field.name === 'psw');
            let currPwd = pwdItem[0].value;
            console.log(pwdItem);
            isValid = (rules.pattern.test(value) && value === currPwd)  && isValid
        }
        return isValid;
    }
    fieldBlur(event, field, index) {
        if (field.touched) {
            return;
        }
        const updatedField = { ...field };
        updatedField.touched = true;
        updatedField.valid = this.checkValidity(updatedField);
        const updatedFields = [...this.state.fields];
        updatedFields.splice(index, 1, updatedField);
        this.setState({
            fields: updatedFields,
        })
    }
    onSubmit = (event) => {

        if(this.state.formValid){
            this.sendData();
        }else{
            this.state.fields.map((field, index) => {
                field.touched = true;

            });

            this.setState({ fields : this.state.fields })
            console.log( this.state.fields);
        }
        event.preventDefault();
      
    }

    handleSubmit  =  (event) =>  {

        event.preventDefault();
  
        if (this.validate()) {
         
        }
  
      }
  
    sendData  = async () =>  {

    const token = await this.recaptchaRef.current.executeAsync();
    const dataObj = {};

    this.state.fields.map((field, index) => {

        if(field.name && field.value){
            dataObj[field.name] = field.value;
        }

    });

    dataObj.grc = token;

    //const { navigation } = this.props;

    let that = this;
    that.setState({ isSubmitting: true });

  //  setTimeout(()=> {


        axios.post('/api/registration', dataObj)
        .then(function (response) {
    
                let data = response.data;
                if(data.success ){
                    that.setState({  successMessage: 'Congratulations, your account has been successfully created.' });
                }else{
                that.setState({  apiError: data.message });
                }
    
                that.setState({  isSubmitting: false });
        })
        .catch(function (error) {
            that.setState({  isSubmitting: false,  apiError: 'Unable to connect with the api.' });
            console.log(error);
           // that.setState({  successMessage: 'Congratulations, your account has been successfully created.' });
        });
  //  }, 5000)

    }
      

    render() {
        const {isLoading, settings} = this.state;
        return (
            <>
                  
                <div className='mx-auto  py-8 '>
                    <div className="w-full text-left text-sm lg:border rounded-2xl lg:shadow-xl overflow-hidden lg:w-[960px]">
                        <form onSubmit={(event) => this.onSubmit(event)}>

                            <div className='lg:flex items-center'>
                                <div className='lg:w-2/6 text-center'>
                                    <img className="logo mx-auto mb-6" width="138" height="auto" src={logo} />
                                    <h1 className='text-2xl md:text-3xl font-normal'>Registration</h1>
                                </div>
                                <div className='lg:w-4/6'>
                                    {
                                        !this.state.successMessage &&
                                        <div className='md:grid md:grid-cols-2 gap-4 lg:max-h-[calc(100vh-200px)] overflow-auto pt-6 px-4'>
                                                {this.state.fields.map((field, index) => {
                                                    return <Field
                                                        key={field.id}
                                                        fieldConfig={field}
                                                        blured={(event) => this.fieldBlur(event,field,index)}
                                                        changed={(event) => this.fieldChange(event,field,index)} />
                                                })}
                                        </div>
                                    }
                                    <div className='px-4'>
                                        {isLoading ? <Loader /> : <></> }
                                        {
                                            !this.state.successMessage && settings && settings.dammore && settings.dammore.google_captcha_key &&
                                            <ReCAPTCHA  ref={this.recaptchaRef} size="invisible" sitekey={settings.dammore.google_captcha_key} />
                                        }
                                        {
                                            !this.state.successMessage &&
                                            <>
                                             {
                                                !this.state.isSubmitting &&
                                                <button type='submit' className={`inline-block w-full mt-5 btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80 ${isLoading && 'opacity-50 pointer-events-none'}`}
                                                data-disabled={!this.state.formValid}>
                                                Register
                                                </button>
                                            }
                                            {
                                                this.state.isSubmitting &&
                                                <button type='button' className="w-full  mt-2 md:mt-5 btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default">
                                                    <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  /> Creating account...
                                                </button>
                                            }
                                            </>
                                        } 
                                        {
                                            !this.state.successMessage &&
                                            <>
                                                <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                                                <p className='mt-2 2xl:mb-4 mb-2 text-center'>Already have an account ? <NavLink to="/login" 
                                                className='font-semibold'>Login here</NavLink></p>  
                                            </>
                                        }
                                        {
                                            this.state.successMessage  &&
                                            <div className='my-16 text-center'>
                                            <h1 className='text-2xl md:text-3xl mb-8 font-normal text-primary text-center'>Registration Success!</h1>
                                            <span className="text-primary text-base italic mt-2 mb-8 block text-center">{this.state.successMessage}
                                            <br/>We will be activating your account soon.  <br/><NavLink to="/login"  className='font-semibold mt-2 inline-block'>
                                                    <span className='flex items-center'><MdArrowBackIos className='inline-block'/> Back to Login</span>
                                                </NavLink>
                                            </span>
                                            </div>
                                        }    
                                    </div>
                                   
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>
            </>
        
        )
    }
}

export default Form;