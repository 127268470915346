
import React, { useEffect, useState, useParams } from "react";
//  import * as dotenv from 'dotenv'
//  dotenv.config()
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import axios from 'axios';

/* IMPORT COMPORNENTS */
import Home from "./pages/Home";
import ExploreDilmah from "./pages/ExploreDilmah";
import Partners from "./pages/Partners";
import PartnerDetails from './pages/PartnerDetails';
import NewsDetails from './pages/NewsDetails';
import EventsDetails from './pages/EventsDetails';
import ExploreDilmahDetailPage from './pages/ExploreDilmahDetailPage';
import DilmahUniverse from "./pages/DilmahUniverse";
import People from "./pages/People";
import DigitalAssets from "./pages/DigitalAssets";
import Collections from "./pages/Collections";
import CollectionDetails from "./pages/CollectionDetails";
import News from "./pages/News";
import Events from "./pages/Events";
import FAQ from "./pages/FAQ";
import Forum from "./pages/Forum";
import ForumDetails from './pages/ForumDetails';
import Recipe from "./pages/Recipe";
import RecipeDetails from "./pages/RecipeDetails";
import Certification from "./pages/Certification";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ForgetPassword from "./pages/ForgetPassword";
import NewPassword from "./pages/NewPassword";
import ScrollToTop from "./ScrollToTop";
import Piwik from "./Piwik";
import Registration from "./pages/Registration";


let user = false;

/* END OF IMPORT COMPORNENTS */
const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
const REFRESH_TOKEN = (userData) && userData.data.refresh_token;
const USER_KEY = (userData) && userData.user_key;

if(ASSCESS_TOKEN){
  axios.defaults.headers.common['Authorization'] = `Bearer ${ASSCESS_TOKEN}`;
  axios.defaults.headers.common['User-Key'] = `${USER_KEY}`;
  axios.defaults.headers.common['User-Rt'] = `${REFRESH_TOKEN}`;
  document.cookie = `at=${ASSCESS_TOKEN}`; 
  user = true;
}

const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  const location = useLocation();
  const userData =  JSON.parse(localStorage.getItem("_userData"));
  const ASSCESS_TOKEN = (userData) && userData.data.access_token;

  //console.log("xxx", location);

  if (!ASSCESS_TOKEN) {
    return <Navigate to={redirectPath} replace  state={{ redirectTo: location }} />;
  }

  return <Outlet />;
};

export default function App() {

  return (
    <Router>
        <ScrollToTop />
        <Routes>
       {/*  <Route index element={<Login />} /> */}
        <Route path="/login" element={<Login />} extend />
        <Route path="/forget-password" element={<ForgetPassword />} extend />
        <Route path="/new-password" element={<NewPassword />} extend />
        <Route path="/registration" element={<Registration />} extend />
          <Route element={<ProtectedRoute user={user} />}>
            <Route index element={<Home />} />
           {/*  <Route path="/home" element={<Home />} /> */}
            <Route path="/explore-dilmah" element={<ExploreDilmah />} />
            <Route path="/explore-dilmah/:slug" element={<ExploreDilmahDetailPage />} />
            <Route path="/partners" element={<Partners />} extend />
            <Route path="/partners/:slug" element={<PartnerDetails />} />
            <Route path="/dilmah-universe" element={<DilmahUniverse />} extend />
            <Route path="/people" element={<People />} extend />
            <Route path="/digital-assets" element={<DigitalAssets />} extend />
            <Route path="/collections" element={<Collections />} extend />
            <Route path="/collections/:slug" element={<CollectionDetails />} extend />
            <Route path="/news" element={<News />} extend />
            <Route path="/news/:slug" element={<NewsDetails />} extend />
            <Route path="/events" element={<Events />} extend />
            <Route path="/events/:slug" element={<EventsDetails />} extend />
            <Route path="/forum" element={<Forum />} extend />
            <Route path="/forum/:slug" element={<ForumDetails />} extend />
            <Route path="/recipe" element={<Recipe />} extend />
            <Route path="/recipe/:slug" element={<RecipeDetails />} extend />
            <Route path="/faq" element={<FAQ />} extend />
            <Route path="/awards-and-certification" element={<Certification />} extend />
            <Route path="/logout" element={<Logout />} extend />
          </Route>
        </Routes>
        <Piwik />
    </Router>
  );
}
