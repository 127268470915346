import React, { Component, createRef } from 'react';
import axios from 'axios';
import { AiOutlineSearch } from "react-icons/ai";
import { FaRegFolder } from "react-icons/fa";
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import { NavLink  } from "react-router-dom";
import { api } from "../Settings";

let timer;
class AssetsTree extends Component {

    constructor (props) {
        super(props)
        this.selectRef  = createRef();

        this.state = {
          filterOpt: [],
          tags: [],
          directory: [],
          allDirectory: [],
          selected:{
            dir: {},
            type: {},
            date: {},
            size: {},
            tags:{}
          },
          activeFilter: '',
          activeTags:'',
          active: false,
          dirSreachParms:'',
          searchparams: '', 
          showFilters: false
        };

        if(props.filters){
            this.state.showFilters = props.filters
        }
      
        this.renderChildren = this.renderChildren.bind(this);
        this.toggleDirTree = this.toggleDirTree.bind(this);
        this.seletFilterItem = this.seletFilterItem.bind(this);
      //
    
        this.updateDir = props.updateDir.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.findNested = this.findNested.bind(this);
  
    }
    
    componentDidMount () {

       axios.get(api.getAssetDirectoryTree).then(response => {
         //console.log( response.data.data);
         this.setState({
           directory: response.data.data,
           allDirectory: response.data.data,
         })
       });
    }

    handleChange(event) {
        const target = event.target;
        const val = (target.value) ? target.value : "";
       // const value = target.type === 'checkbox' ? target.checked : val;
        const name = target.name;

        clearTimeout(timer);
        timer = setTimeout(() => {

          if(val && val !== ""){

            const result = this.findNested(this.state.directory, 'text',  (k, v) => v.toLowerCase().startsWith(val.toLowerCase()) );
            this.setState({ directory: result });

          }else{
            this.setState({ directory: this.state.allDirectory });
          }

        }, 250);

        this.setState({ [name]: val });
       
    }

    findNested(object, key, predicate) {

      let ret = [];
      if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
          ret = [...ret, object];
      }
      if (Object.keys(object).length) {
          for (let i = 0; i < Object.keys(object).length; i++) {
              let value = object[Object.keys(object)[i]];
              if (typeof value === "object" && value != null) {
                  let o = this.findNested(object[Object.keys(object)[i]], key, predicate);
                  if (o != null && o instanceof Array) {
                      ret = [...ret, ...o];
                  }
              }
          }
      }
      return ret;

    }
    
    toggleSubMenu(e) {
        let activeKey = e.currentTarget.getAttribute("data-key");
        const { activeFilter } = this.state;

        if(activeKey == activeFilter ){
          this.setState({activeFilter: ""});
        }else{
          this.setState({activeFilter: activeKey});
        }
      /*   if(activeKey === 'tags'){
          console.log("focuse menu");
          console.log(this.selectRef.current);
          
                  if (this.selectRef.current) {
                    this.selectRef.current.focus();
                  }
        } */

    }

    seletFilterItem(e) {

        let fType = e.currentTarget.getAttribute("data-type");
        let fVal = e.currentTarget.getAttribute("data-id");
        let fLabel = e.currentTarget.getAttribute("data-label");

        let selectedFilters =  this.state.selected;

        let obj = {
          code: fVal,
          label: fLabel,
          type: fType
        };
       
      
        switch (fType) {
          case "browse":
            this.setState({ selected: { ...this.state.selected, dir: obj} });
            selectedFilters.dir = obj;

            const nodes = document.querySelectorAll('.tree-node');
            for (const node of nodes) {
              node.classList.remove('text-primary');
              for (const child of node.children) {
                child.classList.remove("text-primary");
              }
  
            }

            e.currentTarget.classList.add("text-primary");
            for (const child of e.currentTarget.children) {
              child.classList.add("text-primary");
            }
            
            break;
        
          case "date":
            this.setState({ selected: { ...this.state.selected, date: obj} });
            selectedFilters.date = obj;
            break;

          case "type":
            this.setState({ selected: { ...this.state.selected, type: obj} });
            selectedFilters.type = obj;
            break;
        
          case "size":
            this.setState({ selected: { ...this.state.selected, size: obj} });
            selectedFilters.size = obj;
            break;
        
          default:
            break;
        }

        this.updateDir(selectedFilters);

        this.setState({
          activeFilter: ""
        })
    }

    toggleDirTree(e) {
        let item = e.currentTarget.parentElement.parentElement;
        let ul = e.currentTarget.parentElement.nextSibling;
        e.currentTarget.classList.toggle("rotate-90");
        ul.classList.toggle("hidden");
    }

    renderChildren = (v, open) => {
      return (
        v.text && v.text !== "My Drive" &&
        <li key={v.id}>
          
          <span className='flex items-center relative mb-2 pl-5'>
              {
                v.children &&
                <MdArrowRight onClick={this.toggleDirTree} className={`absolute left-0 text-lg  cursor-pointer text-body/50 transition-all duration-300 ${open ? "rotate-90" : ""}`} />
              }
              <span data-id={v.id}  data-label={v.text} data-type="browse"  onClick={this.seletFilterItem} className='tree-node flex items-center cursor-pointer'>
                <FaRegFolder className='mr-1.5 text-body/50 w-4 h-4 min-w-[16px]'/> 
                <span className="text-sm" dangerouslySetInnerHTML={{__html : v.text}}></span>
              </span>
          </span>
          {v.children &&
            <ul className={`pl-4 ${!open ? "hidden" : ""}`}>
            {
              v.children.map((v2) => {
                return this.renderChildren(v2, false);
              })
            }
            </ul>  
          }
        </li>
      );
    };

    render() {
      const that = this;
      const { directory  } = this.state;
      return (
        <div className='relative border-r  pr-4 xl:pr-8 h-full'>
            <div className="search flex items-center relative border rounded-lg mb-3">
                <a className="search-icon text-xl text-gray-400 ml-2"><AiOutlineSearch /></a>
                <input
                    className="search-input bg-transparent text-sm p-3 w-80 h-10 pl-2 outline-none focus:outline-none overflow-hidden truncate"
                    placeholder="Search..."
                    name="searchparams" 
                    onChange = {this.handleChange}
                    value={this.state.searchparams || ''} 
                />
            </div>
            {
              directory && directory.length > 0 &&
              <ul className='overflow-auto'>
              {directory.map((v) => {
                  return that.renderChildren(v, true);
                  })}
              </ul>
            }
        </div>
    
      );
    }
}

export default AssetsTree;
  
  