import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { useNavigate   } from "react-router-dom";
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import Assets from "./../compornents/Assets";
import Collections from "./../compornents/Collections";
//const dataSource = process.env.REACT_APP_API_URL + '/people.json';


function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()}   />;
}


class DigitalAssets extends Component {

    constructor (props) {
        super(props)

        this.state = {
          componentData: []
        }
    }

    componentDidMount () {
       /*  axios.get(dataSource).then(response => {

          console.log(response);
          this.setState({
            componentData: response.data.components
          })
        }) */
    }

    
    componentWillReceiveProps(nextProps) {

     /*  const { location } = this.props;

   //   const { state } = location();

   //   console.log( this.props.match.params);
      console.log(location); */
      //console.log(this.props.route.params);
     // console.log( nextProps.navigation.getParam('q'));

     // const { navigation } = this.props;

    //  const itemId = navigation.getParam('q', 'NO-ID');

    //  console.log( this.props.route.params.q);
    //  console.log(navigation.getParam('q', false));
     // console.log(this.props.route.params);
    //  console.log(this.props.navigation.getParam('q'));
      /* if(this.props.params.slug !== nextProps.params.slug) {
          this.getData(nextProps.params.slug);
          this.subCollections(nextProps.params.slug);
      } */
  }

    render(){
      const { componentData } = this.state;
      return (
        <HelmetProvider>
          <div>
          <Helmet>
            <title> DPN - Digital Assets</title>
          </Helmet>
          <Header/>
          <div className="min-h-screen mx-auto mt-[95px] md:mt-[120px] relative mb-16">
              <Assets  />
          </div>
          <Footer />
          </div>
          </HelmetProvider>
      )
    }
}

export default withParams(DigitalAssets);