import { useState, useEffect } from "react";
import { FiEdit } from 'react-icons/fi';

const EditButton = () => {

    const [editState, setEditState] = useState(false);
    const varName = "edit_mode"
    const buttonNameEditMode = "Edit"
    const buttonNameCancelEditMode = "Preview"

    const handleOnClick = () => {
        localStorage.setItem(varName, !editState);
        localstorageWritter(!editState)
        setEditState(!editState)
        setEditModeOnEditButtons()
    }

    const handleEditMode = async () => {
        const editItem = await localstorageReader(varName);
        if (editItem == null) {
            localstorageWritter(false)
        } else {
            const editSet = (editItem.trim() === 'true') ? true : false
            setEditState(editSet)
        }
    }

    const localstorageWritter = async (state) => {
        const read = await localStorage.setItem(varName, state);
        return read;
    }

    const localstorageReader = async (state) => {
        const read = await localStorage.getItem(varName);
        return read;
    }

    const setEditModeOnEditButtons = () => {
        if (editState){
            document.querySelector("body").classList.add('edit_mode');
        } else {
            document.querySelector("body").classList.remove('edit_mode');
        }

        // console.log(collection)

    } 

    useEffect(() => {
        handleEditMode()
        setEditModeOnEditButtons()
    }, [editState])

    return (
        <>
            <button onClick={() => handleOnClick()} className="fixed top-[88px] right-0 bg-primary shadow text-white font-bold py-2 px-4 text-sm flex items-center">
                <FiEdit className='text-lg mr-1' title="Edit" />
                {
                    (editState) ? (buttonNameCancelEditMode) : (buttonNameEditMode)
                }
            </button>

        </>
    )
}



export { EditButton }