import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';


if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then(function (reg) {
      if (reg.installing) {
        console.log("Service worker installing");
      } else if (reg.waiting) {
        console.log("Service worker installed");
      } else if (reg.active) {
        console.log("Service worker active");
      }
    })
    .catch(function (error) {
      // registration failed
      console.log("Registration failed with " + error);
    });
} else {
  console.log("Service worker not in navigator");
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   //<React.StrictMode> 
    <App />
  //</React.StrictMode>

);


reportWebVitals();
