import React, { Component, createRef } from 'react';
import Select, { components } from "react-select";
import axios from 'axios';
import { AiOutlineSearch } from "react-icons/ai";
import { BsTags, BsCalendar3 } from "react-icons/bs";
import { MdArrowDropDown, MdOutlineSort, MdOutlineClose } from "react-icons/md";
import AssetsMenu from './AssetsMenu';
import { api } from "../Settings";

const { Option } = components;

const filters = [
  {
      label: "Year",
      key: "year",
      icon: <BsCalendar3 />
  },
  {
      label: "Month",
      key: "month",
      icon: <BsCalendar3 />
  },
  {
      label: "Tags",
      key: "tags",
      icon: <BsTags />
  },
  {
      label: "Sort",
      key: "sort",
      icon:  <MdOutlineSort />
  }
];

const CustomOption = props => (
  <Option {...props}>
    <div className='flex items-center'>
      <BsTags className='text-primary mr-1 min-w-[24px]' />
      <span className='text-sm'>{props.data.label}</span>
    </div>
  </Option>
);

const Control = ({ children, ...props }) => {

  return (
    <components.Control {...props}>
      <span >
      <AiOutlineSearch className='text-xl text-gray-400 ml-2' />
      </span>
      {children}
    </components.Control>
  );
};

class NewsFilter extends Component {

    constructor (props) {
        super(props)
        this.selectRef  = createRef();

        this.state = {
          tags: [],
          selected:{
            month: {},
            year: {},
            sort: {},
            tags:{}
          },
          activeFilter: '',
          activeTags:'',
          active: false,
          searchparams: '',
          showMenu: false,
          filterOpt:{
            "month":[ 
                {
                    "code":"01",
                    "label":"January"
                },
                {
                    "code":"02",
                    "label":"February"
                },
                {
                    "code":"03",
                    "label":"March"
                },
                {
                    "code":"04",
                    "label":"April"
                },
                {
                    "code":"05",
                    "label":"May"
                },
                {
                    "code":"06",
                    "label":"June"
                },
                {
                    "code":"07",
                    "label":"July"
                },
                {
                    "code":"08",
                    "label":"August"
                },
                {
                    "code":"09",
                    "label":"September"
                },
                {
                    "code":"10",
                    "label":"October"
                },
                {
                    "code":"11",
                    "label":"November"
                },
                {
                    "code":"12",
                    "label":"December"
                }
            ],
            "year":[ 
                {
                    "code":"2023",
                    "label":"2023"
                },
                {
                    "code":"2022",
                    "label":"2022"
                },
                {
                    "code":"2021",
                    "label":"2021"
                },
                {
                    "code":"2020",
                    "label":"2020"
                },
                {
                    "code":"2019",
                    "label":"2019"
                }
            ],
            "sort":[ 
                {
                    "code":"ASC-name",
                    "label":"Name ascending"
                },
                {
                    "code":"DESC-name",
                    "label":"Name descending"
                },
                {
                    "code":"ASC-news_date",
                    "label":"Date ascending"
                },
                {
                    "code":"DESC-news_date",
                    "label":"Date descending"
                }
              
            ]
          }
        };
        this.showMenu = props.showMenu;
        this.mode = props.mode;
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.seletFilterItem = this.seletFilterItem.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.clearSelectedItem = this.clearSelectedItem.bind(this);
        this.selectTags = this.selectTags.bind(this);
        this.updateSelection = props.updateSelection.bind(this);
    }

    

    componentDidMount () {

       axios.get(api.getNewsTags).then(response => {
         this.setState({
           tags: response.data.data
         })
       });

    }


    toggleSubMenu(e) {
        let activeKey = e.currentTarget.getAttribute("data-key");
        const { activeFilter } = this.state;

        if(activeKey === activeFilter ){
          this.setState({activeFilter: ""});
        }else{
          this.setState({activeFilter: activeKey});
        }
      /*   if(activeKey === 'tags'){
          console.log("focuse menu");
          console.log(this.selectRef.current);
          
                  if (this.selectRef.current) {
                    this.selectRef.current.focus();
                  }
        } */

    }

    seletFilterItem(e) {

        let fType = e.currentTarget.getAttribute("data-type");
        let fVal = e.currentTarget.getAttribute("data-id");
        let fLabel = e.currentTarget.getAttribute("data-label");

        let selectedFilters =  this.state.selected;

        let obj = {
          code: fVal,
          label: fLabel,
          type: fType
        };
       
      
        switch (fType) {
          case "month":
            this.setState({ selected: { ...this.state.selected, month: obj} });
            selectedFilters.month = obj;
            break;
        
          case "year":
            this.setState({ selected: { ...this.state.selected, year: obj} });
            selectedFilters.year = obj;
            break;

          case "sort":
            this.setState({ selected: { ...this.state.selected, sort: obj} });
            selectedFilters.sort = obj;
            break;
        
          default:
            break;
        }

        this.updateSelection(selectedFilters);

        this.setState({
          activeFilter: ""
        })
    }

    selectTags(selectedTags) {

     // console.log('tags updated', selectedTags);

      let selectedFilters =  this.state.selected;
    
      let obj = {
        type: 'tags',
        items: selectedTags
      };
    
      this.setState({ selected: { ...this.state.selected, tags: obj} });
      selectedFilters.tags = obj;
      this.updateSelection(selectedFilters);

      this.setState({ activeFilter: ""})
    
    }

    clearSelectedItem(e){
      
      let fType = e.currentTarget.getAttribute("data-type");
      let fVal = e.currentTarget.getAttribute("data-id");

      let selectedFilters =  this.state.selected;

      switch (fType) {
        case "month":
          this.setState({ selected: { ...this.state.selected, month: {}} });
          selectedFilters.month = {};
          break;
      
        case "year":
          this.setState({ selected: { ...this.state.selected, year: {}} });
          selectedFilters.year = {};
          break;

        case "sort":
          this.setState({ selected: { ...this.state.selected, sort: {}} });
          selectedFilters.sort = {};
          break;

        case "tags": 

          selectedFilters.tags.items.map((tag, i) => {
              if(tag.value === fVal){
                selectedFilters.tags.items.splice(i, 1);
              }
          });

          //console.log(selectedFilters.tags.items);
          //this.selectRef.current.setValue([]);
          //this.selectRef.current.setValue(selectedFilters.tags.items);

         
      
         // this.setState({ selected: { ...this.state.selected, size: {}} });
          //selectedFilters.size = {};
          break;
      
        default:
          break;
      }
      
      this.updateSelection(selectedFilters);

    }

    clearSelection(){
      this.selectRef.current.clearValue();
      this.setState({ selected:{
        month: {},
        year: {},
        sort: {},
        tags:{}
      }});

    
      this.updateSelection(null);
    }


    render() {
      const { filterOpt, activeFilter, tags, selected } = this.state;
      return (
        <div className='z-10 relative '>
          <div className="flex items-center text-body"> 
            {
              
              this.showMenu === true &&
              <AssetsMenu />
               
            }
                <div className='filters flex items-center'>

                    {filters.map((item, index) => {
                    return (
                    <div key={index} className={`relative`}>
                        <span onClick={this.toggleSubMenu} data-key={item.key} className={ `px-1 md:px-4 group cursor-pointe transition-all duration-300 border-b-4 py-3 border-transparent cursor-pointer block ${item.key === activeFilter ? "text-primary" : "text-body "}` }>
                        <div className="flex  items-center">
                            <div className="menu-icon text-xl opacity-50">
                                {item.icon}
                            </div>
                            <div className="menu-text text-sm ml-1.5 ">
                                {item.label}
                            </div>
                            <MdArrowDropDown className={`text-lg text-body/50 transition-all duration-300  ${item.key === activeFilter ? "rotate-180" : ""}`} />
                        </div>
                        </span>
                        {
                            item.key === 'tags' &&
                            <div className={`filter-popup bg-white absolute z-10 w-[330px] shadow-xl rounded-lg px-3   ${'tags' === activeFilter ? "block" : "hidden"}`}>
                            <Select
                                ref={this.selectRef}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                hideSelectedOptions={false}
                                value={this.state.selected.tags.items}
                                maxMenuHeight={200}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                placeholder='Search...'
                                components={{ Option: CustomOption, Control  }}
                                isMulti
                                options={tags}
                                onChange={this.selectTags}
                            />
                        </div>
                        }
                        {
                            item.key === 'month' &&
                        <div className={`filter-popup bg-white absolute right-0 z-10 shadow-2xl rounded-lg w-28 px-3 ${'month' === activeFilter ? "block" : "hidden"}`}>
                            {
                            filterOpt.month &&
                            filterOpt.month.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            })
                            }
                        </div>
                        }
                        {
                            item.key === 'year' &&
                        <div className={`filter-popup bg-white absolute right-0 z-10 shadow-2xl rounded-lg w-20 px-3 ${'year' === activeFilter ? "block" : "hidden"}`}>
                            {
                            filterOpt.year &&
                            filterOpt.year.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            })
                            }
                        </div>
                        }
                        {
                            item.key === 'sort' &&
                        <div className={`filter-popup bg-white absolute left-0 z-10 shadow-2xl rounded-lg px-3 ${'sort' === activeFilter ? "block" : "hidden"}`}>
                            {
                            filterOpt.sort &&
                            filterOpt.sort.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            })
                            }
                        </div>
                        }
        
                    </div>
                    );
                    })}        
                </div>
          </div> 
            {
             (Object.keys(selected.tags).length > 0 ||  Object.keys(selected.month).length > 0 ||  Object.keys(selected.year).length > 0 ||  Object.keys(selected.sort).length > 0 ) &&
             <div className='filtered-opt  mt-4 md:mt-8 flex items-center flex-wrap container mx-auto'>
              <span className='mr-4 text-sm md:text-base'>Resulats showing for:</span>
              {
                Object.keys(selected).map((item, index) => {
              
                  return (
                    <div className="flex items-center" key={index}>
                      <>
                      {
                        selected[item].type !== 'tags' && Object.keys(selected[item]).length > 0 && 
                        <span key={index} className="bg-ash rounded-lg py-1 md:py-2 px-1.5 md:px-4  my-1 md:my-0 md:mb-1.5 mx-1.5 text-xs md:text-base font-medium flex items-center" >
                          <span dangerouslySetInnerHTML={{__html : selected[item].label}}></span>
                          <span  onClick={this.clearSelectedItem} data-type={selected[item].type} data-id={selected[item].code} className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                        </span>
                      }
                      </>
                      <>
                      {
                        selected[item].type === 'tags'  && 
                        selected[item].items.map((tag, index) => {
                          return (
                          <span key={index} className="bg-ash rounded-lg py-1 md:py-2 px-1.5 md:px-4  my-1 md:my-0 md:mb-1.5 mx-1.5 text-xs md:text-base font-medium flex items-center" >
                            <span>{tag.label}</span>
                            <span onClick={this.clearSelectedItem} data-type={selected[item].type} data-id={tag.value}  className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                          </span>
                          )
                        })
                      }
                      </>
                    </div>
                  )
                })
              }
              <span  onClick={this.clearSelection}  className='ml-4 cursor-pointer hover:text-primary text-sm md:text-base'>Clear</span>
             </div>
            }
        </div>
    
    
      );
    }
}

export default NewsFilter;
  
  