import React, { Component } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link  } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { FiDownload, FiEdit } from "react-icons/fi";
import { BsEye, BsPlus, BsShareFill } from "react-icons/bs";
import AssetsMenu from './AssetsMenu';
import AssetsFilter from './AssetsFilter';
import CollectionTree from './CollectionTree';
import Share from './Share';
import Loader from "./Loader";
import EmptyData from "./EmptyData";
import ApiError from "./ApiError";
import { api } from "../Settings";
import Uploader from './Uploader';

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}

class Collections extends Component {

    constructor (props) {
        super(props);
        this.state = {
            data: {},
            items:[],
            params: {},
            hasMore: true,
            isLoading: false,
            total:0,
            loadedCount:0,
            pageSize: 25,
            shareMode: false,
            selectedIds: [],
            selectedTitle: null,
            isError:false,
            contentLoading: false
        }

        this.updateDir = this.updateDir.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.filterData = this.filterData.bind(this);
        this.getData = this.getData.bind(this);
        this.fetch = this.fetch.bind(this);
        this.shareMedia = this.shareMedia.bind(this);
        this.toggleShare = this.toggleShare.bind(this);
    }

    componentDidMount () {
        this.getData(0, true);
    }

    updateSelection(filters) {

        const obj = {};
        obj.ftr = (filters && filters.type.code) ? filters.type.code : null;
        obj.fs = (filters && filters.size.code) ? filters.size.code : null;
        obj.ud = (filters && filters.date.code) ? filters.date.code : null;
        //obj.dir = (filters && filters.dir.code) && filters.dir.code;

        if(filters && filters.dir.code){
            obj.dir = filters.dir.code;
        }

        if(filters && filters.tags.items){
  
            const keys = [];
            filters.tags.items.map((tag, i) => {
                keys.push(tag.value);
            });

            obj.tids = keys;
        }else{
            obj.tids = null
        }

        if(filters){
            this.setState({ params: { ...this.state.params, ...obj } },() => {
                  this.filterData()
            });
        }else{
            this.setState({ params: {} },() => {
                this.filterData()
            });
        }

    }

    updateDir(filters) {

        if(filters && filters.dir.code){
            this.setState({ params: { ...this.state.params, dir: filters.dir.code} },() => {
                this.filterData()
            });
        }
    }

    getData(count, reset){
       
        if (this.state.isLoading) {
            return;
        }

        if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
            this.setState({ hasMore: false })
            return;
        }else{
            this.setState({ hasMore: true })
        }
        
        this.setState({ isLoading: true })

        if(count === 0 ){
            this.setState({ loadedCount: 0  })
        }

        if(reset){
            this.setState({ items: [], loadedCount: 0  }, () => { this.fetch(reset) })
        }else if(this.state.loadedCount === 0){
            this.setState({ loadedCount: 25 },() => { this.fetch(reset) })
        }else{
            this.fetch(reset) 
        }

    }

    fetch(reset){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;

        const dataPath = (!Object.keys(this.state.params).length) ? api.getCollections : api.getCollections;
        const params = this.state.params;
        const that = this;

        if(reset){
            that.setState({  contentLoading: true, isError: false })
        }


       // setTimeout(() => {
            
            axios.get(dataPath, { params: { ...params, st: this.state.loadedCount } }).then(response => {
    
                //Set total
                this.setState({ total: response.data.total });
                this.setState({ data:  response.data });
                this.setState({ items: [ ...this.state.items, ...response.data.items ] },() => {
    
                    that.setState({ contentLoading: false, isError: false, isLoading: false })
    
                   // if(!reset){
                        const newCount = this.state.pageSize + this.state.loadedCount;
                        //console.log(newCount);
                        this.setState({ loadedCount: newCount })
                   // }
    
                });
    
            })
            .catch(function (error) {
    
                if (error.response && error.response.status === 401 ) {
                    navigation("/logout", { state: { previousPath: pathname } } );
                    navigation(0);
                }
    
                that.setState({ contentLoading: false, isError: true , isLoading: false })
    
            });
        //}, 5000);


    }


    filterData(){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;
        const dataPath = (!Object.keys(this.state.params).length) ? api.getCollections : api.getCollections;
        
        axios.get(dataPath, { params: this.state.params }).then(response => {
            this.setState({
              data:  response.data
            })
        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }
        }); 

    }
    

    shareMedia(e){
        //console.log(e);

        let id = e.currentTarget.getAttribute("data-id");
        let title = e.currentTarget.getAttribute("data-title");

        if(id){
            this.setState({ selectedIds: [id], selectedTitle: title, shareMode: true }, () => {
                //callback function
            });
        }
    }

    toggleShare(state){
        this.setState({ shareMode: state, selectedIds: [] });
    }

    renderData(){
        const { data, items, hasMore, shareMode, selectedIds, selectedTitle,total, contentLoading  } = this.state;
        return (
            <>
                {
                    total === 0 && 
                    <EmptyData message="There are no collections to display." />
                }
                {                
                    total > 0 &&
                    <InfiniteScroll
                            pageStart={0}
                            loadMore={this.getData}
                            initialLoad={false}
                            hasMore={hasMore}
                            loader={<div className="loader" key={0}><Loader /></div>}
                        >
                        <div className="items grid grid-cols-2 md:grid-cols-3  lg:grid-cols-3 2xl:grid-cols-4 gap-2 md:gap-4 xl:gap-8">
                            {
                            items && items.length > 0 &&
                            items.map((item, index) => {
                                const thumbnails = item.thumbnails;
                                return (
                                    <div key={index} className="flex  mb-8 flex-wrap items-start relative">
                                        <div className="item-content text-sm w-full ">
                                            <Link to={`/collections/${item.id}`} className='block w-full'>
                                            <div className="mb-2 grid gap-1 grid-cols-1 rounded-xl overflow-hidden">
                                            <span className="bg-primary/20 w-full h-0 pt-full bg-no-repeat bg-cover bg-center relative">
                                                {
                                                    item.cover_image &&
                                                    <span key={index} className="w-full h-full absolute left-0 top-0 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${item.cover_image}&access_token=${ASSCESS_TOKEN}&width=480&height=0")` }}>
                                                    </span>
                                                }
                                            
                                                {!item.cover_image && thumbnails && thumbnails.length > 0 &&
                                                    thumbnails.map((thumb, index) => {
                                                        return (
                                                        index < 1 &&
                                                        <span key={index} className="w-full h-full absolute left-0 top-0  bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${thumb.path}&access_token=${ASSCESS_TOKEN}&width=480&height=0")` }}>
                                                            {index == "3" &&
                                                                <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                                <span className='text-white text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center'>{item.mediaCount}<BsPlus className='text-2xl'/></span>
                                                                </span>
                                                            }
                                                        </span>
                                                        );
                                                    })
                                                }
                                            </span>


                                            
                                            </div>
                                            </Link>
                                            <h3 className="mb-3 font-semibold text-xl">
                                                <Link to={`/collections/${item.id}`} className='block w-full'>
                                                    {item.title}
                                                </Link>
                                            </h3>
                                            {/* {
                                                item.mediaCount &&
                                                <Link to={`/collections/${item.id}`} className='block w-full'>
                                                    <span className='block my-1'>{item.mediaCount} Assets</span>
                                                </Link>
                                            } */}
                                            <div className='line-clamp-3 mb-2 '>
                                                <Link to={`/collections/${item.id}`} className='block w-full'>
                                                    <p>{item.description}</p>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="actions flex items-start mt-4 h-8 self-end w-full">
                                            <Link to={`/collections/${item.id}`}  className="view btn text-primary border border-primary hover:bg-primary transition-all duration-300  rounded-md text-sm hover:text-white  flex items-center py-2 px-3 mr-2 h-full" ><BsEye/><span className='ml-2'>{data.detailLabel}</span></Link>
                                        {/*  <a href={item.downloadLink} className="download btn text-primary border border-primary hover:bg-primary transition-all duration-300  rounded-md text-sm hover:text-white  py-2 px-3  mr-2 h-full"><FiDownload /></a> */}
                                            <a  onClick={this.shareMedia} data-id={item.id}   data-title={item.title}  className="share btn text-primary border border-primary hover:bg-primary transition-all duration-300  rounded-md text-sm hover:text-white cursor-pointer py-2 px-3 h-full" ><BsShareFill /></a>
                                        
                                        </div>
                                        {
                                            EDIT_ACCESS && item.editLink &&
                                            <a href={item.editLink.replace("ITEM_ID", item.id)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                                        }
                                    </div>
                                );
                            })}         
                        </div>
                    </InfiniteScroll>   
                }    
            </>
        )
    }
    
    render() {
        const { data, items, hasMore, shareMode, selectedIds, selectedTitle,total, contentLoading, isError } = this.state;

        return (
            <>
            <div className='container mx-auto'>
                <div className='my-6 flex items-center text-sm text-primary  xl:px-8'>
                    <Link to="/">Home</Link><span className='mx-1'>/</span>
                    <Link to="/collections">Collections</Link>
                </div>  
            </div>  
            <div className='xl:px-8 border-b-2 md:pb-3 mb-4 lg:mb-8 z-10 relative '>
                <div className="flex flex-wrap items-center mb-4 text-body container mx-auto"> 
                    <AssetsMenu />
                    <div className='flex flex-wrap md:flex-nowrap items-center w-full lg:w-3/4'>
                        <h1 className="text-xl mt-4 lg:mt-0 md:text-3xl font-semibold text-primary">{data.title}</h1>
                        <div className='w-full md:w-auto md:ml-auto mt-2 md:mt-0'>
                            <Uploader />
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-body  z-0 relative container xl:px-8 mx-auto">
                <div className='flex'>
                    <div className='w-1/4  pr-4 xl:pr-8 hidden lg:block'>
                        <CollectionTree  updateDir={this.updateDir} />
                    </div>
                    <div className='w-full lg:w-3/4'>
                        <div className='flex items-center mb-8'>
                           
                            {
                                data.total > 0 && 
                                <span className='ml-auto text-sm text-primary'>Total items: {data.total}</span>
                            }
                        </div>
                        {contentLoading ? <Loader /> : this.renderData()}
                        {isError && <ApiError />}
                    
                    </div>
                </div>
                
            </div>
            {
                shareMode &&
                <Share toggleShare={this.toggleShare} type="collection" ids={selectedIds} title={selectedTitle} baseUrl={data.baseUrl} />
            }
            </>
        );
    }
}

export default withParams(Collections);