import React, { Component } from 'react';

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;

class Users extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }
        this.imageAlign = props.imageAlign;
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
  render() {
    const { data } = this.state;
    return (
      <>
      { data && data.items &&
        <div className="mt-20 mb-16 grid  grid-cols-4 gap-4">
            {
                data.items.map((item, index) => {
                    return (
                    <div key={index} className="item text-center mb-8 relative z-0">
                        <div className="content-box px-6 z-10 mt-16">
                            <div className="picture-box w-[105px] h-[105px] rounded-full bg-gray-500 bg-no-repeat bg-cover bg-center mx-auto z-10 relative"  style={{ backgroundImage: `url("${item.avatar}&access_token=${ASSCESS_TOKEN}&width=105&height=0")` }}></div>
                            <h3 className="md:text-2xl mt-2 mb-1 truncate overflow-hidden">{item.author}</h3>
                            { item.email && 
                                <a href={`mailto:${item.email}`} className="text-body/50 text-sm underline hover:text-body truncate overflow-hidden">
                                {item.email}
                                </a>
                            }
                            { item.btnAction && 
                                <a href={item.btnAction} className="inline-block mt-3 btn bg-primary shadow-md py-3 px-8 rounded-md text-sm text-white hover:shadow-lg hover:opacity-90">
                                {item.btnLabel}
                                </a>
                            }
                        </div>
                        <span className="bg-primary absolute left-0 top-0 w-full h-[125px] z-0"></span>
                    </div>
                    );
                })
            }
        </div>
      }
      </>
    );
  }
}

export default Users;