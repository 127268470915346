import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import Welcome from "./../compornents/Welcome";
import FeaturedNav from "./../compornents/FeaturedNav";
import TextBanner from "./../compornents/TextBanner";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import Banner from "./../compornents/Banner";
import ApiError from "./../compornents/ApiError";
import Loader from "./../compornents/Loader";
import { api } from "../Settings";


class Home extends Component {

    constructor (props) {
        super(props)

        this.state = {
          isLoading: false,
          isError: false,
          componentData: []
        }
    }

    componentDidMount () {
        const that = this;
        that.setState({ isLoading: true, isError: false })
        axios.get(api.getHome).then(response => {
          this.setState({
            componentData: response.data.components
          })
          this.setState({ isLoading: false });
        })
        .catch(function (error) {
          that.setState({ isLoading: false, isError: true  })
        })
    }



  render(){
    const { componentData, isLoading, isError } = this.state;
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> DPN - Home </title>
        </Helmet>
        <Header/>
        <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8">
          <div className="lg:w-3/4">
              {isLoading ? <Loader /> : <>
                  
              {
                  componentData && componentData.Welcome &&
                  <Welcome data={componentData.Welcome} />
              }
              {
                  componentData && componentData.TextBanner &&
                  <TextBanner data={componentData.TextBanner} />
              }
              {
                  componentData && componentData.FeaturedNav &&
                  <FeaturedNav data={componentData.FeaturedNav} />
              }
              <div className="md:flex md:gap-2">
                <div className={`${componentData && componentData.BannerAlt ? "md:w-1/2" : "w-full"}`}>
                  {
                        componentData && componentData.Banner &&
                        <Banner boxMode={true} data={componentData.Banner} />
                  }
                </div>
               
                  {
                        componentData && componentData.BannerAlt &&
                        <div className="md:w-1/2">
                          <Banner boxMode={true} data={componentData.BannerAlt} />
                        </div>

                  }
              </div>

          {/*     {
                        componentData && componentData.Banners &&
                        
                          componentData.Banners.map((item, index) => {
                            console.log(item);
                              return (

                                <Banner data={item} />
                              )})


                  } */}
              </>}
              {isError && <ApiError />}

              
          </div>
          <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
              <PartnerFeeds />
          </div>
        </div>
        <Footer />
        </div>
        </HelmetProvider>
    )
  }
}

export default Home;
