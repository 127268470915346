import React, { Component } from 'react';
import {FaMugHot,FaSmile, FaShippingFast, FaBriefcase, FaBoxOpen, FaGraduationCap, FaHeart , FaLandmark, FaMarker, FaTrophy, FaUserFriends, FaRegComments} from "react-icons/fa";
import { FiEdit } from 'react-icons/fi';
const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

const getIcon = (icon) => {
    switch(icon) {
       case "FaMugHot":
        return <FaMugHot />;
       case "FaSmile":
        return <FaSmile />;
       case "FaShippingFast":
        return <FaShippingFast />;
       case "FaBriefcase":
        return <FaBriefcase />;
       case "FaBoxOpen":
        return <FaBoxOpen />;
       case "FaGraduationCap":
        return <FaGraduationCap />;
       case "FaHeart":
        return <FaHeart />;
       case "FaLandmark":
        return <FaLandmark />;
       case "FaMarker":
        return <FaMarker />;
       case "FaTrophy":
        return <FaTrophy />;
       case "FaUserFriends":
        return <FaUserFriends />;
       case "FaRegComments":
        return <FaRegComments />;
       default:
        return null;
    }
}

class GridBox extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }
        this.imageAlign = props.imageAlign;
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
  render() {
    const { data } = this.state;
    return (
      <>
      { data && data.items && 
        <div className="md:mt-8 md:mb-16 gap-6 grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
            {
                data.items.map((item, index) => {
                    return (
                    <div key={index}  className='shadow-lg py-4 px-2 rounded-xl relative'>
                        <h2 className="md:text-lg mb-4 font-meduim">{item.title}</h2>
                        { item.description && 
                        <div className="text-sm line-clamp-4 mb-4" dangerouslySetInnerHTML={{__html : item.description}}></div>
                        }
                        { item.highlights && item.highlights.length > 0  && 
                        <div className={`hightlights`}>
                            {
                                item.highlights.map((highlight, index) => {

                                    return (
                                        
                                    <span key={index} className="mb-2 flex items-center  text-sm">
                                        <span className="p-2 bg-gray-200 rounded-full text-xs text-body/50 mr-3">
                                            {getIcon(highlight.icon)}
                                        </span>
                                        <span>{highlight.name}</span>
                                    </span>
                                    );
                                })
                            }
                        </div>
                        }
                        {
                            EDIT_ACCESS && item.editLink &&
                            <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                        }   
                    </div>
                    );
                })
            }
        </div>
      }
      </>
    );
  }
}

export default GridBox;