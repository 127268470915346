import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import FeaturedEventsSlider from "./../compornents/FeaturedEventsSlider";
import EventsGrid from "./../compornents/EventsGrid";
import Banner from "./../compornents/Banner";
import { api } from "../Settings";

class Events extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: []
        }
    }

    componentDidMount () {
        axios.get(api.getEvents).then(response => {

        console.log(response);
          this.setState({
            data: response.data
          })
        })
    }

    render(){
        const { data } = this.state;
        return (
            <HelmetProvider>
                <div>
                <Helmet>
                <title> DPN - Event</title>
                </Helmet>
                <Header/>
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8  relative z-0"> 
                    <div className="lg:w-3/4">
                        {
                            data.components &&
                            <Banner gradient={true} data={data.components.Banner} />
                        }
                        {
                            data.components &&
                            <FeaturedEventsSlider />
                        }
                        <EventsGrid />
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
                <Footer />
                </div>
            </HelmetProvider>
        )
    }
}

export default Events;
