import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import Intro from "./../compornents/Intro";
import PartnerBlocks from "./../compornents/PartnerBlocks";
import FeatureBox from "./../compornents/FeatureBox";
import FAQBox from "./../compornents/FAQBox";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import ForumFeaturedFeeds from "./../compornents/ForumFeaturedFeeds";
import ApiError from "./../compornents/ApiError";
import Loader from "./../compornents/Loader";
import { api } from "../Settings";

class Partners extends Component {

    constructor (props) {
        super(props)

        this.state = {
            isLoading: false,
            isError: false,
            componentData: []
        }
    }

    componentDidMount () {
        const that = this;
        that.setState({ isLoading: true, isError: false })
        axios.get(api.getPartners).then(response => {
          this.setState({
            componentData: response.data.components
          })
          this.setState({ isLoading: false });
        })
        .catch(function (error) {
          that.setState({ isLoading: false, isError: true  })
        })
    }

    render(){
        const { componentData, isLoading, isError } = this.state;
        return (
            <HelmetProvider>
                <div>
                <Helmet>
                <title> DPN - Partmers </title>
                </Helmet>
                <Header/>
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8">
                    <div className="lg:w-3/4">
                        {isLoading ? <Loader /> : <>
                            <Intro data={componentData.Intro} />
                            <PartnerBlocks data={componentData.Blocks} />
                            <FeatureBox imageAlign="left"  data={componentData.FeatureBox1} />
                            <FAQBox data={componentData.FAQBox} />
                            <FeatureBox imageAlign="right" data={componentData.FeatureBox2} />
                            <ForumFeaturedFeeds title="Featured Forum Discussions" />
                        </>}
                        {isError && <ApiError />}
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
                <Footer />
                </div>
            </HelmetProvider>
        )
    }
}

export default Partners;
