import React, { Component } from 'react';
import logo from './../assets/empty_data.jpg';

class EmptyData extends Component {

  constructor (props) {
    super(props)
    this.state = {
      message: "There is no data to display."
    }

    if(props.message){
      this.state. message = props.message;
    }
  }

  render() {
    return (
        <div className="text-center mt-8 text-body/50">
            <img alt="" src={logo} className="w-full max-w-[400px] mx-auto"/>
            <span className="mt-4 inline-block">{this.state.message}</span>
        </div>
    );
  }
}

export default EmptyData;