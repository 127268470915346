import React, { Component, createRef } from 'react';
import logo from './../assets/logo.png';
import axios from 'axios';
import { useNavigate, NavLink } from "react-router-dom";
import { api } from "../Settings";
import ReCAPTCHA  from "react-google-recaptcha";
import { BiLoaderAlt } from 'react-icons/bi';


function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} />;
}
  

class ForgetPwForm extends Component {

    constructor (props) {
        super(props);
        this.recaptchaRef  = createRef();

        this.state = {
          settings: null,
          username: '', 
          emailError: '',
          successMessage: '',
          apiError: '',
          isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);
        this.sendData = this.sendData.bind(this);

    }

    componentDidMount () {
      axios.get(api.getSettings).then(response => {
        console.log( response.data);
        this.setState({
          settings: response.data
        })
      })
    }

    handleChange(event) {
        const target = event.target;
        const val = (target.value) ? target.value : "";
        const value = target.type === 'checkbox' ? target.checked : val;
        const name = target.name;
        this.setState({ [name]: value    });
        this.setState({  apiError: "",  emailError : "", successMessage: ""});

        this.validate();
    }

    

    validate() {
      let emailError = "";
      let apiError = "";

      this.setState({  apiError: "", emailError: "" });

      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      //console.log(reg.test(this.state.username))
      if (reg.test(this.state.username) === false) {
        emailError = "Email Field is Invalid ";
      }
      if (emailError) {
        this.setState({  emailError });
        return false;
      }
      return true;
    }


    
    handleSubmit  =  (event) =>  {

      event.preventDefault();

      if (this.validate()) {
        this.sendData();
      }

    }

 

    sendData  = async () =>  {

      const token = await this.recaptchaRef.current.executeAsync();
      const { navigation } = this.props;
        
      let that = this;
      that.setState({  isLoading: true });

      axios.post('/api/reset-password', {
        email: this.state.username,
        grc : token
      })
      .then(function (response) {

            let data = response.data;
            if(data.success ){
              that.setState({  successMessage: data.message });
            }else{

              that.setState({  apiError: data.message });
            }

            that.setState({  isLoading: false });

      })
      .catch(function (error) {
        that.setState({  isLoading: false,  apiError: 'Unable to connect with the api.' });
        console.log(error);

       // that.setState({  successMessage: 'Password reset email successfully sent. Please check your emails and follow instructions.' });
      });
 

      

    }
    

    render() {
      const { settings } = this.state;
      return (
        <>
        <div className='mx-auto w-[380px]'>
            <div className="w-full text-left text-sm">
                <form className="bg-white" onSubmit={this.handleSubmit}>
                    <div className='text-center'>
                        <img className="logo mx-auto mb-6" width="138" height="auto" src={logo} />
                        <h1 className='text-2xl md:text-3xl mb-8 font-normal'>Forget Password</h1>
                    </div>
                    {
                      !this.state.successMessage &&
                      <div className="mb-2 mb:mb-4">
                        <label className="block text-gray-700  font-semibold mb-2" htmlFor="username">Email address</label>
                        <input  onBlur={this.validate} onChange={this.handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.username || ''}    name="username" type="email" placeholder="yourname@company.com"></input>
                        <span className="text-red-500 text-xs italic">{this.state.emailError}</span>
                      </div>
                    }
                    {
                       !this.state.successMessage && settings && settings.dammore && settings.dammore.google_captcha_key &&
                      <ReCAPTCHA  ref={this.recaptchaRef} size="invisible" sitekey={settings.dammore.google_captcha_key} />
                    }
                    {
                       this.state.successMessage  &&
                       <span className="text-primary text-base italic mt-2 mb-8 block text-center">{this.state.successMessage}</span>
                    }
                    <div className="text-center">
                        {
                          !this.state.successMessage &&
                          <>  
                            {
                              !this.state.isLoading &&
                              <button type='submit' className="inline-block w-full mt-5 btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80">Send</button>
                            }
                            {
                              this.state.isLoading &&
                              <button type='button' className="w-full  mt-2 md:mt-5 btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default">
                                  <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  /> Loading...
                              </button>
                            }
                            <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                          </>
                        }
                        <p className='mt-4'><NavLink to="/login" className='font-semibold'>Back to Login</NavLink></p> 
                    </div>
                </form>
            </div>
          
        </div>
        </>
      );
    }
}

export default withParams(ForgetPwForm);