import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "../compornents/Header";
import Footer from "../compornents/Footer";
import Collections from "../compornents/Collections";
//const dataSource = process.env.REACT_APP_API_URL + '/people.json';

class DigitalAssets extends Component {

    constructor (props) {
        super(props)

        this.state = {
          componentData: []
        }
    }

    componentDidMount () {
       /*  axios.get(dataSource).then(response => {

          console.log(response);
          this.setState({
            componentData: response.data.components
          })
        }) */
    }


  render(){
    const { componentData } = this.state;
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> DPN - Digital Assets</title>
        </Helmet>
        <Header/>
        <div className="min-h-screen mx-auto mt-[95px] md:mt-[120px] z-0 relative mb-16">
            <Collections  />
        </div>
        <Footer />
        </div>
        </HelmetProvider>
    )
  }
}

export default DigitalAssets;
