import React, { Component } from 'react';
import { api } from '../Settings';
import axios from 'axios';
import { BiLoaderAlt } from "react-icons/bi";
import { MdContentCopy } from "react-icons/md";
import {
  TwitterIcon,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  PinterestIcon,
  WhatsappIcon,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

const qs = require('qs');

class Share extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: [],
          generating: false,
          shareLink: null,
          error: null
        }

        this.baseUrl = props.baseUrl;
        this.ids = props.ids;
        this.type = props.type;
        this.title = props.title;
        this.toggleShare = props.toggleShare.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.generateLink = this.generateLink.bind(this);

       // this.generateLink();

    }
    
   /*  static getDerivedStateFromProps(props, state) {
        return {data: props.data };
    } */

    componentDidMount () {
      this.generateLink();
    }

    generateLink(){
        const that = this;

        this.setState({ generating: true, error: null });

        const apiPath = (this.type === 'collection') ? api.getCollectionSharableLink : api.getAssetSharableLink;
        //const params = (this.type === 'collection') ? qs.stringify({ id : this.ids[0], is_token_base:1  }) : { params: { files : this.ids, is_token_base:1 } };
        const params = (this.type === 'collection') ? qs.stringify({ id : this.ids[0], is_token_base:1  }) :  qs.stringify({ files : this.ids, is_token_base:1 } );
        const type = (this.type === 'collection') ? 'post' : 'get';

        axios.post( `${this.baseUrl}${apiPath}`, params ).then(response => {

            console.log(response.data);
            if(response.data && response.data.success){
              that.setState({ shareLink: response.data.data });
            }else{
              that.setState({ error: response.data.message });
            }

            that.setState({ generating: false });
        })
        .catch(function (error) {
          console.log(error);
            that.setState({ generating: false, error: "Unable to connect with the api." });
        });
    }

    closeModal(){
      this.toggleShare(false);
    }


    render() {
        const { generating, error, shareLink } = this.state;
        return (
        <>
          <div className="modal fade fixed top-0 left-0  w-full h-full outline-none overflow-x-hidden overflow-y-auto z-12"
            tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog relative w-auto pointer-events-none max-w-[360px] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30">
              <div
                className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
                <div
                  className="modal-header flex flex-shrink-0 items-center justify-between p-3 border-b border-gray-200 rounded-t-md">
                  <h5 className="text-lg font-medium leading-normal text-gray-800" id="exampleModalLabel">Share</h5>
                  <button type="button"
                    className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                    data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body relative p-3">
                  {
                     generating &&

                     <span className="flex items-center justify-center text-primary transition-all duration-300 text-sm">
                        <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  />  Generating sharing link
                    </span>

                  }
                  {
                     error &&
                     <span className="flex items-center justify-center text-red-500 transition-all duration-300 text-sm">
                        {error}
                    </span>
                  }
                  {
                      !generating && !error &&
                      <div className='flex gap-1 items-center justify-center'>
                          <EmailShareButton url={`${shareLink}`} title={this.title}>
                              <EmailIcon size={48} round={true} />
                          </EmailShareButton>
                          <TwitterShareButton url={`${shareLink}`} title={this.title}>
                              <TwitterIcon size={48} round={true} />
                          </TwitterShareButton>
                          <FacebookShareButton url={`${shareLink}`} title={this.title}>
                              <FacebookIcon size={48} round={true} />
                          </FacebookShareButton>
                          <LinkedinShareButton url={`${shareLink}`} title={this.title}>
                              <LinkedinIcon size={48} round={true} />
                          </LinkedinShareButton>
                          <WhatsappShareButton url={`${shareLink}`} title={this.title}>
                              <WhatsappIcon size={48} round={true} />
                          </WhatsappShareButton>
                          <a onClick={() => {navigator.clipboard.writeText(shareLink)}} className="rounded-full bg-primary w-[48px] h-[48px] text-sm text-white flex items-center justify-center cursor-pointer">
                              <MdContentCopy className='w-6 h-6' />
                          </a>
                      </div>
                  }
                </div>
                <div
                  className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-3 border-t border-gray-200 rounded-b-md">
                  <button  onClick={this.closeModal} type="button" className="inline-block btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-1.5 px-6 rounded-md text-sm hover:text-white" data-bs-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
            <div className='bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-20'></div>
          </div>
        </>
        );
    }
}

export default Share;