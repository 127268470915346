import React, { Component } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation,  } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { GrRestaurant } from "react-icons/gr";
import { GiMeal, GiWaterBottle } from 'react-icons/gi';
import { FaCaretRight } from 'react-icons/fa';
import { api } from "../Settings";
import RecipeFilter from './RecipeFilter';
import Loader from "./Loader";
import ApiError from "./ApiError";
import EmptyData from "./EmptyData";
import { FiEdit } from 'react-icons/fi';

const dayjs = require('dayjs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}

class RecipeGrid extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: [],
          items:[],
          isLoading: false,
          isError: false,
          params: {},
          hasMore: true,
          loading: false,
          total:0,
          loadedCount:0,
          pageSize: 25
        }

        this.formatDate = this.formatDate.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.renderData = this.renderData.bind(this);
        this.getData = this.getData.bind(this);
        this.fetch = this.fetch.bind(this);
      
    }

    componentDidMount () {
        const that = this;
        that.setState({  isLoading: true, isError: false })
        this.getData(0, true); 
       
    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }

    updateSelection(filters) {

      const obj = {};

      console.log(filters);

    /*   if((filters && filters.chef.code) || (filters && filters.range.code) || (filters && filters.varity.code)){
          obj.qt = 1;
          obj.qf = [];

          if(filters.chef.code){
            obj.qf.push('created_chefs');
            obj.q = filters.chef.code
          }
          

          if(filters.year.code && filters.month.code){
            obj.q = filters.year.code + '-' + filters.month.code;
          }else if(filters.year.code){
            obj.q = filters.year.code;
          }else if(filters.month.code){
            obj.q = '-' + filters.month.code + '-';
          }
 

      }else{
          obj.qt = null;
          obj.qf = null;
          obj.q = null;
      }
 */
      if(filters && filters.sort.code){
        
        let sortParams = filters.sort.code.split('-');
        obj.sd = sortParams[0];
        obj.sp = sortParams[1];

      }else{
        obj.sd = null;
        obj.sp = null;
      }

      if(filters && filters.chef.items){

          const keys = [];
          filters.chef.items.map((tag, i) => (
            keys.push(tag.value)
          ));

          obj.created_chefs = keys;
          
      }else{
          obj.created_chefs = null
      }

      
      if(filters && filters.category.code){

          const keys = [];
          //filters.category.items.map((tag, i) => (
            keys.push(filters.category.code)
         // ));

          obj.recipe_category = keys;
          
      }else{
          obj.recipe_category = null
      }

      if(filters && filters.varity.items){

          const keys = [];
          filters.varity.items.map((tag, i) => (
            keys.push(tag.value)
          ));

          obj.recipes_variety = keys;
          
      }else{
          obj.recipes_variety = null
      }

      if(filters && filters.range.items){
          const keys = [];
          filters.range.items.map((tag, i) => (
            keys.push(tag.value)
          ));

          obj.recipes_tea_range = keys;
          
      }else{
          obj.recipes_tea_range = null
      }



      if(filters && filters.tags.items){

          const keys = [];
          filters.tags.items.map((tag, i) => (
              keys.push(tag.value)
          ));

          obj.tags = keys;
          
      }else{
          obj.tags = null
      }

      if(filters){
          this.setState({ params: { ...this.state.params, ...obj } ,  loadedCount: 0 },() => {
            this.getData(0, true)
          });
      }else{
          this.setState({ params: {} ,  loadedCount: 0 },() => {
            this.getData(0, true)
          });
      }

    }

    getData(count, reset){
       
      if (this.state.loading) {
          return;
      }

      if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
          this.setState({ hasMore: false })
          return;
      }else{
          this.setState({ hasMore: true })
      }
      
      this.setState({ loading: true })

      if(count === 0 ){
          this.setState({ loadedCount: 0  })
      }

      if(reset){
          this.setState({ items: [], loadedCount: 0  }, () => { this.fetch(reset) })
      }else if(this.state.loadedCount === 0){
          this.setState({ loadedCount: 25 },() => { this.fetch(reset) })
      }else{
          this.fetch(reset) 
      }

    }

    fetch(reset){

      const { navigation } = this.props;
      const { pathname  } = this.props.location;
      const params = this.state.params;
      const that = this;

      that.setState({  isLoading: true, isError: false })


      axios.get(api.getRecipeItems, { params: { ...params, st: this.state.loadedCount } }).then(response => {

          //Set total

          //console.log(response.data);
          const tot = response.data.total ? response.data.total : 0;
          this.setState({ total: tot });

          this.setState({ items: [ ...this.state.items, ...response.data.items ] },() => {

              this.setState({ isLoading: false, loading: false });
            

             // if(!reset){
                  const newCount = this.state.pageSize + this.state.loadedCount;
                 // console.log(newCount);
                  this.setState({ loadedCount: newCount })
             // }

          });

      })
      .catch(function (error) {

          if (error.response && error.response.status === 401 ) {
            navigation("/logout", { state: { previousPath: pathname } } );
              navigation(0);
          }

          

          that.setState({ isLoading: false, isError: true , loading: false })
      })
    }

    renderData(){
      const { isError,  items, hasMore, total  } = this.state;
      return (
       items &&
        <>
            {
                  total === 0 && !isError &&
                  <EmptyData/>
            }
            {                
            total > 0 &&
            <InfiniteScroll
                pageStart={0}
                loadMore={this.getData}
                initialLoad={false}
                hasMore={hasMore}
                loader={<div className="loader" key={0}><Loader /></div>}
            >
              <div className="mb-8 mt-4 md:mt-12 md:mb-16 grid grid-cols-1 md:grid-cols-3 gap-8 xl:gap-20">
                  {
                      items.map((item, index) => {
                          return (
                          <div key={index} className="item relative flex flex-wrap">
                              <div className="relative rounded-2xl image-box w-full h-0 pb-full bg-gray-500 bg-no-repeat bg-cover bg-center"  style={{ backgroundImage: `url("${item.image}")` }}><Link to={`/recipe/${item.hash}`} className="absolute w-full h-full"></Link></div>
                              <div className="content-box mt-4 flex flex-wrap w-full">
                                  <h3 className="text-xl md:text-2xl line-clamp-2 max-h-16 w-full"><Link to={`/recipe/${item.hash}`} className="block">{item.title}</Link></h3>
                                  <div className='text-sm  mt-3 w-full'>
                                        {
                                            item.chefs && item.chefs.length > 0 &&
                                              <span className='text-body/50 font-medium flex items-start mb-1'>
                                                <GrRestaurant className='mr-2 text-lg stroke-primary ' />
                                                <span className=''>
                                                {
                                                  item.chefs.map((obj, index) => {
                                                    return(
                                                      <span key={index} className='inline-block'>
                                                        <span>{obj.label}</span>
                                                        {
                                                          index !== (item.chefs.length - 1 ) &&
                                                          <span className='mr-0.5'>,</span>
                                                        }
                                                      </span>
                                                    )}
                                                  )
                                                }
                                                </span>
                                              </span>
                                        }
                                        {
                                            item.category && item.category.length > 0 &&
                                              <div className='text-body/50 font-medium mb-1 flex items-start'>
                                                <GiMeal className='mr-2 text-lg text-primary inline-block' />
                                                <div className='truncate'>
                                                Category&nbsp;-&nbsp;
                                                    {
                                                      item.category.map((obj, index) => {
                                                        return(
                                                          <React.Fragment key={index}>
                                                            {obj.label}
                                                            {
                                                              index !== (item.category.length - 1 ) &&
                                                              <>/</>
                                                            }
                                                          </React.Fragment>
                                                        )}
                                                      )
                                                    }
                                                </div>
                                              </div>
                                        }
                                        {
                                            item.variety && item.variety.length > 0 &&
                                              <div className='text-body/50 font-medium mb-1 flex items-start'>
                                                <GiWaterBottle className='mr-2 text-lg text-primary inline-block' />
                                                <div className='truncate'>
                                                Variety&nbsp;-&nbsp;
                                                    {
                                                      item.variety.map((obj, index) => {
                                                        return(
                                                          <React.Fragment key={index}>
                                                            {obj.label}
                                                            {
                                                              index !== (item.variety.length - 1 ) &&
                                                              <>/</>
                                                            }
                                                          </React.Fragment>
                                                        )}
                                                      )
                                                    }
                                                </div>
                                              </div>
                                        }
                                        {
                                            item.festivals && item.festivals.length > 0 &&
                                              <div className='text-body/50 font-medium mb-1 flex items-start'>
                                                <FaCaretRight className='mt-0.5 mr-2  min-w-[16px] text-base text-primary inline-block' />
                                                <div className='truncate'>
                                                Festival Names&nbsp;-&nbsp;
                                                    {
                                                      item.festivals.map((obj, index) => {
                                                        return(
                                                          <React.Fragment key={index}>
                                                            {obj.label}
                                                            {
                                                              index !== (item.festivals.length - 1 ) &&
                                                              <>/</>
                                                            }
                                                          </React.Fragment>
                                                        )}
                                                      )
                                                    }
                                                </div>
                                              </div>
                                        }
                                        {/* {
                                            item.festivals && item.festivals.length > 0 &&
                                              <span className='text-body/50 font-medium flex items-start mb-1'>
                                                <FaCaretRight className='mt-0.5 mr-2 text-base text-primary ' />
                                                <span className='mr-0.5 whitespace-nowrap'>Festival Names -</span>
                                                <span className=''>
                                                  {
                                                    item.festivals.map((obj, index) => {
                                                      return(
                                                        <span className='inline-block'>
                                                          <span>{obj.label}</span>
                                                          {
                                                            index !== (item.festivals.length - 1 ) &&
                                                            <span className=''>/</span>
                                                          }
                                                        </span>
                                                      )}
                                                    )
                                                  }
                                                </span>
                                              </span>
                                        } */}
                                        {
                                            item.teas && item.teas.length > 0 &&
                                              <div className='text-body/50 font-medium mb-1 flex items-start'>
                                                <FaCaretRight className='mt-0.5 mr-2 min-w-[16px] text-base text-primary inline-block' />
                                                <div className='truncate'>
                                                  Tea Used&nbsp;-&nbsp;
                                                    {
                                                      item.teas.map((obj, index) => {
                                                        return(
                                                          <React.Fragment key={index}>
                                                            {obj.label}
                                                            {
                                                              index !== (item.teas.length - 1 ) &&
                                                              <>/</>
                                                            }
                                                          </React.Fragment>
                                                        )}
                                                      )
                                                    }
                                                </div>
                                              </div>
                                        }

                                  </div>
                                  { item.hash && 
                                    <div className='w-full self-end '>
                                        <Link to={`/recipe/${item.hash}`} className="inline-block mt-4 btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-3 px-6 rounded-md text-sm hover:text-white">
                                          Read more
                                        </Link>
                                    </div>
                                  }
                              </div>
                              {
                                  EDIT_ACCESS && item.editLink &&
                                  <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                              }
                          </div>
                          );
                      })
                  }
              </div>
            </InfiniteScroll>
            }
        </>
      
      )
    }

    
    render() {
      const { isLoading, isError } = this.state;
      return (
        <>
          <h2  className="text-2xl md:text-3xl mb-8 font-semibold">All Recipes</h2>
          <RecipeFilter  mode="news" updateSelection={this.updateSelection}  />
          {isLoading ? <Loader /> : this.renderData()}
          {isError && <ApiError />}
        </>
      );
    }
}

export default withParams(RecipeGrid);