import React, { useRef, useState, useEffect  } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';
import { MdOutlineClose } from 'react-icons/md';
import { api } from "../Settings";
import { BiLoaderAlt } from 'react-icons/bi';
const qs = require('qs');

export default function TextEditor({item, toggleEditor, mode, baseurl}) {

  const editorRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setinitialValue] = useState('');
  const [editorLoaded, setEditorLoad] = useState(false);
  const id = (item.postId) ? item.postId : item.commentId;
  //const parentId = (item.parentId) ? item.parentId : null;
  const content = (item.post) ? item.post : item.comment;
  const requestPath = (mode === 'Update') ? 'forumEditDiscussions' : 'forumAddDiscussions';

  useEffect(()=>{

  if(content && mode === 'Update'){
    setinitialValue(content);
  }

  }, [])

  const log = () => {

    setErrorMessage(null);

    // console.log(editorRef,item, mode,'cdxdddddddddddddddddddddddddddddd');

    if (editorRef.current) {

        if(editorRef.current.getContent() === ""){
            setErrorMessage("Please add your message.");
            return;
        }

        console.log(mode)

        let paramsToMoveUpdate = { fp : editorRef.current.getContent(), fid: id, is_token_base: 1 }

        if ('title' in item){
          paramsToMoveUpdate = { 
                ...paramsToMoveUpdate,
                forumTitle: item.title
            }
        }

        const params = (mode === 'Update') ? paramsToMoveUpdate : { fp : editorRef.current.getContent(), p: id, is_token_base: 1 };

        axios.post( api[requestPath] , qs.stringify(params) ).then(response => {

            console.log(response.data);
            if(response.data && response.data.success){
              toggleEditor(item, false, true)
            }else{
              setErrorMessage(response.data.message);
            }

            //that.setState({ generating: false });
        })
        .catch(function (error) {
          console.log(error);
          setErrorMessage("Unable to connect with the api.");
        });


    }
  };

  return (
    <>
    {
      !editorLoaded &&
      <span className="w-full  mt-2 md:mt-5 flex items-center justify-center cursor-default text-body/50 text-sm">
          <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  /> Loading...
      </span>
    }
    <div className={`w-full ${editorLoaded ? "" : "hidden"}`}>
    {/* fixed bottom-0  left-1/2 -translate-x-1/2 transform z-40 bg-white p-4 <div className='flex items-center  pb-2'>
      <span className='block font-medium text-base md:text-xl mb-4 text-body/50'>Add a Reply</span>
      <button  onClick={() => toggleEditor(false, false)} type="button" className="text-primary bg-transparent hover:bg-gray-200 rounded-lg text-sm p-1.5 inline-flex items-center ml-auto" >
          <MdOutlineClose className='w-6 h-6' />
      </button>
    </div> */}
    <div className='pt-4 pb-4'>

      <Editor
        apiKey='pnmu7sa7p1lhte4lquiugrdag27btpsgs32z9rjq7piyqhyq'
        onInit={(evt, editor) => {
          editorRef.current = editor;
           console.log('init editor');
         // console.log(editor);
            setEditorLoad(true);
        }}
        initialValue={initialValue}
        init={{
          height: 200,
          menubar: false,
          valid_elements : '*[*]',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code wordcount'
          ],
          toolbar: 'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat fullscreen',
          content_style: 'body { font-family:Open+Sans,Segoe UI,Arial,sans-serif; font-size:14px; color: rgba(0,0,0,0.75) }'
        }}
      />
    </div>
    <div className='flex items-center'>
          {
              errorMessage &&
              <span className="flex items-center justify-center text-red-500 transition-all duration-300 text-sm">
                {errorMessage}
            </span>
          }
        <button  onClick={() => toggleEditor(item, false, false)}  className="ml-auto btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-2 px-5 rounded-md text-sm hover:text-white">Close</button>
        <button onClick={log} className="ml-2 btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-2 px-5 rounded-md text-sm hover:text-white">{mode}</button>
    </div>
    </div>
  {/*   <div className='bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-20'></div> */}
    </>
  );
}
