import React from 'react';
const field = (props) => {
    const fieldConfig = props.fieldConfig;
    let element = null;
    let classes = ['field'];
    if (fieldConfig.touched && !fieldConfig.valid) {
        classes.push('invalid');
    }
    switch (fieldConfig.type) {
        case 'date':
        case 'email':
        case 'text':
        case 'password':
            element = (<input type={fieldConfig.type}
                name={fieldConfig.id}
                value={fieldConfig.value}
                className={classes.join(' ') + ' shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'}
                placeholder={fieldConfig.placeholder}
               
                onBlur={props.blured}
                minLength={fieldConfig.validation.minLength}
                onChange={props.changed}
                maxLength={fieldConfig.validation.maxLength}
            />);
            break;
            case 'textarea':
                element = <textarea
                value={fieldConfig.value}
                className={classes.join(' ') + ' shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'}
                placeholder={fieldConfig.placeholder}
                
                onBlur={props.blured}
                minLength={fieldConfig.validation.minLength}
                onChange={props.changed}
                maxLength={fieldConfig.validation.maxLength}   
                />;
                break;
            case 'select':
                element = (
                    <select
                    value={fieldConfig.value}
                    className={classes.join(' ') + ' shadow appearance-none border  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'}
                    required={fieldConfig.validation.required}
                    onBlur={props.blured}
                    onChange={props.changed}>
                        <option>{fieldConfig.placeholder}</option>
                        {fieldConfig.options && fieldConfig.options.length > 0 && fieldConfig.options.map(option => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                );
                break;
            case 'radio':
                element = (
                   <div className='flex items-center'>
                    <div className='mr-4 flex items-center'>
                       <input type="radio" className="cursor-pointer" name={fieldConfig.id} id="male" value="male" />
                       <label className="ml-2 inline-block cursor-pointer"  htmlFor="male" >Male</label>
                    </div>
                    <div className='mr-4 flex items-center'>
                       <input type="radio" className="cursor-pointer" name={fieldConfig.id} id="female"  value="female" />
                       <label className="ml-2 inline-block cursor-pointer" htmlFor="female">Female</label>
                    </div>
                   </div>
                );
                break;
    }
    return (
            /*  ${fieldConfig.name === 'gender'  && ' col-span-2 '} */
        <div className={`field-wrapper mb-4 lg:mb-0`}>
            <label className="block text-gray-700  font-semibold mb-2" htmlFor={fieldConfig.id}>{fieldConfig.label}</label>
           {element}
           {!fieldConfig.valid && fieldConfig.touched &&
                <span className='text-red-500 text-xs italic block'>Enter valid {fieldConfig.label}</span>
           }
        </div>
    )
}
export default field;