import React, { Component } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link} from "react-router-dom";
import { FiDownload, FiImage } from "react-icons/fi";
import { HiOutlineDocumentText } from "react-icons/hi";
import { BiLoaderAlt } from "react-icons/bi";
import { BsPlayCircle, BsFileEarmarkMusic, BsShareFill, BsFileEarmarkPdf, BsFileEarmark } from "react-icons/bs";
import InfiniteScroll from 'react-infinite-scroller';
import Plyr from 'plyr';
import "plyr/dist/plyr.css";
import playerIcons from './../assets/plyr.svg';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import "./Fancybox.scss";
import { api } from "../Settings";
import FileSaver from "file-saver";
import AssetsMenu from './AssetsMenu';
import AssetsTree from './AssetsTree';
import AssetsFilter from './AssetsFilter';
import Loader from "./Loader";
import ApiError from "./ApiError";
import EmptyData from "./EmptyData";
import Share from './Share';
import Uploader from './Uploader';
import { FaRegFilePowerpoint, FaRegFileWord } from 'react-icons/fa';
import { MdAnimation } from 'react-icons/md';
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"

var qs = require('qs');
const userData = JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;


function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}
  

class Assets extends Component {

    constructor (props) {
        super(props);
        this.state = {
            data: {},
            items:[],
            params: {},
            selectedIds: [],
            breadcrumb: [],
            hasMore: true,
            isLoading: false,
            loadedCount:0,
            pageSize: 25,
            fixedMode: false,
            downloading: false,
            shareMode: false,
            selectedIds: [],
            selectedTitle: null,
            searchText: null,
            contentLoading: false,
            baseUrl: null,
            breakSession: false
        }

        this.renderIcon = this.renderIcon.bind(this);
        this.updateDir = this.updateDir.bind(this);
        this.updateSelection = this.updateSelection.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setPreviewUrl = this.setPreviewUrl.bind(this);
        this.setThumb = this.setThumb.bind(this);
        this.getData = this.getData.bind(this);
        this.fetch = this.fetch.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
       
        this.checkFileDownload = this.checkFileDownload.bind(this);
        this.downloadMedia = this.downloadMedia.bind(this);
        this.shareMedia = this.shareMedia.bind(this);
        this.shareSelectedMedia = this.shareSelectedMedia.bind(this);
        this.toggleShare = this.toggleShare.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.initFancy = this.initFancy.bind(this);
        this.getAssetDetail = this.getAssetDetail.bind(this);
        this.bytesToSize = this.bytesToSize.bind(this);
        this.setMediaType = this.setMediaType.bind(this);
        this.singleDownload = this.singleDownload.bind(this);
        this.singleDownloadCustom = this.singleDownloadCustom.bind(this);
        this.getParameterByName = this.getParameterByName.bind(this);
        this.getBreadcrumb = this.getBreadcrumb.bind(this);
        this.setqualityLevels = this.setqualityLevels.bind(this);
      
    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidMount () {
       // const { navigation } = this.props;

        const { state } = this.props.location;
        const { val } = state || {};
        if(!val){
            this.getData(0, true);
        }

        window.addEventListener('scroll', () => {

            if(document.querySelector('.item-wrp') ){

                const offsetPosition = document.querySelector('.item-wrp').offsetTop; 
                let fixed = false;
                if(window.scrollY > offsetPosition){
                    fixed = true;
                }
                this.setState({ fixedMode: fixed });
            }

        });

        this.initFancy();


       
    }

    handleItemClick(e) {

        let id = e.currentTarget.getAttribute("data-id");

        if(e.target.checked){
            this.setState({ selectedIds: [...this.state.selectedIds, id] }, () => {
                //this.requestDownload()
            });
        }else{
            let currIds = [...this.state.selectedIds];
            var index = currIds.indexOf(id)
            if (index !== -1) {
                currIds.splice(index, 1);
                this.setState({selectedIds: currIds},() => {
                   // this.requestDownload()
                });
            }
        }
       
    }
    

    initFancy(){

        Fancybox.bind('[data-fancybox="gallery"]', {
            click: false,
            dragToClose: false,
            touch: false,
            hideScrollbar : true,
            Toolbar: {
                autoEnable: false,
                display: [
                {
                    id: "counter",
                    position: "center",
                },
                "zoom",
                "slideshow",
                "fullscreen",
                "thumbs",
                "close",
                ],
            },
            Thumbs: {
                Carousel: {
                    Sync: {
                        friction: 0,
                    },
                    Dots: false,
                    Navigation: false,
                    center: true,
                    slidesPerPage: 1,
                    infinite: false,
                },
            },
            Carousel: {
                Panzoom: {
                    touch: false,
                },
                
                // Disable sliding animation
                friction: 0,
            },
            Image: {
                // Disable animation from/to thumbnail on start/close
                //zoom: false,
                touch: false,
                // Disable zoom on scroll event
                wheel: false,
            
                // Disable zoom on image click
                click: false
              },
            on: {
                initLayout: (fancybox) => {

                 
                // Create elements
                // ===
            
                // Create left column
                const $leftCol = document.createElement("div");
                $leftCol.classList.add("fancybox__leftCol");
            
                while (fancybox.$container.firstChild) {
                    $leftCol.appendChild(fancybox.$container.firstChild);
                }
            
                // Create right column
                const $rightCol = document.createElement("div");
                $rightCol.classList.add("fancybox__rightCol");
            
                $rightCol.innerHTML = '';
            
                // Create info-box
                const $info = document.createElement("div");
                $rightCol.appendChild($info);
                fancybox.$info = $info;
            
                // Add elements to DOM
                fancybox.$container.appendChild(fancybox.$backdrop);
            
                fancybox.$container.appendChild($leftCol);
                fancybox.$container.appendChild($rightCol);
            
                fancybox.$leftCol = $leftCol;
                fancybox.$rightCol = $rightCol;
                },
                "Carousel.ready Carousel.change": (fancybox, carousel, slideIndex) => {
                // Update info-box
                // ===
            
                // Get index of the current gallery item
                slideIndex =
                    slideIndex === undefined ? carousel.options.initialPage : slideIndex;
            
                // Get link related to current item
                const $trigger = fancybox.items[slideIndex].$trigger;
            
                // Get data from `data-info` attribute
                const title = $trigger.dataset.info ? `<h3 class="text-lg font-semibold mb-4 xl:fixed xl:top-2 xl:left-2 z-20 xl:max-w-2xl xl:truncate">${$trigger.dataset.info}</h3>` : "";
                const type = $trigger.dataset.mtype ? `<span class="flex mb-2">
                                                        <span class="font-semibold">File Type:</span>
                                                        <span class="_ml-auto ml-2 text-body/50">${$trigger.dataset.mtype}</span>
                                                    </span>` : "";
                const size = $trigger.dataset.size ? `<span class="flex mb-2">
                                                        <span class="font-semibold">Size:</span>
                                                        <span class="_ml-auto ml-2 text-body/50">${this.bytesToSize($trigger.dataset.size)}</span>
                                                    </span>` : "";
                const created = $trigger.dataset.created ? `<span class="flex mb-2">
                                                                <span class="font-semibold">Created Date:</span>
                                                                <span class="_ml-auto ml-2 text-body/50">${$trigger.dataset.created}</span>
                                                            </span>` : "";
                const modified = $trigger.dataset.modified ? `<span class="flex mb-2">
                                                                <span class="font-semibold">Modified Date:</span>
                                                                <span class="_ml-auto ml-2 text-body/50">${$trigger.dataset.modified}</span>
                                                            </span>` : "";
                let mediaData = '';
                let downloadData = '';
                let tagData = '';
                let desc = '';
            
                if($trigger.dataset.tags){
            
                    const items = $trigger.dataset.tags.split(',');
                    if(items.length > 0){
            
                        tagData += `<span class="flex mb-2">
                        <span class="font-semibold">Tags:</span>
                        <span class="_ml-auto ml-4 flex flex-wrap gap-2">`;
            
                        items.map((item, index) => {
                            tagData += `<span class="flex text-xs py-1 px-2 rounded-md bg-primary/10">${item}</span>`;
                        });
            
                        tagData += `</span></span>`;
            
                    }
            
                }
            
                if($trigger.dataset.id){
                    this.getAssetDetail($trigger.dataset.id).then((itemData) => {
    
                        //console.log(itemData.dataSets.otherSizes);
            
                        if(itemData.dataSets.description){
                            desc = `<div class="text-sm text-body/50">${itemData.dataSets.description}</div>`;
                        }
                        
                        let mediaData = '<div class="mt-8">';
                        mediaData += `<h3 class="text-sm font-bold mb-4">Available Downloads</h3>`;
            
                        mediaData += `<h3 class="text-xs font-bold mb-2">Original</h3>
                        <div class="flex flex-wrap gap-2 mb-4">
                            <a class="media-down cursor-pointer flex text-xs p-2 rounded-md bg-primary/10 hover:bg-primary/30 transition-all duration-300" data-id="${itemData.dataSets.file_id}"><svg class="mr-1.5 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg> ${itemData.dataSets.ext}</a>
                        </div>`;
            
            
                        downloadData += `<div class="bg-primary py-2 pr-3 pl-4 rounded-md text-white fixed top-[60px] right-[16px] xl:top-1.5 xl:right-[250px] z-20 group">
                        
                        <div class="flex items-center cursor-pointer">
                            Downloads
                            <svg class="ml-1.5 w-4 h-4 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>   
                        </div>
                        <div class="duration-300 transition-all download-box opacity-0 invisible group-hover:visible  group-hover:opacity-100  absolute top-10 right-0 bg-primary shadow rounded-lg py-2 text-white text-right min-w-[150px]">
                        <div class="mb-2">
                        <span class="block px-3 text-sm">Original size</span>
                            <div class="px-3">
                                <a  data-id="${itemData.dataSets.file_id}" class="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70 media-down">
                                    <span class="uppercase pr-2">${itemData.dataSets.ext}</span>
                                    ${ itemData.dataSets.fileWidth && itemData.dataSets.fileHeight ? ` <span class='opacity-70'> (${itemData.dataSets.fileWidth} x ${itemData.dataSets.fileHeight})</span> ` : '' }
                                </a>  
                            </div>
                        </div>`;
            
            
                        if(itemData.dataSets.otherSizes && itemData.dataSets.otherSizes.length > 0 ){
                            mediaData += `<h3 class="text-sm font-bold mb-4">Other sizes</h3><div class="flex flex-wrap gap-2">`;
            
                            downloadData += `
                            <div class="mb-2">
                                <span class="block px-3 border-t pt-2 border-white/50 text-sm">Other Sizes</span>
                                <div class="px-3">`;
            
                            itemData.dataSets.otherSizes.map((item, index) => {
            
                                mediaData += `<a class="media-down cursor-pointer flex text-xs p-2 rounded-md bg-primary/10 hover:bg-primary/30 transition-all duration-300"  data-id="${item.key}" data-name="${item.name}"  ><svg class="mr-1.5 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg> ${item.heading}</a>`;
            
                                downloadData += `
                                <a data-id="${item.key}" data-name="${item.name}"  class="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70 media-down">
                                <span class='opacity-70'> ${item.heading}</span> 
                                </a>`;
            
                            });
            
                            mediaData += '</div>';
            
            
                            downloadData += `</div></div>`;
                        }
            
                        if(itemData.dataSets.otherFormats && itemData.dataSets.otherFormats.length > 0 ){
                            mediaData += `<h3 class="text-sm font-bold mb-4  ">Other formats</h3><div class="flex flex-wrap gap-2">`;
            
                            downloadData += `
                            <div class="mb-2">
                                <span class="block px-3 border-t pt-2 border-white/50 text-sm ">Other Formats</span>
                                <div class="px-3">`;
            
                            itemData.dataSets.otherFormats.map((item, index) => {
            
                                mediaData += `<a class="cursor-pointer flex text-xs p-2 rounded-md bg-primary/10 hover:bg-primary/30 transition-all duration-300 media-down"  data-id="${item.key}" data-name="${item.name}" ><svg class="mr-1.5 w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"/></svg> ${item.heading}</a>`;
            
            
                                downloadData += `
                                <a data-id="${item.key}" data-name="${item.name}" class="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70 uppercase media-down">
                                <span class='opacity-70'> ${item.heading}</span> 
                                </a>`;
            
            
                            });
            
                            mediaData += '</div>';
            
                            downloadData += `</div></div>`;
                        }
            
                        if(itemData.dataSets.relatedItems  && itemData.dataSets.relatedItems.length > 0){
                            mediaData += `<h3 class="text-sm font-bold mb-4 mt-6"> Related items</h3><div class="grid grid-cols-2 gap-4">`;
            
                            itemData.dataSets.relatedItems.map((item, index) => {
            
                                mediaData += `<a 
                                data-fancybox="gallery" 
                                data-id='${item.key}'
                                data-info='${item.name}'
                                data-type='${item.mediaType}'
                                data-size='${item.fileSize}' 
                                data-href='${item.url}&access_token=${ASSCESS_TOKEN}'
                                class="block w-full h-16 cursor-default" 
                                ><span class="block w-full h-full bg-primary/20 bg-no-repeat bg-contain bg-center" style="background-image:url('${item.thumbnail}&access_token=${ASSCESS_TOKEN}&is_token_base=1')" ></span></a>`;
                            });
            
                            mediaData += '</div>';
                        }
            
                        downloadData += `</div></div>`;
            
            
                        mediaData += '</div>';
                        //fancybox.$info.innerHTML += mediaData;
                        fancybox.$info.querySelector('.desc').innerHTML = desc;
                        fancybox.$info.querySelector('.mediaData').innerHTML = mediaData;
                        //fancybox.$info.querySelector('.download-area').innerHTML = downloadData;
            
                        const MediaDownload = (e) => {
                        
                            let url = e.currentTarget.getAttribute("data-href");
                            let name = e.currentTarget.getAttribute("data-name");
            
                           // console.log(url, name);
                            FileSaver.saveAs(url, name);
                            // ...
                        };
            
                        const SeverDownload = (e) => {
                        
                            let id = e.currentTarget.getAttribute("data-id");
                            let name = e.currentTarget.getAttribute("data-name");
               
                           // console.log(id, name);
                            this.downloadMedia({ id: id, name: name });
    
                        }; 
            
                        document.querySelectorAll(".fancy-down").forEach(el=>el.addEventListener("click", MediaDownload));
                        document.querySelectorAll(".media-down").forEach(el=>el.addEventListener("click", SeverDownload));      
            
                    });
            
            
                    
                }
                // Update info
                fancybox.$info.innerHTML = `<div class="text-sm">
                    ${title}
                    <div class="desc mb-4"></div>
                    ${type}
                    ${size}
                    ${created}
                    ${modified}
                    ${tagData}
                    <div class="mediaData mb-4"></div>
                    <div class="download-area mb-4"></div>
                </div>`;
                },
                
                reveal: (fancybox, slide) => {
                    if (typeof Plyr === undefined) {
                        return;
                    }
                
                    let $el;
                
                    if (slide.type === "html5video") {
                        $el = slide.$content.querySelector("video");
                    } else if (slide.type === "video") {
                        $el = document.createElement("div");
                        $el.classList.add("plyr__video-embed");
                
                        $el.appendChild(slide.$iframe);
                
                        slide.$content.appendChild($el);
                    }


                    if ($el) {


                        let vt = 480;
                        if (window.innerHeight <= 240) {
                            vt = 240;
                        } else if (window.innerHeight <= 360) {
                            vt = 360;
                        }

                        let prevType = vt;
                        let qts = (slide.qt) ? slide.qt.split(",") : [];

                        slide.player = new Plyr($el, {
                            iconUrl: playerIcons,
                            controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'airplay', 'fullscreen'],
                            quality: { default: vt, options: qts, forced: true, onChange: (quality) => {

                                if(quality === prevType){
                                    return;
                                }

                                prevType = quality;

                                if(slide.player){

                                    let src =  $el.querySelector('source').getAttribute( "src");
                                    const newStr =  src.replace(/vt=.*p/, `vt=${quality}p`);
                                    $el.querySelector('source').setAttribute( "src", newStr)
                                    // slide.player.source = $el;
                                    
                                    $el.load();
                                    $el.play();
                               
                                }

                            } },
                            settings: [ 'quality', 'speed']
                        });

                      
                    }
                },
                "Carousel.unselectSlide": (fancybox, carousel, slide) => {
                    if (slide.player) {
                        slide.player.pause();
                    }
                },
                "Carousel.selectSlide": (fancybox, carousel, slide) => {
                    if (slide.player) {
                        slide.player.play();
                    }
                },
            },
        });

        Fancybox.Plugins.Thumbs.defaults.Carousel.preload = 0;
        Fancybox.defaults.ScrollLock = false;

    }

    getAssetDetail(id){

        return new Promise((resolve, reject) => {
                
            axios.get(api.getAssetItemDetail, { params : { ii: id }}).then(response => {
                resolve(response.data);
            })
            .catch(function (error) {

                if (error.response && error.response.status === 401 ) {
                    reject(error.response);
                }
            });

        });
    
    }

    bytesToSize(bytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
        if (bytes === 0) return 'n/a'
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
        if (i === 0) return `${bytes} ${sizes[i]})`
        return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }

    clearSelection(){
        this.updateSelection(null);
    }


    updateSelection(filters) {

       // this.setState({ searchText: null });

        const obj = {};
        obj.ftr = (filters && filters.type.code) ? filters.type.code : null;
        obj.fs = (filters && filters.size.code) ? filters.size.code : null;
        obj.ud = (filters && filters.date.code) ? filters.date.code : null;
        obj.q = (filters && filters.q.label) ? filters.q.label : null;
        //obj.q = null;

        this.setState({ selectedIds:[] });

        if(filters && filters.dir.code){
            obj.dir = filters.dir.code;
        }

        if(filters && filters.sort.code){
        
            let sortParams = filters.sort.code.split('-');
            obj.sd = sortParams[0];
            obj.sp = sortParams[1];

        }else{
            obj.sd = null;
            obj.sp = null;
        }

        if(filters && filters.tags.items){
  
            const keys = [];
            filters.tags.items.map((tag, i) => {
                keys.push(tag.value);
            });

            obj.tids = keys;
            
        }else{
            obj.tids = null
        }

        if(filters){
            this.setState({ params: { ...this.state.params, ...obj }, loadedCount: 0 },() => {
                this.getData(0, true)
            });
        }else{
            this.setState({ params: {}, loadedCount: 0  },() => {
                this.getData(0, true)
            });
        }

    }

    getData(count, reset){
       
        if (this.state.isLoading) {
            return;
        }

        if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
            this.setState({ hasMore: false })
            return;
        }else{
            this.setState({ hasMore: true })
        }
        
        this.setState({ isLoading: true })

        if(count === 0 ){
            this.setState({ loadedCount: 0  })
        }

        if(reset){
            this.setState({ items: [], loadedCount: 0  }, () => { this.fetch(reset) })
        }else if(this.state.loadedCount === 0){
            this.setState({ loadedCount: 25 },() => { this.fetch(reset) })
        }else{
            this.fetch(reset) 
        }

    }

    fetch(reset){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;
        const dataPath = (!Object.keys(this.state.params).length) ? api.getFeaturedAssets : api.getAssets;
        const params = this.state.params;
        const that = this;      
        
        //test
       // navigation("/logout", { state: { previousPath: pathname } } );
      //  navigation(0);

      //  console.log("llll", pathname )


        if(reset){
            that.setState({  contentLoading: true, isError: false })
        }
        

       // setTimeout(() => {
            
                    axios.get(dataPath, { params: { ...params, st: this.state.loadedCount } }).then(response => {
            
                        //Set total
                        const tot = response.data.total ? response.data.total : 0;
                        this.setState({ total: tot });
                        this.setState({ data: response.data, baseUrl: response.data.baseUrl });
            
                        this.setState({ items: [ ...this.state.items, ...response.data.items ] },() => {
            
                            that.setState({ contentLoading: false, isError: false, isLoading: false })
                            
                            //if(!reset){
                                const newCount = this.state.pageSize + this.state.loadedCount;
                                this.setState({ loadedCount: newCount })
                           // }
            
                        });
            
                    })
                    .catch(function (error) {

                        

                        if (error.response && error.response.status === 401 ) {
                           // that.setState({ breakSession: true });

                           navigation("/logout", { state: { previousPath: pathname } } );
                           navigation(0);
                        }

                        that.setState({ contentLoading: false, isError: true , isLoading: false })
                    })

        //}, 5000);


    }

    updateDir(filters) {

        this.setState({ selectedIds:[] });

        this.getBreadcrumb(filters.dir.code);

        if(filters && filters.dir.code){
            this.setState({ params: { ...this.state.params, dir: filters.dir.code}, loadedCount: 0  },() => {
                this.getData(0, true)
            });
        }
    }


    getBreadcrumb(id){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;

        axios.get(api.getAssetBreadcrumb, { params: { ii: id } }).then(response => {
            
            if(response.data && response.data.items ){
                this.setState({ breadcrumb: response.data.items })
            }

        })
        .catch(function (error) {

            
            if (error.response && error.response.status === 401 ) {
                //that.setState({ breakSession: true });
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }
        })
    }

    setqualityLevels(qts){

        if(!qts){
            return;
        }

        let data = [];

        qts.map((item, index) => {
            data.push(item.qt);
        });

        return data;
    }

    setPreviewUrl(url, type){

        let posterHeight = '960';
        if (window.innerHeight <= 240) {
            posterHeight = '240';
        } else if (window.innerHeight <= 360) {
            posterHeight = '360';
        } else if (window.innerHeight <= 480) {
            posterHeight = '480';
        } else if (window.innerHeight <= 720) {
            posterHeight = '720';
        } 

        let vt = '480p';
        if (window.innerHeight <= 240) {
            vt = '240p';
        } else if (window.innerHeight <= 360) {
            vt = '360p';
        }

        switch (type) {
            case 'image':
                return (`${url}&access_token=${ASSCESS_TOKEN}&act=resize&width=0&height=${posterHeight}`)
                break;

            case 'vector':
                return (`${url}&access_token=${ASSCESS_TOKEN}&act=resize&width=0&height=${posterHeight}`)
                break;

            case 'video':
                return (`${url}&access_token=${ASSCESS_TOKEN}&vt=${vt}`)
                break;

            default:
                return (`${url}&access_token=${ASSCESS_TOKEN}`)
                break;
        }
    }

    setThumb(url, type){

        switch (type) {
            case 'image':
            case 'powerpoint':
            case 'word':
            case 'vector':
            case 'pdf':
            case 'video':
            case 'animation':
            case 'swf':
                return (`${url}&access_token=${ASSCESS_TOKEN}&width=320&height=0`)
                break;
            default:
                return (`${url}`)
                break;
        }
    }


    downloadFile(e){

        let url = e.currentTarget.getAttribute("data-href");
        let name = e.currentTarget.getAttribute("data-name");
        console.log(url);
        FileSaver.saveAs(url, name);
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    singleDownload(e){
        let id = e.currentTarget.getAttribute("data-id");
        let name = e.currentTarget.getAttribute("data-name");
        this.downloadMedia({ id: id, name: name });
    }

    singleDownloadCustom(e){
        let id = e.currentTarget.getAttribute("data-id");
        let key = e.currentTarget.getAttribute("data-key");
        let width = e.currentTarget.getAttribute("data-width");
        let height = e.currentTarget.getAttribute("data-height");
        let name = `${key}_${width}x${height}.jpg`;
        this.downloadMedia({ id: id, name: name });
    }

    downloadMedia(data){

        const path = this.state.baseUrl;
        const that = this;
        const dataObj = {};

       if(data &&  data.id){
            dataObj['fileIdArray[]'] = data.id;

            if( data.name){
                dataObj['fn['+data.id+']'] = data.name;
            }

            dataObj.is_token_base = 1;

       }else{   
            dataObj.fileIdArray = this.state.selectedIds;
            dataObj.is_token_base = 1;
       }
        
        this.setState({ downloading: true });

        axios.post(path+ '/mediaManager/media-request/prepare-download-file', qs.stringify(dataObj)).then(response => {
            if(response.data && response.data.hash){
                this.checkFileDownload(path, response.data.hash)
            }else{
                that.setState({ downloading: false });
            }
        })
        .catch(function (error) {
            that.setState({ downloading: false });
        });
    
    }


    checkFileDownload(path, id){

        let timer;
        let time = 1000;
        const that = this;

        clearInterval(timer);
        timer = setInterval(function(){

            axios.post(path+ '/mediaManager/media-download/check-file-complete-and-download',  qs.stringify({
                'file[]' : id,
                'is_token_base' : 1
              }) ).then(response => {
                //console.log(response.data);
              //  this.checkFileDownload(path)

              if (response.data.success) {
                // start sending request to check weather file is ready to download

                //console.log('download bbb')

                response.data.files.map((record, index) => {

                   // console.log('download filee', record)

                    if (record.file_complete === "1") {
                        const isZip = (that.state.selectedIds.length > 1) ? 1 : 0;
        
                        window.location.assign(path+ '/mediaManager/media/download/file/' + record.download_key + '/zipFile/' + isZip + '?access_token=' + ASSCESS_TOKEN );

                        that.setState({ downloading: false, selectedIds:[] });
                                    
                        //window.open(path+ '/mediaManager/media/download/file/' + record.download_key + '/zipFile/' + isZip + '?access_token=' + ASSCESS_TOKEN , '_blank');

                        clearInterval(timer);
       
                    }

                });

            }


            })
            .catch(function (error) {
                that.setState({ downloading: false });
            });

            time += 2000;
        }, time);
    }

    
    shareMedia(e){

        let id = e.currentTarget.getAttribute("data-id");
        let title = e.currentTarget.getAttribute("data-title");

        if(id){
            this.setState({ selectedIds: [id], selectedTitle: title, shareMode: true });
        }
    }

    shareSelectedMedia(e){
        this.setState({ selectedTitle: '', shareMode: true });   
    }

    toggleShare(state){
        this.setState({ shareMode: state, selectedIds: [] });
    }


    
    setMediaType(param) {
        switch(param) {
            case 'image':
            case 'vector':
            return ('image');
            break;
          case 'pdf':
            return ('pdf');
            break;
          case 'audio':
            return ('html5video');
            break;
          case 'video':
            return ('html5video');
            break;
          default:
            return ('iframe');
            break;
        }
    }



    renderIcon(param) {
        switch(param) {
            case 'image':
            case 'vector':
                return (<FiImage className='text-white text-base mr-0.5  mt-0.5 min-w-[24px]'/>);
            case 'powerpoint':
                return (<FaRegFilePowerpoint className='text-white text-base mr-0.5  mt-0.5 min-w-[24px]'/>);
            case 'word':
                return (<FaRegFileWord className='text-white text-base mr-0.5  mt-0.5 min-w-[24px]'/>);
            case 'animation':
            case 'swf':
                return (<MdAnimation className='text-white text-base mr-0.5  mt-0.5 min-w-[24px]'/>);
            case 'audio':
                return (<BsFileEarmarkMusic className='text-white text-base mr-0.5  mt-0.5 min-w-[24px]'/>);
            case 'pdf':
                return (<BsFileEarmarkPdf className='text-white text-base mr-0.5  mt-0.5 min-w-[24px]'/>);
            case 'video':
                return (<BsPlayCircle className='text-white text-base mr-0.5 mt-0.5  min-w-[24px]'/>);
            case 'file':
                return (<HiOutlineDocumentText className='text-white text-base mr-0.5 mt-0.5 min-w-[24px]'/>);
            default:
                return (<BsFileEarmark className='text-white text-base mr-0.5 mt-0.5 min-w-[24px]'/>);
        }
    }

    renderData(){
        const {  items, hasMore, total, selectedIds, isError  } = this.state;
        return (
            <>
                        {
                             total === 0  && !isError &&
                             <EmptyData message="There are no assets to display." />
                        }
                        {
                            
                            total > 0 &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.getData}
                                initialLoad={false}
                                hasMore={hasMore}
                                loader={<div className="loader" key={0}><Loader /></div>}
                            >
                                <div className="item-wrp items relative z-0">
                                <ResponsiveMasonry columnsCountBreakPoints={{ 650: 2, 767: 4, 1279: 5}} >
                                    <Masonry gutter="10px" columnsCount={2}>
                                    {
                                        items &&
                                        items.map((item, index) => {
                                            return (
                                            <div key={index} className={`relative bg-primary/20  transition-all duration-300 rounded-md overflow-hidden  group ${selectedIds.indexOf(item.id) > -1 ? "p-2 xl:p-4"  : ""}`}>
                                                    <div className='relative'>

                                                        {

                                                            item.preview === "true" &&
                                                            <a  
                                                            data-fancybox="gallery" 
                                                            data-id={item.id} 
                                                            data-info={item.fileName} 
                                                            data-mtype={item.type} 
                                                            data-qt={this.setqualityLevels(item.qualityLevels)} 
                                                            data-type={this.setMediaType(item.type)} 
                                                            data-modified={item.modified} 
                                                            data-created={item.created} 
                                                            data-size={item.size} 
                                                            data-tags={item.tags} 
                                                            href={this.setPreviewUrl( item.url, item.type)} 
                                                            className="block w-full h-full z-11 relative">
                                                                <div className={`relative ${item.fileWidth && item.fileHeight ? `h-0` : 'min-h-[50px]' }`} style={ {paddingBottom: item.fileHeight && item.fileWidth && item.fileHeight / item.fileWidth * 100+'% '}}> 
                                                                    <img src={this.setThumb( item.thumb, item.type)} className="w-full h-auto" />
                                                                    <div className='absolute bottom-0 left-0 block w-full h-8 from-gray-600 bg-gradient-to-t z-10'></div>
                                                                </div>
                                                                <span className="relative z-10 bg-gray-600 w-full  pb-2 px-2 group-hover:from-gray-600 duration-300 transition-all text-xs font-medium text-white flex items-start">{this.renderIcon(item.type)}{item.fileName}</span>
                                                                
                                                            </a>
                                                        }
                                                        {

                                                            item.preview !== "true" &&
                                                            <a 
                                                            className="block w-full h-full z-11 relative">
                                                                <div className={`relative ${item.fileWidth && item.fileHeight ? `h-0` : 'min-h-[50px]' }`} style={ {paddingBottom: item.fileHeight && item.fileWidth && item.fileHeight / item.fileWidth * 100+'% '}}> 
                                                                    <img src={this.setThumb( item.thumb, item.type)} className="w-full h-auto" />
                                                                    <div className='absolute bottom-0 left-0 block w-full h-8 from-gray-600 bg-gradient-to-t z-10'></div>
                                                                </div>
                                                                <span className="relative z-10 bg-gray-600 w-full  pb-2 px-2 group-hover:from-gray-600 duration-300 transition-all text-xs font-medium text-white flex items-start">{this.renderIcon(item.type)}{item.fileName}</span>
                                                                
                                                            </a>
                                                        }

                                                        <div className={`actions flex items-center z-11 absolute top-2 w-full px-2 ${selectedIds.indexOf(item.id) > -1 ? ""  : "opacity-0 group-hover:opacity-100 duration-300 transition-all"}`}>
                                                            <input data-id={item.id} onClick={(e) => this.handleItemClick(e)} type="checkbox" value="" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary  focus:ring-0"></input>
                                                                {item.type == 'image' &&
                                                                    <span data-href={item.url} className="download btn bg-white shadow-md p-1.5 rounded-md text-sm text-primary hover:shadow-lg relative mr-1 ml-auto">
                                                                    <FiDownload className='cursor-pointer' />
                                                                    <div className='duration-300 transition-all invisible download-box opacity-0 absolute bottom-100 right-0 bg-primary shadow rounded-lg py-2 p-3 text-white text-right'>
                                                                        <a onClick={this.singleDownload} data-id={item.id} data-name={item.title} className="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70">
                                                                        <span className='opacity-70'>Original</span> 
                                                                        </a>
                                                                        <a onClick={this.singleDownloadCustom} data-id={item.id}  data-key={item.dataKey}   data-width="140" data-height="124" className="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70">
                                                                        <span className='opacity-70'>140 x 124</span> 
                                                                        </a>
                                                                        <a onClick={this.singleDownloadCustom} data-id={item.id}  data-key={item.dataKey}   data-width="800" data-height="800" data-name={item.title} className="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70">
                                                                        <span className='opacity-70'>800 x 800</span> 
                                                                        </a>
                                                                        <a onClick={this.singleDownloadCustom}  data-id={item.id} data-key={item.dataKey}   data-width="1440" data-height="960"  data-name={item.title}  className="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70">
                                                                        <span className='opacity-70'>1440 x 960</span> 
                                                                        </a>
                                                                        <a onClick={this.singleDownloadCustom} data-id={item.id} data-key={item.dataKey}  data-width="2000" data-height="2000" data-name={item.title}  className="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70">
                                                                        <span className='opacity-70'>2000 x 2000</span> 
                                                                        </a>
                                                                    </div>
                                                                    </span>
                                                                }
                                                                {item.type != 'image' &&
                                                                    <span  onClick={this.singleDownload} data-id={item.id} data-name={item.title} className="cursor-pointer download btn bg-white shadow-md p-1.5 rounded-md text-sm text-primary hover:shadow-lg relative mr-1 ml-auto">
                                                                        <FiDownload />
                                                                    </span>
                                                                }
                                                            <a  onClick={this.shareMedia} data-title={item.title} data-id={item.id} className="cursor-pointer share group-acb btn bg-white shadow-md p-1.5 rounded-md text-sm text-primary hover:shadow-lg relative" >
                                                                <BsShareFill />
                                                                
                                                            </a>     
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }      
                                </Masonry>
                               </ResponsiveMasonry>
                             </div>
                            </InfiniteScroll>
                        }
            </>
        )
    }
      
    render() {
        const { data, items, hasMore, fixedMode, total, selectedIds, downloading, shareMode, selectedTitle, contentLoading, isError, isLoading, breadcrumb  } = this.state;
        return (
            <>  
            
                <div className='container mx-auto'>
                    <div className='my-6 flex flex-wrap items-center text-sm text-primary  xl:px-8'>
                        <Link to="/">Home</Link><span className='mx-1'>/</span>
                        <Link to="/digital-assets">Digital Assets</Link>
                        {
                             breadcrumb.length > 0 &&
                            <span className='mx-1'>/</span>
                        }
                        {
                                breadcrumb.length > 0 &&
                                
                                breadcrumb.map((item, index) => {
                                    return (
                                        item.name !== 'Media Bank' && 
                                        <div key={index}>

                                            <span dangerouslySetInnerHTML={{__html : item.name}}></span>
                                            
                                            {
                                                index !== (breadcrumb.length - 1)  && 
                                                <span className='mx-1'>/</span>
                                            }
                                        </div>
                                    )
                                })

                        } 
                    </div>  
                </div>  
            
                <div className={` ${fixedMode ? 'fixed-mode' : ''}`}>
                    <div className={`asset-menu xl:px-8 border-b-2  md:pb-3 mb-4 lg:mb-8 z-[9] bg-white`}>
                        <div className="flex  flex-wrap items-center mb-4 text-body container mx-auto"> 
                            <AssetsMenu />
                            <div className='flex flex-wrap md:flex-nowrap items-center w-full lg:w-3/4'>
                                {
                                    data.title &&
                                    <h1 className="text-xl  md:text-3xl  mt-4 lg:mt-0 font-semibold text-primary">{data.title}</h1>
                                }
                                {
                                    breadcrumb.length > 0 &&
                                    <h1 className="text-xl  md:text-3xl  mt-4 lg:mt-0 font-semibold text-primary" dangerouslySetInnerHTML={{__html : breadcrumb[breadcrumb.length - 1].name}}></h1>

                                    
                                } 
                                <div className='w-full md:w-auto md:ml-auto mt-2 md:mt-0'>
                                    <Uploader />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-body container xl:px-8 mx-auto">

                        <div className='flex'>
                            <div className='w-1/4 pr-4 xl:pr-8 hidden lg:block z-0 relative '>
                                <AssetsTree updateDir={this.updateDir}  />
                            </div>
                            <div className='w-full lg:w-3/4'>
                              
                                <AssetsFilter mode="asset" updateSelection={this.updateSelection} />
                                <div className='flex relative items-center mb-4 md:mb-12'>
                                {/*  {
                                        searchText &&
                                        <>
                                        <span className='text-xl text-primary'>Search Results for {searchText}</span>
                                        <span  onClick={this.clearSelection}  className='ml-4 cursor-pointer hover:text-primary'>Clear</span>
                                        </>
                                    } */}
                                    <div className='ml-auto flex items-center flex-wrap'>
                                    {/*   {
                                            data.shareCount &&
                                            <span className='mr-8'>Shared with {data.shareCount} people</span>
                                        } */}
                                    
                                        {
                                            total > 0 &&
                                            <span className='md:mr-8 mb-4 md:mb-0 text-sm md:text-base w-full md:w-auto'>

                                                {
                                                    selectedIds.length > 0 &&
                                                    <span className='pr-1'>{selectedIds.length} items selected, </span>
                                                }
                                                {total} Assets
                                            </span>
                                        }

                                        {
                                            selectedIds.length > 0 && !downloading && 
                                            <a onClick={this.downloadMedia}  className="btn flex items-center text-primary border border-primary hover:bg-primary transition-all duration-300  py-1.5 md:py-3 px-3 md:px-6 rounded-md text-sm hover:text-white cursor-pointer">
                                                <FiDownload className='mr-2' /> Download Selected
                                            </a>
                                        }
                                        
                                        {
                                            downloading &&
                                            <a className="fixed bottom-4 right-4 btn flex items-center border border-primary bg-primary transition-all duration-300  py-1.5 md:py-3 px-3 md:px-6 rounded-md text-sm text-white cursor-pointer z-[1100] shadow-lg">
                                                <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  />  Preparing download
                                            </a>
                                        }
                                        {/* {
                                            selectedIds.length == 0 && 
                                            <a onClick={this.downloadMedia} className="btn flex items-center text-primary border border-primary hover:bg-primary transition-all duration-300  py-3 px-6 rounded-md text-sm hover:text-white cursor-pointer">
                                            <FiDownload className='mr-2' /> Download all
                                            </a>
                                        } */}

                                        { 
                                        selectedIds.length > 0 &&
                                        <a onClick={this.shareSelectedMedia} className="ml-auto md:ml-4 btn flex items-center text-primary border border-primary hover:bg-primary transition-all duration-300 py-1.5 md:py-3 px-3 md:px-6 rounded-md text-sm hover:text-white cursor-pointer">
                                        <BsShareFill className='mr-2' />
                                        Share
                                        </a> }
                                    </div>
                                </div>

                                {contentLoading ? <Loader /> : this.renderData()}
                                {isError && <ApiError />}

                            
                            </div>
                        </div>
                    </div>
                    {
                        shareMode &&
                        <Share toggleShare={this.toggleShare} type="asset" ids={selectedIds} title={selectedTitle} baseUrl={data.baseUrl} />
                    }
                </div>
               {/*  {
                    this.state.breakSession &&
                    <AuthRouter />
                } */}
            </>
      );
    }
}

export default withParams(Assets);