import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { api } from "../Settings";
import axios from 'axios';
import { MdCloudUpload } from 'react-icons/md';
import pl from 'plupload/js/plupload.full.min.js';
//import Plupload from 'plupload';
import Plupload from 'react-plupload';
import { BiLoaderAlt } from 'react-icons/bi';
const qs = require('qs');
const dayjs = require('dayjs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
const USER_EMAIL = (userData) && userData.details.username;
const UPLOAD_DIR = (userData) && userData.file_upload_dir;
const BASE_URL = (userData) && userData.base_url;
const CURR_DATE  = (userData) && userData.base_url;

export default function Uploader(props) {

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const [uploading, setUploadedStatus] = useState(false);

  useEffect(()=>{
  
  }, [])

  const getUploadUniqueKey =() => {
    try {
        return ((Math.floor((Math.random() * 10000) + 1)) + "" + ((new Date()).getTime()) + "" + (Math.floor((Math.random() * 10000) + 1)));
    } catch (e) {
      // console.log('getUploadUniqueKey', e.message);
    }
  }

  return (
    <section id="drop-zone" className="bg-gray-200 w-full md:w-[250px] min-h-[60px] p-4 overflow-hidden">
      <div  id="browse_button" className='flex items-center cursor-pointer'>
        <span  className='cursor-pointer hover:text-primary'>Drop files here or add files to share.</span>
        <div className='ml-auto'>
            <MdCloudUpload className='text-primary text-4xl min-w-[40px]' />
            {
                  !errorMessage && uploading && uploadedPercent > 0  &&
                  <span className="flex items-center justify-center text-primary transition-all duration-300 text-sm">
                    {uploadedPercent}%
                </span>
              }
        </div>
       
      </div>

      {
          !errorMessage && uploading && uploadedPercent > 0  &&
          <a className="flex items-center transition-all duration-300 text-sm text-primary mt-2">
          <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  />  Uploading...
          </a>
      }
     
      <Plupload
        id={'amber'}
        drop_element={'drop-zone'}
        browse_button={'browse_button'}
        runtimes="html5"
        multipart
        chunk_size="2mb"
        showCalculating={false}
        massanger={null}
        multipart_params={{
          is_token_base: 1,
          fileType: 'file',
          dir: UPLOAD_DIR,
          relativePath: "/" + USER_EMAIL + "/" + dayjs().format('YYYY-MM-DD') + "/"
        }}
        filters={{
          max_file_size : '10000mb'
        }}
        headers={{
          'Authorization': `Bearer ${ASSCESS_TOKEN}`
        }}
        init={{
          PostInit: function(uploader) {
          //  console.log(uploader, 'onPostInit');
            uploader.settings.multipart_params.lastfile = 0;
          //  console.log(uploader.files.length);
          //  if (uploader.files.length > 0) {

                var multipart_params = uploader.settings.multipart_params;
                multipart_params.uuk = getUploadUniqueKey();

                uploader.setOption('multipart_params', multipart_params);
               // uploader.start();
          //  }
          },
          FilesAdded: function(up, files) {
           // console.log(up, 'onFilesAdded');
            up.start();
          },
          BeforeUpload: function (up, file) {

            setErrorMessage(null);
            setSuccessMessage(null);
            setUploadedStatus(true);
          
           // console.log(up, 'onBeforeUpload');


            let t = up.total;
            let failed = t.failed;
            let success = t.uploaded;
            let sent = failed + success;
            let total = up.files.length;

            up.settings.multipart_params.relate = 1;

          //  console.log(total, sent);
         
            if ((total - sent) == 1) {
              up.settings.multipart_params.lastfile = 1;
            } else {
              up.settings.multipart_params.lastfile = 0;
            }


          },
          UploadProgress: function(up, file) {
            setUploadedPercent(up.total.percent)
          //  console.log(up.total.percent, status);
          },
          ChunkUploaded: function (uploader, file, status) {
           // console.log(uploader, 'ChunkUploaded');
            try {
                if (status && status.response) {
                    var response = JSON.parse(status.response);
                    if (response) {
                        if (response.success !== true) {
                            if (response.message) {
                                uploader.trigger('Error', {
                                    code: -104,
                                    message: response.message,
                                    details: response.message,
                                    file: file
                                });
                                setErrorMessage(response.message);
                            }
                            if (response.status && (response.status === 'login_error')) {
                              uploader.stop();
                              uploader.splice();
                              uploader.refresh();
                            }

                        }
                    }
                } else {
                    uploader.trigger('Error', {
                        code: -1004,
                        message: 'Server Not Responding',
                        details: 'Server Not Responding',
                        file: file
                    });

                    setErrorMessage('Server Not Responding');
                }
                uploader.trigger('updateProgress', file);
            } catch (e) {
               // console.log('ChunkUploaded', e.message);
            }
          },
          FileUploaded: function (up, file, status) {


            if (up.files.length == (up.total.uploaded + up.total.failed)) {
              setSuccessMessage('Upload completed.');
              setUploadedPercent(0);

              setTimeout(()=>{
                setSuccessMessage('');
              }, 3000);
            }

            if (status && status.response) {
              const response = JSON.parse(status.response);

             // console.log(up);

              if (response) {
                  if (response.success === true) {
                   
                  } else {

                      if (response.message) {
                        setErrorMessage(response.message);
                      } 
                      
                  }


              }
          }
          },
          UploadComplete: function(up, files) {
            // Called when all files are either uploaded or failed
            setUploadedStatus(false);
        },
          Error: function (up, err) {
           // console.log(up, 'Error1');

            const response = JSON.parse(err.response);
            if (response.message) {
              setErrorMessage(response.message);
            } 

            if( response.status === 'login_error'){
              up.stop();
              up.splice();
              up.refresh();
            }
            //console.log(response);
          }
        }}
        url={ BASE_URL + api.bulkFileUpload}
      />



      {
          errorMessage &&
          <span className="flex items-center text-red-500 transition-all duration-300 text-sm mt-2">
            {errorMessage}
        </span>
      }
      {
          successMessage &&
          <span className="flex items-center text-primary transition-all duration-300 text-sm mt-2">
            {successMessage}
        </span>
      }

   </section>
  
   
  );
}

