
import React, { Component} from "react";
import { useParams, useNavigate, useLocation, } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import FeaturedRecipeSlider from "./../compornents/FeaturedRecipeSlider";
import { Link  } from "react-router-dom";
import { api } from "../Settings";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Loader from "./../compornents/Loader";
import ApiError from "./../compornents/ApiError";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const dayjs = require('dayjs');


function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigation={useNavigate()} location={useLocation()} />;
}
  

class DetailPage extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: [],
            isLoading: false,
            isError: false
        }
        
        this.renderData = this.renderData.bind(this);
 
    }

    componentDidMount () {
        const { slug } = this.props.params;
        this.getData(slug);       
    }


    componentWillReceiveProps(nextProps) {
        if(this.props.params.slug !== nextProps.params.slug) {
            this.getData(nextProps.params.slug);
        }
    }


    getData(id){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;
        const that = this;
        that.setState({  isLoading: true, isError: false })
     
        axios.get(api.getRecipeDetails,  { params: { 'ii[]': id } }).then(response => {
          this.setState({
            data: response.data
          })

          that.setState({ isLoading: false })

        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }

            

            that.setState({ isLoading: false, isError: true })
        });

    }

    renderData(){
        const { data } = this.state;
        return (
            data && 
            <div className="item-content mb-8 text-sm">
                <div className='my-6 flex items-start text-sm text-primary'>
                    <Link to="/">Home</Link><span className='mx-1'>/</span>
                    <Link to="/recipe">Recipe</Link><span className='mx-1'>/</span>
                    <span>{data.name}</span>
                </div>
                <h1 className="md:text-3xl font-semibold mt-4 lg:mt-8 mb-4 lg:mb-8">{data.name}</h1>
                <div className="mb-8 md:mb-12">
                    {
                        (data.other_images && data.other_images.length > 0 || data.cover_image ) &&
                        <div className="slider relative pb-16">
                            <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView={"auto"}
                            navigation={{
                                nextEl: ".image-swiper-button-next",
                                prevEl: ".image-swiper-button-prev",
                                disabledClass: "opacity-50 cursor-default"
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}>
                                    {
                                        data.cover_image &&
                                        <SwiperSlide className="w-auto swiper-slide-auto">

                                                <figure>
                                                    <a >
                                                        <img src={data.cover_image} className="w-auto h-[150px]  md:h-[400px] 2xl:h-[600px]" />
                                                    </a>
                                                </figure>
                                            
                                        </SwiperSlide>
                                    }
                                    {
                                        data.other_images &&
                                        data.other_images.map((item, index) => {
                                            return (
                                                <SwiperSlide key={index} className="w-auto swiper-slide-auto">

                                                    <figure>
                                                        <a >
                                                            <img src={item.fileUrl} className="w-auto h-[150px]  md:h-[400px] 2xl:h-[600px]" />
                                                        </a>
                                                    </figure>
                                                
                                                </SwiperSlide>
                                            );
                                        })
                                    }   
                            </Swiper>
                            <div className='flex gap-2 absolute right-0 bottom-0 z-10'>
                                <span className='image-swiper-button-prev w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronLeft /></span>
                                <span className='image-swiper-button-next  w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronRight /></span>
                            </div>
                        </div>
                    }
                </div>

                {
                    data.chefs && data.chefs.length > 0 &&
                    <div className='text-body font-medium flex items-start mb-1'>
                        <span className="font-semibold mr-1">Chef Name:</span>
                        <span className='text-body/50'>
                        {
                        data.chefs.map((obj, index) => {
                            return(
                            <span className='inline-block'>
                                <span>{obj.label}</span>
                                {
                                index !== (data.chefs.length - 1 ) &&
                                <span className='mr-0.5'>,</span>
                                }
                            </span>
                            )}
                        )
                        }
                        </span>
                    </div>
                }
                {
                    data.recipe_sub_category && data.recipe_sub_category.length > 0 &&
                    <div className='text-body font-medium flex items-start mb-1'>
                        <span className="font-semibold mr-1">Sub Category Name:</span>
                        <span className='text-body/50'>
                        {
                        data.recipe_sub_category.map((obj, index) => {
                            return(
                            <span className='inline-block'>
                                <span>{obj.label}</span>
                                {
                                index !== (data.recipe_sub_category.length - 1 ) &&
                                <span className='mr-0.5'>,</span>
                                }
                            </span>
                            )}
                        )
                        }
                        </span>
                    </div>
                }
                {
                    data.recipe_source && data.recipe_source.length > 0 &&
                    <div className='text-body font-medium flex items-start mb-1'>
                        <span className="font-semibold mr-1">Recipe Source Name:</span>
                        <span className='text-body/50'>
                        {
                        data.recipe_source.map((obj, index) => {
                            return(
                            <span className='inline-block'>
                                <span>{obj.label}</span>
                                {
                                index !== (data.recipe_source.length - 1 ) &&
                                <span className='mr-0.5'>,</span>
                                }
                            </span>
                            )}
                        )
                        }
                        </span>
                    </div>
                }
                {
                    data.festivals && data.festivals.length > 0 &&
                    <div className='text-body font-medium flex items-start mb-1'>
                        <span className="font-semibold mr-1">Festivity Names:</span>
                        <span className='text-body/50'>
                        {
                        data.festivals.map((obj, index) => {
                            return(
                            <span className='inline-block'>
                                <span>{obj.label}</span>
                                {
                                index !== (data.festivals.length - 1 ) &&
                                <span className='mr-0.5'>,</span>
                                }
                            </span>
                            )}
                        )
                        }
                        </span>
                    </div>
                }
                {
                    data.teas && data.teas.length > 0 &&
                    <div className='text-body font-medium mt-12 my-4'>
                        <span className="font-semibold text-2xl block mb-4">Used Teas</span>
                        <div className='grid grid-cols-2 md:grid-cols-6 gap-4'>
                        {
                        data.teas.map((obj, index) => {
                            return(
                            <div className="">
                                <div className="relative rounded-lg image-box w-full h-0 pb-full bg-gray-500 bg-no-repeat bg-cover bg-center"  style={{ backgroundImage: `url("${obj.cover_image}")` }}></div>
                                <span className="block text-lg font-medium my-2 truncate">{obj.label}</span>
                                {/* { obj.url && 
                                    <div className='w-full self-end '>
                                        <a href={obj.url} target="_blank" className="inline-block btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-3 px-6 rounded-md text-sm hover:text-white">
                                          Book now
                                        </a>
                                    </div>
                                  } */}
                            </div>
                            )}
                        )
                        }
                        </div>
                    </div>
                }
                {
                    data.ingredients  &&
                    <div className='text-body font-medium mt-12 my-4'>
                        <span className="font-semibold text-xl block mb-4">Ingredients</span>
                        <div className="mt-8" dangerouslySetInnerHTML={{__html : data.ingredients}}></div>
                    </div>
                }
                {
                    data.garnish  &&
                    <div className='text-body font-medium my-8'>
                        <span className="font-semibold text-xl block mb-4">Garnish</span>
                        <div className="mt-8" dangerouslySetInnerHTML={{__html : data.garnish}}></div>
                    </div>
                }
                {
                    data.serves_or_makes  &&
                    <div className='text-body font-medium my-8'>
                        <span className="font-semibold text-xl block mb-4">Methods and Directions</span>
                        <div className="mt-8" dangerouslySetInnerHTML={{__html : data.serves_or_makes}}></div>
                    </div>
                }
                {
                    data.recipe_items  && data.recipe_items.length > 0  &&
                    data.recipe_items.map((recipe, index) => {
                        return(
                        <>
                            {
                                recipe.name &&
                                <span className="font-semibold text-2xl block mb-4">{recipe.name}</span>
                            }
                            {
                                recipe.ingredients &&
                                <div className='text-body font-medium mt-12 my-4'>
                                    <span className="font-semibold text-xl block mb-4">Ingredients</span>
                                    <div className="mt-8" dangerouslySetInnerHTML={{__html : recipe.ingredients}}></div>
                                </div>
                            }
                            {
                                 recipe.method &&
                                <div className='text-body font-medium my-8'>
                                    <span className="font-semibold text-xl block mb-4">Methods and Directions</span>
                                    <div className="mt-8" dangerouslySetInnerHTML={{__html : recipe.method}}></div>
                                </div>
                            }
                        </>
                       
                    )})
                }



                <div className="mt-8" dangerouslySetInnerHTML={{__html : data.long_description}}></div>
            </div>
        )
    }


  render(){
    const { isLoading, isError } = this.state;
    return (
        <HelmetProvider>
            <div>
            <Helmet>
            <title>DPN - Recipe Details</title>
            </Helmet>
            <Header/>
            {
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8  relative z-0">
                    <div className="lg:w-3/4">
                        {isLoading ? <Loader /> : this.renderData()}
                        {isError && <ApiError />}
                        <FeaturedRecipeSlider />
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
            }
            <Footer />
            </div>
        </HelmetProvider>
    )
  }

}

export default withParams(DetailPage);;


