import { useEffect, useState } from "react";
import axios from 'axios';
import { api } from "./Settings";

const userData =  JSON.parse(localStorage.getItem("_userData"));
let userId = userData && userData.details.user_id ? `${userData.details.user_id}` : ''; 

export default function Piwik() {

    const [settings, setSettings] = useState(null);

    useEffect(() => {

        axios.get(api.getSettings).then(response => {
            setSettings(response.data);
        })

    }, []);

    useEffect(() => {

        if(settings?.dammore?.piwik_analytic){

            var _paq = window._paq = window._paq || [];
        
            if(userId){
                _paq.push(['setUserId', userId]);
            }

            _paq.push(['setCustomVariable', '1', 'module', 'module', 'page']);
            _paq.push(['setCustomVariable', '2', 'event', 'visit', 'page']);

            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);

            var url = settings.dammore.piwik_analytic.url;
            _paq.push(['setTrackerUrl', url + 'piwik.php']);
            _paq.push(['setSiteId', settings.dammore.piwik_analytic.site_id]);
        
            var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
            g.type = 'text/javascript';
            g.async = true;
            g.defer = true;
            g.src = url + 'piwik.js';
            s.parentNode.insertBefore(g, s);
        }

    }, [settings]);
 
}