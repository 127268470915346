import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {  BsFillBellFill } from "react-icons/bs";
import { FiEdit } from 'react-icons/fi';

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

class TextBanner extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: props.data
        }
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
  render() {
    const { data } = this.state;
    return (
        <>
        { data &&
        <div  className={`flex items-center text-banner relative p-4 2xl:px-16 w-full mb-8 md:mb-16 border text-body bg-primary/10`} >
            <div className="user-icon min-w-[50px] w-[50px] h-[50px] rounded-full bg-primary mr-4 bg-no-repeat bg-cover bg-center text-white justify-center flex items-center text-xl"><BsFillBellFill/></div>
            <div className=''>
              <h2 className="text-xl font-semibold">{data.title}</h2>
              <div className='md:flex md:items-center'>
                  <div className="text-sm mr-4" dangerouslySetInnerHTML={{__html : data.description}}></div>
                  {
                    data.btnAction &&
                    <a href={data.btnAction} target="_blank" className="my-3 md:my-0 inline-block btn whitespace-nowrap text-primary border border-primary hover:bg-primary transition-all duration-300 py-2 px-5 rounded-md text-sm hover:text-white">
                        {data.btnLabel}
                    </a>
                  }
              </div>
            </div>
            {
            EDIT_ACCESS && data.editLink &&
            <a href={data.editLink.replace("ITEM_ID", data.hash).replace("ITEM_ID", data.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
          }
          
        </div>
        }
        </>
    );
  }
}

export default TextBanner;