import React, { Component, createRef } from 'react';
import Select, { components } from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { RiFoldersFill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import { TbFiles } from "react-icons/tb";
import { FaRegFolder } from "react-icons/fa";
import { BsCollection, BsTags } from "react-icons/bs";
import { MdOutlineSort , MdOutlineDateRange, MdPhotoSizeSelectLarge,MdArrowDropDown, MdArrowRight, MdOutlineClose } from "react-icons/md";
import { Link  } from "react-router-dom";
import AssetsMenu from './AssetsMenu';
//import { debounce } from 'lodash';
import { api } from "../Settings";

const { Option } = components;

const filters = [

  {
      label: "Browse",
      key: "browse",
      icon: <RiFoldersFill />,
      types: ["asset", "collection"]
  },
  {
      label: "Tags",
      key: "tags",
      icon: <BsTags />,
      types: ["asset"]
  },
  {
      label: "Type",
      key: "type",
      icon:  <TbFiles />,
      types: ["asset", "collection"]
  },
  {
      label: "Date",
      key: "date",
      icon:  <MdOutlineDateRange />,
      types: ["asset"]
  },
  {
      label: "Size",
      key: "size",
      icon:  <MdPhotoSizeSelectLarge />,
      types: ["asset"]
  },
  {
      label: "Sort",
      key: "sort",
      icon:  <MdOutlineSort />,
      types: ["asset", "collection"]
  }
];

//const selectRef = useRef();

const CustomOption = props => (
  <Option {...props}>
    <div className='flex items-center'>
      <BsTags className='text-primary mr-1 min-w-[24px]' />
      <span className='text-sm'>{props.data.label}</span>
    </div>
  </Option>
);

const Control = ({ children, ...props }) => {
  // @ts-ignore
 
  const style = { cursor: 'pointer' };

  return (
    <components.Control {...props}>
      <span >
      <AiOutlineSearch className='text-xl text-gray-400 ml-2' />
      </span>
      {children}
    </components.Control>
  );
};

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}


let timer;
class AssetsFilter extends Component {

    constructor (props) {
        super(props)
        this.selectRef  = createRef();
        this.wrapperRef  = createRef();

        this.state = {
          filterOpt: {},
          tags: [],
          directory: [],
          allDirectory: [],
          selected:{
            dir: {},
            type: {},
            date: {},
            size: {},
            tags:{},
            sort: {},
            q:{},
          },
          activeFilter: '',
          activeTags:'',
          active: false,
          dirSreachParms:'',
          searchparams: '',
          showMenu: false,
          clearSearch: false,
          searchText: null
        };
        this.showMenu = props.showMenu;
        this.mode = props.mode;
        this.renderChildren = this.renderChildren.bind(this);
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.toggleDirTree = this.toggleDirTree.bind(this);
        this.seletFilterItem = this.seletFilterItem.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.clearSelectedItem = this.clearSelectedItem.bind(this);
        this.selectTags = this.selectTags.bind(this);
        this.updateSelection = props.updateSelection.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.filterByParam = this.filterByParam.bind(this);
        this.findNested = this.findNested.bind(this);
    }

    

    componentDidMount () {

      const { state } = this.props.location;
      const { val } = state || {};
      const dataPath = ( this.mode === 'collection') ? api.getCollectionAssetFilters : api.getAssetFilters;

      //this.setState({ searchText: val });

      if(val){
        this.setState({ searchText: val },() => {
          this.filterByParam();
        });
      }


      axios.get(dataPath).then(response => {
        // console.log( response.data);
         this.setState({
           filterOpt: response.data
         })
       });

       axios.get(api.getAssetTags).then(response => {
         //console.log( response.data.data);
         this.setState({
           tags: response.data.data
         })
       });

       const treeSource = (this.mode && this.mode === "collection") ? api.getCollectionDirectoryTree : api.getAssetDirectoryTree;

       axios.get(treeSource).then(response => {
         //console.log( response.data.data);
         this.setState({
           directory: response.data.data,
           allDirectory: response.data.data,
         })
       });

       document.addEventListener("mousedown", this.handleClickOutside);


      // console.log(val);

     /*   if(val){
           this.setState({ searchText: val });
           this.setState({ params: { q: val }, loadedCount: 0  },() => {
               this.getData(0, true)
           });
       }else{
           this.getData(0, true);
           this.setState({ searchText: null });
       } */
    }

    componentWillReceiveProps(nextProps) {

      const { state } = nextProps.location;
      const { val } = state || {};

      if( val && val !== this.state.searchText ) {
      
        if(val){
            //console.log('fire');
            this.setState({ searchText: val },() => {
              this.filterByParam();
            });
        }
      }

    }

    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside(event) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        this.setState({activeFilter: ""});
      }
    }

    handleChange(event) {
        const target = event.target;
        const val = (target.value) ? target.value : "";
      // const value = target.type === 'checkbox' ? target.checked : val;
        const name = target.name;

        clearTimeout(timer);
        timer = setTimeout(() => {

          if(val && val !== ""){
            const key = (this.mode === "collection") ?  "name" : "text";
            const result = this.findNested(this.state.directory, key,  (k, v) => v.toLowerCase().startsWith(val.toLowerCase()) );

            console.log(result);
            this.setState({ directory: result });

          }else{
            this.setState({ directory: this.state.allDirectory });
          }

        }, 250);

        this.setState({ [name]: val });
      
    }

    findNested(object, key, predicate) {

      let ret = [];
      if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
          ret = [...ret, object];
      }
      if (Object.keys(object).length) {
          for (let i = 0; i < Object.keys(object).length; i++) {
              let value = object[Object.keys(object)[i]];
              if (typeof value === "object" && value != null) {
                  let o = this.findNested(object[Object.keys(object)[i]], key, predicate);
                  if (o != null && o instanceof Array) {
                      ret = [...ret, ...o];
                  }
              }
          }
      }
      return ret;

    }
  

    toggleSubMenu(e) {
        let activeKey = e.currentTarget.getAttribute("data-key");
        const { activeFilter } = this.state;

        if(activeKey == activeFilter ){
          this.setState({activeFilter: ""});
        }else{
          this.setState({activeFilter: activeKey});
        }
      /*   if(activeKey === 'tags'){
          console.log("focuse menu");
          console.log(this.selectRef.current);
          
                  if (this.selectRef.current) {
                    this.selectRef.current.focus();
                  }
        } */

    }

    seletFilterItem(e) {

        let fType = e.currentTarget.getAttribute("data-type");
        let fVal = e.currentTarget.getAttribute("data-id");
        let fLabel = e.currentTarget.getAttribute("data-label");

        let selectedFilters =  this.state.selected;

        let obj = {
          code: fVal,
          label: fLabel,
          type: fType
        };
       
      
        switch (fType) {
          case "browse":
            this.setState({ selected: { ...this.state.selected, dir: obj} });
            selectedFilters.dir = obj;
            break;
        
          case "date":
            this.setState({ selected: { ...this.state.selected, date: obj} });
            selectedFilters.date = obj;
            break;

          case "type":
            this.setState({ selected: { ...this.state.selected, type: obj} });
            selectedFilters.type = obj;
            break;
        
          case "size":
            this.setState({ selected: { ...this.state.selected, size: obj} });
            selectedFilters.size = obj;
            break;

          case "sort":
              this.setState({ selected: { ...this.state.selected, sort: obj} });
              selectedFilters.sort = obj;
              break;
        
          default:
            break;
        }

        this.updateSelection(selectedFilters);

        this.setState({
          activeFilter: ""
        })
    }

    filterByParam(){

      let selectedFilters =  this.state.selected;
      selectedFilters.q = {
        label: this.state.searchText,
        type: 'query'
      };
      
      this.updateSelection(selectedFilters);
      //const { navigation } = this.props;
      //navigation(".");

    }

    selectTags(selectedTags) {

      let selectedFilters =  this.state.selected;
    
      let obj = {
        type: 'tags',
        items: selectedTags
      };
    
      this.setState({ selected: { ...this.state.selected, tags: obj} });
      selectedFilters.tags = obj;
      this.updateSelection(selectedFilters);

      this.setState({ activeFilter: ""})
    
    }

    clearSelectedItem(e){
      
      let fType = e.currentTarget.getAttribute("data-type");
      let fVal = e.currentTarget.getAttribute("data-id");

      let selectedFilters =  this.state.selected;

      switch (fType) {
        case "browse":
          this.setState({ selected: { ...this.state.selected, dir: {}} });
          selectedFilters.dir = {};
          break;
      
        case "date":
          this.setState({ selected: { ...this.state.selected, date: {}} });
          selectedFilters.date = {};
          break;

        case "type":
          this.setState({ selected: { ...this.state.selected, type: {}} });
          selectedFilters.type = {};
          break;
      
        case "size":
          this.setState({ selected: { ...this.state.selected, size: {}} });
          selectedFilters.size = {};
          break;

        case "sort":
          this.setState({ selected: { ...this.state.selected, sort: {}} });
          selectedFilters.sort = {};
          break;

        case "query":

          this.setState({ searchText: null });
          selectedFilters.q = {};

          const { navigation } = this.props;
          navigation(".", { state: {} });
          
          break;

        case "tags": 

          selectedFilters.tags.items.map((tag, i) => {
              if(tag.value === fVal){
                selectedFilters.tags.items.splice(i, 1);
              }
          });

          console.log(selectedFilters.tags.items);
          //this.selectRef.current.setValue([]);
          //this.selectRef.current.setValue(selectedFilters.tags.items);

         
      
         // this.setState({ selected: { ...this.state.selected, size: {}} });
          //selectedFilters.size = {};
          break;
      
        default:
          break;
      }
      
      this.updateSelection(selectedFilters);

    }

    clearSelection(){
      if( this.selectRef && this.selectRef.current){
        this.selectRef.current.clearValue();
      }

      if(this.state.searchText){
        this.setState({ searchText: null });
        const { navigation } = this.props;
        navigation(".", { state: {} });
      }


      this.setState({ selected:{
        dir: {},
        type: {},
        date: {},
        size: {},
        tags: {},
        sort: {},
        q: {}
      }});


      const nodes = document.querySelectorAll('.tree-node');
      for (const node of nodes) {
        node.classList.remove('text-primary');
        for (const child of node.children) {
          child.classList.remove("text-primary");
        }

      }


    
      this.updateSelection(null);
    }

    toggleDirTree(e) {
        console.log(e);
        let item = e.currentTarget.parentElement.parentElement;
        let ul = e.currentTarget.parentElement.nextSibling;
        e.currentTarget.classList.toggle("rotate-90");
        ul.classList.toggle("hidden");
    }

    renderChildren = (v) => {

      const key = (this.mode === "collection") ?  v.name : v.text;
      return (
        
        key && key !== "My Drive" &&
          <li key={v.id}>
            
            <span className='flex items-center relative mb-2 pl-5'>
                {
                  v.children &&
                  <MdArrowRight onClick={this.toggleDirTree} className={`absolute left-0 text-lg  cursor-pointer text-body/50 transition-all duration-300  ${v.id === 1 ? "rotate-180" : ""}`} />
                }
                {
                  this.mode === "collection" &&
                  <Link to={`/collections/${v.id}`}  data-id={v.id}  data-label={key} data-type="browse" className='flex items-center cursor-pointer'>
                    <FaRegFolder className='mr-1.5 text-body/50 w-4 h-4 min-w-[16px]'/> 
                    <span className="text-sm" dangerouslySetInnerHTML={{__html : key}}></span>
                  </Link>
                }
                {
                  this.mode !== "collection" &&
                  <span data-id={v.id}  data-label={key} data-type="browse"  onClick={this.seletFilterItem} className='flex items-center cursor-pointer'>
                    <FaRegFolder className='mr-1.5 text-body/50 w-4 h-4 min-w-[16px]'/> 
                    <span className="text-sm" dangerouslySetInnerHTML={{__html : key}}></span>
                  </span>
                }
            </span>
            {v.children &&
              <ul className="pl-4 hidden">
              {
                v.children.map((v2) => {
                  return this.renderChildren(v2);
                })
              }
              </ul>  
            }
          </li>
        
      );
    };

    render() {
      const that = this;
      const { filterOpt, activeFilter, tags, directory, selected, searchText } = this.state;
      return (
        <div className='filter-menu border-b-2 pb-3 mb-4 md:mb-8 z-[9] relative '>
          <div className={`opts flex  items-center mb-4 text-body ${  this.showMenu ? 'container  mx-auto px-8' : ''}`}> 
            {
              this.showMenu === true &&
              <AssetsMenu /> 
            }
            <div ref={this.wrapperRef} className='filters flex flex-wrap items-center'>

                {filters.map((item, index) => {

                
                  return (
                    item.types.indexOf(this.mode) > -1 &&
                  <div key={index} className={`relative ${item.key === 'browse' ? "lg:hidden" : ""}`}>
                      <a  onClick={this.toggleSubMenu} data-key={item.key} className={ `px-2 lg:px-4 group cursor-pointe transition-all duration-300 border-b-4 py-3 border-transparent cursor-pointer block ${item.key === activeFilter ? "text-primary" : "text-body "}` }>
                      <div className="flex items-center">
                          <div className="menu-icon text-xl opacity-50">
                              {item.icon}
                          </div>
                          <div className="menu-text text-sm ml-1.5 ">
                              {item.label}
                          </div>
                          <MdArrowDropDown className={`text-lg text-body/50 transition-all duration-300  ${item.key === activeFilter ? "rotate-180" : ""}`} />
                      </div>
                      </a>
                      {
                          item.types.indexOf(this.mode) > -1  && item.key == 'tags' &&
                          <div  className={`filter-popup bg-white absolute z-10 w-[330px] shadow-xl rounded-lg px-3   ${'tags' === activeFilter ? "block" : "hidden"}`}>
                          <Select
                              ref={this.selectRef}
                              menuIsOpen={true}
                              defaultMenuIsOpen={true}
                              openMenuOnFocus={true}
                              hideSelectedOptions={false}
                              value={this.state.selected.tags.items}
                              maxMenuHeight={200}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              closeMenuOnSelect={false}
                              placeholder='Search...'
                              components={{ Option: CustomOption, Control  }}
                              isMulti
                              options={tags}
                              onChange={this.selectTags}
                          />
                      </div>
                      }
                      {
                          item.key == 'browse' &&
                          <div className={`filter-popup bg-white absolute z-10 w-[320px] shadow-xl rounded-lg px-3   ${'browse' === activeFilter ? "block" : "hidden"}`}>
                              <div className="search flex items-center relative border rounded-lg mb-3">
                              <a className="search-icon text-xl text-gray-400 ml-2"><AiOutlineSearch /></a>
                              <input
                                  className="search-input bg-transparent text-sm p-3 w-80 h-10 pl-2 outline-none focus:outline-none overflow-hidden truncate"
                                  placeholder="Search..."
                                  name="searchparams" 
                                  onChange = {this.handleChange}
                                  value={this.state.searchparams || ''} 
                              />
                              </div>
                          <ul className='max-h-[200px] overflow-auto'>
                          {
                          directory && directory.length > 0 &&
                          directory.map((v) => {
                              return that.renderChildren(v);
                              })}
                          </ul>
                      </div>
                      }
                      {
                       item.types.indexOf(this.mode) > -1  &&   item.key == 'size' &&
                      <div className={`filter-popup bg-white absolute right-0 z-10 shadow-2xl rounded-lg px-3 ${'size' === activeFilter ? "block" : "hidden"}`}>
                          {
                          filterOpt.size &&
                          filterOpt.size.map((opt, index) => {
                              return (
                                  <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                  {opt.label}
                                  </span>
                              );                         
                          })
                          }
                      </div>
                      }
                      {
                       item.types.indexOf(this.mode) > -1  &&   item.key == 'date' &&
                      <div className={`filter-popup bg-white absolute left-0 z-10 shadow-2xl rounded-lg px-3 ${'date' === activeFilter ? "block" : "hidden"}`}>
                          {
                          filterOpt.date &&
                          filterOpt.date.map((opt, index) => {
                              return (
                                  <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                  {opt.label}
                                  </span>
                              );                         
                          })
                          }
                      </div>
                      }
                      {
                       item.types.indexOf(this.mode) > -1 &&  item.key == 'type' &&
                      <div className={`filter-popup bg-white absolute left-0 z-10 shadow-2xl rounded-lg px-3 ${'type' === activeFilter ? "block" : "hidden"}`}>
                          {
                          filterOpt.type &&
                          filterOpt.type.map((opt, index) => {
                              return (
                              
                                  <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                  {opt.label}
                                  </span>
                              );                         
                          })
                          }
                      </div>
                      }
                      {
                          item.types.indexOf(this.mode) > -1  &&  item.key === 'sort' &&
                          <div className={`filter-popup bg-white absolute left-0 z-10 shadow-2xl rounded-lg px-3 ${'sort' === activeFilter ? "block" : "hidden"}`}>
                              {
                              filterOpt.sort &&
                              filterOpt.sort.map((opt, index) => {
                                  return (
                                      <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                      {opt.label}
                                      </span>
                                  );                         
                              })
                              }
                          </div>
                      }

      
                  </div>
                  );
                })}        
            </div>
          </div> 
            {
             (Object.keys(selected.tags).length > 0 ||  Object.keys(selected.dir).length > 0 ||  Object.keys(selected.type).length > 0 ||  Object.keys(selected.date).length > 0  ||  Object.keys(selected.size).length > 0  || Object.keys(selected.q).length > 0 ||  Object.keys(selected.sort).length > 0  )  &&
             <div className='filtered-opt mt-4 md:mt-8 flex items-center flex-wrap container mx-auto'>
              <span className='mr-4 text-sm md:text-base'>Resulats showing for:</span>
              {
                Object.keys(selected).map((item, index) => {
              
                  return (
                    <div className="flex items-center" key={index}>
                      <>
                      {
                        selected[item].type !== 'tags' && Object.keys(selected[item]).length > 0 && 
                        <span key={index} className="bg-ash rounded-lg py-1 md:py-2 px-1.5 md:px-4  my-1 md:my-0 md:mb-1.5 mx-1.5 text-xs md:text-base font-medium flex items-center" >
                          <span dangerouslySetInnerHTML={{__html : selected[item].label}}></span>
                          <span  onClick={this.clearSelectedItem} data-type={selected[item].type} data-id={selected[item].code} className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                        </span>
                      }
                      </>
                      <>
                      {
                        selected[item].type === 'tags'  && 
                        selected[item].items.map((tag, index) => {
                          return (
                          <span key={index} className="bg-ash rounded-lg py-1 md:py-2 px-1.5 md:px-4  my-1 md:my-0 md:mb-1.5 mx-1.5 text-xs md:text-base font-medium flex items-center" >
                            <span>{tag.label}</span>
                            <span onClick={this.clearSelectedItem} data-type={selected[item].type} data-id={tag.value}  className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                          </span>
                          )
                        })
                      }
                      </>
                    </div>
                  )
                })
              }
            {/*   {
                searchText &&

                <span className="bg-ash rounded-lg py-2 px-4  mb-1.5 mx-1.5 text-base font-medium flex items-center" >
                  <span className='block'>{searchText}</span>
                  <span  onClick={this.clearSelectedItem} data-type="query" data-id={searchText} className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                </span>
              } */}
              <span  onClick={this.clearSelection}  className='ml-4 cursor-pointer hover:text-primary text-sm md:text-base'>Clear</span>
             </div>
            }
        </div>
    
    
      );
    }
}

export default withParams(AssetsFilter);
  