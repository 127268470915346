import React, { Component } from 'react';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { FiDownload, FiEdit } from "react-icons/fi";
import { BsShareFill } from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";
import { api } from "../Settings";
import Loader from "./Loader";
import ApiError from "./ApiError";
import EmptyData from "./EmptyData";
import Share from './Share';
import { InView } from 'react-intersection-observer';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";
import "./Fancybox.scss";
const qs = require('qs');
const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class CertificationList extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: [],
          selectedIds: [],
          isLoading: false,
          isMediaLoading: false,
          isError: false,
          params: {},
          hasMore: true,
          loading: false,
          loadedCount:0,
          total:0,
          pageSize: 25,
          baseUrl: null,
          downloading: false,
        }

        this.renderData = this.renderData.bind(this);
        this.getData = this.getData.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.fetch = this.fetch.bind(this);
        this.fetchMedia = this.fetchMedia.bind(this);
        this.setMediaType  = this.setMediaType.bind(this);
        this.setPreviewUrl = this.setPreviewUrl.bind(this);
        this.setThumb = this.setThumb.bind(this);
        this.handleItemClick = this.handleItemClick.bind(this);
        this.initFancy = this.initFancy.bind(this);
        this.bytesToSize = this.bytesToSize.bind(this);
        this.getAssetDetail = this.getAssetDetail.bind(this);
        this.singleDownload = this.singleDownload.bind(this);
        this.downloadMedia = this.downloadMedia.bind(this);
        this.checkFileDownload = this.checkFileDownload.bind(this);
        this.shareMedia = this.shareMedia.bind(this);
        this.toggleShare = this.toggleShare.bind(this);
    }

    componentDidMount () {

      const that = this;
      that.setState({  isLoading: true, isError: false })

      this.getData(0, true);

      this.initFancy()

    }
    
    getData(count, reset){
       
      if (this.state.loading) {
          return;
      }

      if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
          this.setState({ hasMore: false })
          return;
      }else{
          this.setState({ hasMore: true })
      }
      
      this.setState({ loading: true })

      if(count === 0 ){
          this.setState({ loadedCount: 0  })
      }

      if(reset){
          this.setState({ items: [], loadedCount: 0  }, () => { this.fetch(reset) })
      }else if(this.state.loadedCount === 0){
          this.setState({ loadedCount: 25 },() => { this.fetch(reset) })
      }else{
          this.fetch(reset) 
      }

    }
    
    fetch(reset){

      const { navigation } = this.props;
      const params = this.state.params;
      const that = this;

      if(reset){
        that.setState({ isLoading: true, isError: false })
      }

      axios.get(api.getCertificationList, { params: { ...params, st: this.state.loadedCount } }).then(response => {

          //Set total
          const tot = response.data.total ? response.data.total : 0;
          this.setState({ total: tot });
          this.setState({ baseUrl: response.data.baseUrl  });

          this.setState({ items: [ ...this.state.items, ...response.data.items ] },() => {

              this.setState({ isLoading: false, loading: false });
            

              //if(!reset){
                  const newCount = this.state.pageSize + this.state.loadedCount;
                  this.setState({ loadedCount: newCount })
             // }

          });

      })
      .catch(function (error) {

          if (error.response && error.response.status === 401 ) {
              navigation("/logout");
              navigation(0);
          }

          that.setState({ isLoading: false, isError: true , loading: false })
      })
    }


    getMedia(item, inView){
       
        if(inView && !item.loaded){
          //console.log(item, inView);
          this.fetchMedia(item);
          item.loaded = true;
        }
    }

    fetchMedia(item){

      const { navigation } = this.props;
      const that = this;
      that.setState({  isMediaLoading: true })

      axios.get(api.getCertificationDetails, { params: { dir: item.id } }).then(response => {

          item.items = response.data.items;
          that.setState({  isMediaLoading: false })

      })
      .catch(function (error) {

          if (error.response && error.response.status === 401 ) {
              navigation("/logout");
              navigation(0);
          }

          that.setState({ isMediaLoading: false, isError: true })
      })
    }

    setMediaType(param) {
      switch(param) {
          case 'image':
          case 'vector':
          return ('image');
          break;
        case 'pdf':
          return ('pdf');
          break;
        case 'audio':
          return ('html5video');
          break;
        case 'video':
          return ('html5video');
          break;
        default:
          return ('iframe');
          break;
      }
    }

    setPreviewUrl(url, type){

      let posterHeight = '960';
      if (window.innerHeight <= 240) {
          posterHeight = '240';
      } else if (window.innerHeight <= 360) {
          posterHeight = '360';
      } else if (window.innerHeight <= 480) {
          posterHeight = '480';
      } else if (window.innerHeight <= 720) {
          posterHeight = '720';
      } 

      let vt = '480p';
      if (window.innerHeight <= 240) {
          vt = '240p';
      } else if (window.innerHeight <= 360) {
          vt = '360p';
      }

      switch (type) {
          case 'image':
              return (`${url}&access_token=${ASSCESS_TOKEN}&act=resize&width=0&height=${posterHeight}`)
              break;

          case 'vector':
              return (`${url}&access_token=${ASSCESS_TOKEN}&act=resize&width=0&height=${posterHeight}`)
              break;

          case 'video':
              return (`${url}&access_token=${ASSCESS_TOKEN}&vt=${vt}`)
              break;

          default:
              return (`${url}&access_token=${ASSCESS_TOKEN}`)
              break;
      }
    }

    setThumb(url, type){

      switch (type) {
          case 'image':
          case 'powerpoint':
          case 'word':
          case 'vector':
          case 'pdf':
          case 'video':
          case 'animation':
          case 'swf':
              return (`${url}&access_token=${ASSCESS_TOKEN}&width=320&height=0`)
              break;
          default:
              return (`${url}`)
              break;
      }
    }


    handleItemClick(e) {

      let id = e.currentTarget.getAttribute("data-id");

      if(e.target.checked){
          this.setState({ selectedIds: [...this.state.selectedIds, id] }, () => {
              //this.requestDownload()
          });
      }else{
          let currIds = [...this.state.selectedIds];
          var index = currIds.indexOf(id)
          if (index !== -1) {
              currIds.splice(index, 1);
              this.setState({selectedIds: currIds},() => {
                 // this.requestDownload()
              });
          }
      }
     
    }
    

    initFancy(){

        Fancybox.bind('[data-fancybox="gallery"]', {
            click: false,
            dragToClose: false,
            touch: false,
            hideScrollbar : true,
            Toolbar: {
                autoEnable: false,
                display: [
                {
                    id: "counter",
                    position: "center",
                },
                "zoom",
                "slideshow",
                "fullscreen",
                "thumbs",
                "close",
                ],
            },
            Thumbs: {
                Carousel: {
                    Sync: {
                        friction: 0,
                    },
                    Dots: false,
                    Navigation: false,
                    center: true,
                    slidesPerPage: 1,
                    infinite: false,
                },
            },
            Carousel: {
                Panzoom: {
                    touch: false,
                },
                
                // Disable sliding animation
                friction: 0,
            },
            Image: {
                // Disable animation from/to thumbnail on start/close
                //zoom: false,
                touch: false,
                // Disable zoom on scroll event
                wheel: false,
            
                // Disable zoom on image click
                click: false
              },
            on: {
                initLayout: (fancybox) => {

                
                // Create elements
                // ===
            
                // Create left column
                const $leftCol = document.createElement("div");
                $leftCol.classList.add("fancybox__leftCol");
            
                while (fancybox.$container.firstChild) {
                    $leftCol.appendChild(fancybox.$container.firstChild);
                }
            
                // Create right column
                const $rightCol = document.createElement("div");
                $rightCol.classList.add("fancybox__rightCol");
            
                $rightCol.innerHTML = '';
            
                // Create info-box
                const $info = document.createElement("div");
                $rightCol.appendChild($info);
                fancybox.$info = $info;
            
                // Add elements to DOM
                fancybox.$container.appendChild(fancybox.$backdrop);
            
                fancybox.$container.appendChild($leftCol);
                fancybox.$container.appendChild($rightCol);
            
                fancybox.$leftCol = $leftCol;
                fancybox.$rightCol = $rightCol;
                },
                "Carousel.ready Carousel.change": (fancybox, carousel, slideIndex) => {
                // Update info-box
                // ===
            
                // Get index of the current gallery item
                slideIndex =
                    slideIndex === undefined ? carousel.options.initialPage : slideIndex;
            
                // Get link related to current item
                const $trigger = fancybox.items[slideIndex].$trigger;
            
                // Get data from `data-info` attribute
                const title = $trigger.dataset.info ? `<h3 class="text-lg font-semibold mb-4 xl:fixed xl:top-2 xl:left-2 z-20 xl:max-w-2xl xl:truncate">${$trigger.dataset.info}</h3>` : "";
                const type = $trigger.dataset.mtype ? `<span class="flex mb-2">
                                                        <span class="font-semibold">File Type:</span>
                                                        <span class="_ml-auto ml-2 text-body/50">${$trigger.dataset.mtype}</span>
                                                    </span>` : "";
                const size = $trigger.dataset.size ? `<span class="flex mb-2">
                                                        <span class="font-semibold">Size:</span>
                                                        <span class="_ml-auto ml-2 text-body/50">${this.bytesToSize($trigger.dataset.size)}</span>
                                                    </span>` : "";
                const created = $trigger.dataset.created ? `<span class="flex mb-2">
                                                                <span class="font-semibold">Created Date:</span>
                                                                <span class="_ml-auto ml-2 text-body/50">${$trigger.dataset.created}</span>
                                                            </span>` : "";
                const modified = $trigger.dataset.modified ? `<span class="flex mb-2">
                                                                <span class="font-semibold">Modified Date:</span>
                                                                <span class="_ml-auto ml-2 text-body/50">${$trigger.dataset.modified}</span>
                                                            </span>` : "";
                let mediaData = '';
                let downloadData = '';
                let tagData = '';
                let desc = '';
            
                if($trigger.dataset.tags){
            
                    const items = $trigger.dataset.tags.split(',');
                    if(items.length > 0){
            
                        tagData += `<span class="flex mb-2">
                        <span class="font-semibold">Tags:</span>
                        <span class="_ml-auto ml-4 flex flex-wrap gap-2">`;
            
                        items.map((item, index) => {
                            tagData += `<span class="flex text-xs py-1 px-2 rounded-md bg-primary/10">${item}</span>`;
                        });
            
                        tagData += `</span></span>`;
            
                    }
            
                }
            
                if($trigger.dataset.id){
                    this.getAssetDetail($trigger.dataset.id).then((itemData) => {
    
             
                        if(itemData.dataSets.description){
                            desc = `<div class="text-sm text-body/50">${itemData.dataSets.description}</div>`;
                        }
                        
                        let mediaData = '<div class="mt-8">';

            
                        downloadData += `<div class="bg-primary py-2 pr-3 pl-4 rounded-md text-white fixed top-[60px] right-[16px] xl:top-1.5 xl:right-[250px] z-20 group">
                        
                        <div class="flex items-center cursor-pointer">
                            Downloads
                            <svg class="ml-1.5 w-4 h-4 fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>   
                        </div>
                        <div class="duration-300 transition-all download-box opacity-0 invisible group-hover:visible  group-hover:opacity-100  absolute top-10 right-0 bg-primary shadow rounded-lg py-2 text-white text-right min-w-[150px]"><div class="mb-2">
                            <div class="px-3">
                                <a  data-id="${itemData.dataSets.file_id}" class="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70 media-down">
                                    <span class="uppercase pr-2">${itemData.dataSets.ext}</span>
                                    ${ itemData.dataSets.fileWidth && itemData.dataSets.fileHeight ? ` <span class='opacity-70'> (${itemData.dataSets.fileWidth} x ${itemData.dataSets.fileHeight})</span> ` : '' }
                                </a>  
                            </div>
                        </div>`;
            
            
                        if(itemData.dataSets.otherSizes && itemData.dataSets.otherSizes.length > 0 ){
                            //mediaData += `<h3 class="text-sm font-bold mb-4">Other sizes</h3><div class="flex flex-wrap gap-2">`;
            
                            downloadData += `
                            <div class="mb-2">
                                <span class="block px-3 border-t pt-2 border-white/50 text-sm">Other Sizes</span>
                                <div class="px-3">`;
            
                            itemData.dataSets.otherSizes.map((item, index) => {
            
                                downloadData += `
                                <a data-id="${item.key}" data-name="${item.name}"  class="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70 media-down">
                                <span class='opacity-70'> ${item.heading}</span> 
                                </a>`;
            
                            });
            
                          // mediaData += '</div>';
            
            
                            downloadData += `</div></div>`;
                        }
            
                        if(itemData.dataSets.otherFormats && itemData.dataSets.otherFormats.length > 0 ){
                        
            
                            downloadData += `
                            <div class="mb-2">
                                <span class="block px-3 border-t pt-2 border-white/50 text-sm ">Other Formats</span>
                                <div class="px-3">`;
            
                            itemData.dataSets.otherFormats.map((item, index) => {
            
          
                                downloadData += `
                                <a data-id="${item.key}" data-name="${item.name}" class="cursor-pointer block text-sm my-1 whitespace-nowrap hover:opacity-70 uppercase media-down">
                                <span class='opacity-70'> ${item.heading}</span> 
                                </a>`;
            
            
                            });
            
                          // mediaData += '</div>';
            
                            downloadData += `</div></div>`;
                        }
            
                        if(itemData.dataSets.relatedItems  && itemData.dataSets.relatedItems.length > 0){
                            mediaData += `<h3 class="text-sm font-bold mb-4 mt-6"> Related items</h3><div class="grid grid-cols-2 gap-4">`;
            
                            itemData.dataSets.relatedItems.map((item, index) => {
            
                                mediaData += `<a 
                                data-fancybox="gallery" 
                                data-id='${item.key}'
                                data-info='${item.name}'
                                data-type='${item.mediaType}'
                                data-size='${item.fileSize}' 
                                data-href='${item.url}&access_token=${ASSCESS_TOKEN}'
                                class="block w-full h-24 md:h-36 cursor-default" 
                                ><span class="block w-full h-full bg-primary/20 bg-no-repeat bg-contain bg-center" style="background-image:url('${item.thumbnail}&access_token=${ASSCESS_TOKEN}&is_token_base=1')" ></span></a>`;
                            });
            
                            mediaData += '</div>';
                        }
            
                        downloadData += `</div></div>`;
            
            
                        mediaData += '</div>';
                      // fancybox.$info.innerHTML += mediaData;
                        fancybox.$info.querySelector('.desc').innerHTML = desc;
                        fancybox.$info.querySelector('.mediaData').innerHTML = mediaData;
                        //fancybox.$info.querySelector('.download-area').innerHTML = downloadData;
            
                        const SeverDownload = (e) => {
                        
                            let id = e.currentTarget.getAttribute("data-id");
                            let name = e.currentTarget.getAttribute("data-name");
                            this.downloadMedia({ id: id, name: name });
    
                        }; 
            
                        document.querySelectorAll(".media-down").forEach(el=>el.addEventListener("click", SeverDownload));      
            
                    });
            
            
                    
                }
                // Update info
                fancybox.$info.innerHTML = `<div class="text-sm">
                    ${title}
                    <div class="desc mb-4"></div>
                    ${type}
                    ${size}
                    ${created}
                    ${modified}
                    ${tagData}
                    <div class="mediaData mb-4"></div>
                    <div class="download-area mb-4"></div>
                </div>`;
                },
            },
        });

        Fancybox.Plugins.Thumbs.defaults.Carousel.preload = 0;
        Fancybox.defaults.ScrollLock = false;

    }

    getAssetDetail(id){

      return new Promise((resolve, reject) => {
              
          axios.get(api.getAssetItemDetail, { params : { ii: id }}).then(response => {
              resolve(response.data);
          })
          .catch(function (error) {

              if (error.response && error.response.status === 401 ) {
                  reject(error.response);
              }
          });

      });
  
    }

    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
      if (i === 0) return `${bytes} ${sizes[i]})`
      return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
    }

    singleDownload(e){
      let id = e.currentTarget.getAttribute("data-id");
      let name = e.currentTarget.getAttribute("data-name");
      this.downloadMedia({ id: id, name: name });
    }

    downloadMedia(data){

      const path = this.state.baseUrl;
      const that = this;
      const dataObj = {};

      if(data &&  data.id){
            dataObj['fileIdArray[]'] = data.id;

            if( data.name){
                dataObj['fn['+data.id+']'] = data.name;
            }

            dataObj.is_token_base = 1;

      }else{   
            dataObj.fileIdArray = this.state.selectedIds;
            dataObj.is_token_base = 1;
      }
        
        this.setState({ downloading: true });

        axios.post(path+ '/mediaManager/media-request/prepare-download-file', qs.stringify(dataObj)).then(response => {
            if(response.data && response.data.hash){
                this.checkFileDownload(path, response.data.hash)
            }else{
                that.setState({ downloading: false });
            }
        })
        .catch(function (error) {
            that.setState({ downloading: false });
        });
    
    }

    checkFileDownload(path, id){

        let timer;
        let time = 1000;
        const that = this;

        clearInterval(timer);
        timer = setInterval(function(){

            axios.post(path+ '/mediaManager/media-download/check-file-complete-and-download',  qs.stringify({
                'file[]' : id,
                'is_token_base' : 1
              }) ).then(response => {
                //console.log(response.data);
              //  this.checkFileDownload(path)

              if (response.data.success) {
                // start sending request to check weather file is ready to download

                //console.log('download bbb')

                response.data.files.map((record, index) => {

                  // console.log('download filee', record)

                    if (record.file_complete === "1") {
                        const isZip = (that.state.selectedIds.length > 1) ? 1 : 0;
        
                        window.location.assign(path+ '/mediaManager/media/download/file/' + record.download_key + '/zipFile/' + isZip + '?access_token=' + ASSCESS_TOKEN );

                        that.setState({ downloading: false, selectedIds:[] });
                                    
                        //window.open(path+ '/mediaManager/media/download/file/' + record.download_key + '/zipFile/' + isZip + '?access_token=' + ASSCESS_TOKEN , '_blank');

                        clearInterval(timer);
      
                    }

                });

            }


            })
            .catch(function (error) {
                that.setState({ downloading: false });
            });

            time += 2000;
        }, time);
    }

    shareMedia(e){

        let id = e.currentTarget.getAttribute("data-id");
        let title = e.currentTarget.getAttribute("data-title");

        if(id){
            this.setState({ selectedIds: [id], selectedTitle: title, shareMode: true });
        }
    }

    toggleShare(state){
      this.setState({ shareMode: state, selectedIds: [] });
    }

    renderData(){
      const { selectedIds,  items, hasMore, total, isError  } = this.state;
      return (
        items &&
        <>
          {
                total === 0 && !isError &&
                <EmptyData/>
          }
          {                
          total > 0 &&
            <InfiniteScroll
                  pageStart={0}
                  loadMore={this.getData}
                  initialLoad={false}
                  hasMore={hasMore}
                  loader={<div className="loader" key={0}><Loader /></div>}
              >
                <div className="mb-8 md:mb-16">
                  {   
                      
                      items.map((section, index) => {
                          return (
                            <div  key={index} className="item mb-16 relative">
                                <InView as="div" onChange={(inView, entry) => this.getMedia(section , inView)}>


                                  <h2 className="text-xl md:text-2xl  font-bold mb-4">{section.title}</h2>
                                  <div className="text-sm" dangerouslySetInnerHTML={{__html : section.description}}></div>
                                  <div className="items mt-6 grid grid-cols-2 md:grid-cols-3  lg:grid-cols-3 2xl:grid-cols-4 gap-2 md:gap-4  lg:gap-6 xl:gap-8">
                                    {   
                                      section.items && 
                                      section.items.map(( item , index) => {

                                        return (
                                              <div key={index}>

                                                <div className={`relative border border-primary bg-white transition-all duration-300 rounded-md overflow-hidden  group ${selectedIds.indexOf(item.id) > -1 ? "_p-2 _xl:p-4"  : ""}`}>
                                                      <div className='w-full pb-[140%] h-0 bg-no-repeat bg-contain bg-center' style={{ backgroundImage: `url("${this.setThumb( item.thumb, item.type)}")` }}>

                                                          {

                                                              item.preview === "true" &&
                                                              <a  
                                                              data-fancybox="gallery" 
                                                              data-id={item.id} 
                                                              data-info={item.fileName} 
                                                              data-mtype={item.type} 
                                                              data-type={this.setMediaType(item.type)} 
                                                              data-modified={item.modified} 
                                                              data-created={item.created} 
                                                              data-size={item.size} 
                                                              data-tags={item.tags} 
                                                              href={this.setPreviewUrl( item.url, item.type)} 
                                                              className="block w-full h-full z-11 absolute"> </a>
                                                          }
                                                          {

                                                              item.preview !== "true" &&
                                                              <a 
                                                              className="block w-full h-full z-11 relative">
                                                                  <div className={`relative ${item.fileWidth && item.fileHeight ? `h-0` : 'min-h-[50px]' }`} style={ {paddingBottom: item.fileHeight && item.fileWidth && item.fileHeight / item.fileWidth * 100+'% '}}> 
                                                                      <img src={this.setThumb( item.thumb, item.type)} className="w-full h-auto" />
                                                                      <div className='absolute bottom-0 left-0 block w-full h-8 from-gray-600 bg-gradient-to-t z-10'></div>
                                                                  </div>
                                                                  <span className="relative z-10 bg-gray-600 w-full  pb-2 px-2 group-hover:from-gray-600 duration-300 transition-all text-xs font-medium text-white flex items-start">{this.renderIcon(item.type)}{item.fileName}</span>
                                                                  
                                                              </a>
                                                          }
                              

                                                          <div className={`actions flex items-center z-11 absolute top-2 w-full px-2 ${selectedIds.indexOf(item.id) > -1 ? ""  : "opacity-0 group-hover:opacity-100 duration-300 transition-all"}`}>
                                                             {/*  <input data-id={item.id} onClick={(e) => this.handleItemClick(e)} type="checkbox" value="" className="w-4 h-4 text-primary bg-gray-100 border-gray-300 rounded focus:ring-primary  focus:ring-0"></input> */}

                                                              <span  onClick={this.singleDownload} data-id={item.id} data-name={item.title} className="cursor-pointer download btn bg-white shadow-md p-1.5 rounded-md text-sm text-primary hover:shadow-lg relative mr-1 ml-auto">
                                                                  <FiDownload />
                                                              </span>
                                                                  
                                                              <span onClick={this.shareMedia} data-title={item.title} data-id={item.id} className="cursor-pointer share group-acb btn bg-white shadow-md p-1.5 rounded-md text-sm text-primary hover:shadow-lg  relative" >
                                                                  <BsShareFill />
                                                              </span>     
                                                          </div>

                                                      </div>
                                                      
                                                    
                                                </div>
                                                <span className=' text-sm text-center block mt-2'>{item.fileName}</span>
                                              </div>
                                          );

                                        

                                      })
                                    }
                                  </div>
                                  {
                                        EDIT_ACCESS && section.editLink &&
                                        <a href={section.editLink.replace("ITEM_ID", section.id)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                                    }
                                </InView>

                          </div>
                          );
                      })
                  }
                </div>
            </InfiniteScroll>
          }
        </>

      )
    }
    
  render() {
    const { shareMode, baseUrl, isLoading, isError, selectedIds, selectedTitle, downloading } = this.state;
    return (
      <>
        {isLoading ? <Loader /> : this.renderData()}
        {isError && <ApiError />}
        {
            shareMode &&
            <Share toggleShare={this.toggleShare} type="asset" ids={selectedIds} title={selectedTitle} baseUrl={baseUrl} />
        }
        {
            downloading &&
            <a className="fixed bottom-4 right-4 btn flex items-center border border-primary bg-primary transition-all duration-300  py-1.5 md:py-3 px-3 md:px-6 rounded-md text-sm text-white cursor-pointer z-[1100] shadow-lg">
                <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  />  Preparing download
            </a>
        }
      </>
    );
  }
}

export default CertificationList;