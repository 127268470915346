import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate  } from "react-router-dom";
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import Users from "./../compornents/Users";
import Banner from "./../compornents/Banner";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import { api } from "../Settings";

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} />;
}

class People extends Component {

    constructor (props) {
        super(props)

        this.state = {
          componentData: []
        }
    }

    componentDidMount () {

        const { navigation } = this.props;
        
        axios.get(api.getPeople).then(response => {
          this.setState({
            componentData: response.data.components
          })
        })
        .catch(function (error) {

          

        /*   if (error.response && error.response.status === 401 ) {
              navigation("/logout");
              navigation(0);
          } */
      });
    }


  render(){
    const { componentData } = this.state;
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> DPN - People </title>
        </Helmet>
        <Header/>
        <div className="container min-h-screen mx-auto mt-[120px] flex px-8">
          <div className="w-3/4">
              <Banner data={componentData.Banner} />
              <Users data={componentData.Users} />
          </div>
          <div className="w-1/4 ml-8">
              <PartnerFeeds />
          </div>
        </div>
        <Footer />
        </div>
        </HelmetProvider>
    )
  }
}

export default withParams(People);
