
import React, { Component} from "react";
import { useParams, useNavigate, useLocation, } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import FeaturedNewsSlider from "./../compornents/FeaturedNewsSlider";
import { Link  } from "react-router-dom";
import { api } from "../Settings";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import Loader from "./../compornents/Loader";
import ApiError from "./../compornents/ApiError";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';



const dayjs = require('dayjs');


function withParams(Component) {
    return props => <Component {...props} params={useParams()}  navigation={useNavigate()} location={useLocation()} />;
}
  

class DetailPage extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: [],
            isLoading: false,
            isError: false
        }

        this.formatDate = this.formatDate.bind(this);
        this.renderData = this.renderData.bind(this);
    }

    componentDidMount () {
        const { slug } = this.props.params;
        this.getData(slug);
    }


    componentWillReceiveProps(nextProps) {
        if(this.props.params.slug !== nextProps.params.slug) {
            this.getData(nextProps.params.slug);
        }
    }


    getData(id){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;
        const that = this;
        that.setState({  isLoading: true, isError: false })
     
        axios.get(api.getNewsDetails,  { params: { 'ii[]': id } }).then(response => {
          this.setState({
            data: response.data
          })

          that.setState({ isLoading: false })

        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }

            

            that.setState({ isLoading: false, isError: true })
        });

    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }


    renderData(){
        const { data } = this.state;
        return (
            data && 
            <div className="item-content mb-8">
                <div className='my-6 flex items-start text-sm text-primary'>
                    <Link to="/">Home</Link><span className='mx-1'>/</span>
                    <Link to="/news">News</Link><span className='mx-1'>/</span>
                    <span>{data.name}</span>
                </div>
                <h1 className="md:text-3xl font-semibold mt-4 lg:mt-12 mb-4">{data.name}</h1>
                <span className="block  mb-4">{this.formatDate(data.news_date)}</span>
                <div className="mb-8 md:mb-20">
                    {
                        data.thumbnail && data.cover_images.length === 0 &&
                        <img src={data.thumbnail} className="w-full h-auto" />
                    }
                    {
                        data.cover_images && data.cover_images.length > 0 &&
                        <div className="slider relative pb-16">
                            <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView={"auto"}
                            navigation={{
                                nextEl: ".image-swiper-button-next",
                                prevEl: ".image-swiper-button-prev",
                                disabledClass: "opacity-50 cursor-default"
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}>
                                    {
                                        data.cover_images &&
                                        data.cover_images.map((item, index) => {
                                            return (
                                                <SwiperSlide key={index} className="w-auto swiper-slide-auto">

                                                    <figure>
                                                        <a >
                                                            <img src={item.fileUrl} className="w-auto h-[150px]  md:h-[200px] 2xl:h-[350px]" />
                                                        </a>
                                                    </figure>
                                                
                                                </SwiperSlide>
                                            );
                                        })
                                    }   
                            </Swiper>
                            <div className='flex gap-2 absolute right-0 bottom-0 z-10'>
                                <span className='image-swiper-button-prev w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronLeft /></span>
                                <span className='image-swiper-button-next  w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronRight /></span>
                            </div>
                        </div>
                    }
                </div>
                <div className="text-sm mt-8" dangerouslySetInnerHTML={{__html : data.long_description}}></div>
            </div>
        )
    }


  render(){
    const { isLoading, isError } = this.state;
    return (
        <HelmetProvider>
            <div>
            <Helmet>
            <title>DPN - News Details</title>
            </Helmet>
            <Header/>
            {
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8  relative z-0">
                    <div className="lg:w-3/4">
                        {isLoading ? <Loader /> : this.renderData()}
                        {isError && <ApiError />}
                        <FeaturedNewsSlider />
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
            }
            <Footer />
            </div>
        </HelmetProvider>
    )
  }

}

export default withParams(DetailPage);;


