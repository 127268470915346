import React, { Component, createRef } from 'react';
import Select, { components } from "react-select";
import axios from 'axios';
import { RiFoldersFill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import { TbFiles } from "react-icons/tb";
import { FaRegFolder } from "react-icons/fa";
import { BsCollection, BsTags } from "react-icons/bs";
import { MdOutlineImage , MdOutlineDateRange, MdPhotoSizeSelectLarge,MdArrowDropDown, MdArrowRight, MdOutlineClose } from "react-icons/md";
import { NavLink  } from "react-router-dom";
//import { debounce } from 'lodash';
import { api } from "../Settings";

const { Option } = components;

const navigation = [
  {
      url: "/collections",
      label: "Collections",
      key: "collections",
      icon: <BsCollection />
  },
  {
    url: "/digital-assets",
    label: "Assets",
    key: "assets",
    icon: <MdOutlineImage />
  }
];

class AssetsMenu extends Component {


    render() {
    
      return (
         <div className='navigation-wrp w-full lg:w-1/4 pr-4 xl:pr-8'>
         <div className='navigation flex items-center lg:border-r-2 -ml-4'>
                  {navigation.map((item, index) => {
                    return (
                        <NavLink key={index} to={item.url}  className={({ isActive }) =>  isActive ? 'text-primary mx-4  group border-b-4 py-3 border-body/50  transition-all duration-300 block' : 'mx-4 group cursor-pointer text-body group-hover:text-black transition-all duration-300 border-b-4 py-3 border-transparent block' }>
                          <div className="flex  items-center">
                              <div className="menu-icon text-xl opacity-50">
                                  {item.icon}
                              </div>
                              <div className="menu-text text-sm ml-1.5 ">
                                {item.label}
                              </div>
                          </div>
                        </NavLink>
                    );
                  })} 
          </div>
          </div>
             

      );
    }
  }

  export default AssetsMenu;
  
  