import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 

class Banner extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: props.data
        }
        this.styles = props.styles;
        this.boxMode = props.boxMode;
        this.gradient = props.gradient;
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }
    
  render() {
    const { data } = this.state;
    return (
        <>
        { data &&
        <div  className={`banner relative overflow-hidden  w-full z-0 bg-gray-500 bg-no-repeat bg-cover bg-center mb-4 lg:mb-12  xl:mb-16 flex items-center  ${this.styles ? this.styles : "h-auto"} ${this.boxMode ? "" : "rounded-2xl"}`} >
          {/* style={{ backgroundImage: `url("${data.image}")` } */}
            <h2 className="md:text-5xl font-semibold w-1/2 text-white px-4 lg:px-16 text-shadow-lg z-10 absolute top-1/2 transform -translate-y-1/2">{data.title}</h2>
            <img src={data.banner} className="w-full h-auto block" width="100%" height="auto" />
            {
              this.gradient &&
              <span className='absolute w-2/3 top-0 left-0 h-full z-0 from-primary bg-gradient-to-r'></span>
            }
            {
                EDIT_ACCESS && data.editLink &&
                <a href={data.editLink.replace("ITEM_ID", data.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white p-1 hover:bg-primary/70 text-sm'>
                   <FiEdit className='text-lg' title="Edit" />
                </a>
            }
        </div>
        }
        </>
    );
  }
}

export default Banner;