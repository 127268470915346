
import React, { Component} from "react";
import { useParams, useNavigate, useLocation, } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { FaRegCommentAlt, FaRegThumbsUp, FaThumbsUp } from 'react-icons/fa';
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import { Link  } from "react-router-dom";
import { api } from "../Settings";
import Loader from "./../compornents/Loader";
import ApiError from "./../compornents/ApiError";
import Editor from "./../compornents/Editor";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { MdOutlineClose } from "react-icons/md";
import { GiConsoleController } from "react-icons/gi";
const qs = require('qs');
const dayjs = require('dayjs');


const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;
const USERID = (userData) && userData.details.user_id;

//const USERID = "1";
//console.log(USERID);
 


function withParams(Component) {
    return props => <Component {...props} params={useParams()} navigation={useNavigate()} location={useLocation()} />;
}
  

class DetailPage extends Component {

    constructor (props) {
        super(props)

        this.state = {
            data: [],
            loadingData: false,
            isLoading: false,
            isError: false,
            editorMode: false,
            deleteMode: false,
            mode: "Reply",
            baseUrl: null,
            currItem: null
        }

        this.formatDate = this.formatDate.bind(this);
        this.renderData = this.renderData.bind(this);
        this.renderComment = this.renderComment.bind(this);
        this.getDiff = this.getDiff.bind(this);
        this.toggleEditor = this.toggleEditor.bind(this);
        this.addComment = this.addComment.bind(this);
        this.editComment = this.editComment.bind(this);
        this.deleteComment = this.deleteComment.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.setFilePaths = this.setFilePaths.bind(this);
        this.cancelDelete = this.cancelDelete.bind(this);
        this.acceptDelete = this.acceptDelete.bind(this);
    }

    componentDidMount () {
        const { slug } = this.props.params;
        this.getData(slug);

        /* let searchParams = new URLSearchParams(window.location.search)
        if(searchParams.has('reply')){
            this.setState({  editorMode: true });   
        } */
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.params.slug !== nextProps.params.slug) {
            this.getData(nextProps.params.slug);
        }
    }

    
    toggleEditor(item, state, reload){

        const { slug } = this.props.params;

        if(reload){
            this.getData(slug);
        }

        item.editorMode =  state;

        this.setState({ isLoading: false });
        this.setState({ mode: "Reply" });
    }


    changeStatus(item){

        let id = (item.postId) ? item.postId : item.commentId;
        let fav = item.isFavorite;
        let baseUrl = this.state.baseUrl;
        let f = (fav === "1") ? "1" : "0";
        let fCount = item.favCount ? Number(item.favCount) : 0;
        const that = this;

        this.setState({ loadingData: true });

        axios.post(api.forumChangeFavoriteStatus , qs.stringify({ fav : f , pid: id, is_token_base: 1 }) ).then(response => {

            console.log(response.data);
            if(response.data && response.data.success){
                item.isFavorite = (fav === "1") ? "0" : "1";
                item.favCount = (fav === "1") ? fCount - 1 :  fCount + 1;
            }

            this.setState({ loadingData: false });

        })
        .catch(function (error) {
          //console.log(error);
          that.setState({ loadingData: false });
        });

    }

    getDiff(param) {

        if( dayjs(param).isValid()){
            const evtDate = dayjs(param);
            const today = dayjs();
    
            let hours = today.diff(evtDate, 'hours');
            const days = Math.floor(hours / 24);
            hours = hours - (days * 24);
    
            if(days === 0){
                return ( <>{hours} hours ago</>);
            }else{
                return dayjs(param).format('MMMM DD, YYYY h:mm A');
            }
        }
    }

    setFilePaths(content){

        if(!content){ return }

        const baseUrl = this.state.baseUrl;

        content = content.replace(/="\/forum(.*?)\"/g, function (matched, str) {
            return `="${baseUrl}/forum${str}&access_token=${ASSCESS_TOKEN}"`;
        });

        return content;
    }

    getData(id){

        const { navigation } = this.props;
        const { pathname  } = this.props.location;
        const that = this;
        that.setState({  isLoading: true, isError: false })

     
        axios.get(api.getForumDetails,  { params: { 'pid': id } }).then(response => {

          this.setState({
            data: response.data
          })

          if(response.data.baseUrl && response.data.baseUrl !== ""){

              this.setState({
                baseUrl: response.data.baseUrl
              });

          }

          that.setState({ isLoading: false })
        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }

            

            that.setState({ isLoading: false, isError: true })

        });

    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }

    addComment(item){

        item.editorMode =  !item.editorMode;
        this.setState({ isLoading: false });
        this.setState({ mode: "Reply" });

    }

    editComment(item){
        console.log('ddddd', item)
        let id = (item.postId) ? item.postId : item.commentId;
        let baseUrl = this.state.baseUrl;
        const that = this;

        item.loading = true;
        this.setState({ loadingData: true });
        
        let paramsToMove = {  fid: id, is_token_base: 1 }

        // if ('title' in item){
        //     paramsToMove = { 
        //         fid: id, is_token_base: 1,
        //         forumTitle: item.title
        //     }
        // }

        axios.get( api.forumGetDiscussionContent, { params :paramsToMove } ).then(response => {

            item.editorMode =  !item.editorMode;

            if(response.data && response.data.success){
               
                if(item.post && response.data.post){
                    item.post = response.data.post;
                }else if(item.comment && response.data.post){
                    item.comment = response.data.post;
                }
            
            }

            item.loading = false;
            this.setState({ mode: "Update" });
            this.setState({ loadingData: false });
           

        })
        .catch(function (error) {
          //console.log(error);
          item.editorMode =  !item.editorMode;
          item.loading = false;
          that.setState({ mode: "Update" });
          that.setState({ loadingData: false });

        });

    }

    cancelDelete(){
        this.setState({ deleteMode: false });
    }

    deleteComment(item){
        this.setState({  deleteMode: true, currItem:item  });
    }

    acceptDelete(){

        const item = this.state.currItem;
        let id = (item.postId) ? item.postId : item.commentId;
        let baseUrl = this.state.baseUrl;
        const that = this;

        this.setState({ loadingData: true });

        axios.post( api.forumDeleteDiscussion, qs.stringify({ fid: id, is_token_base: 1 }) ).then(response => {

            if(response.data && response.data.success){
            
                const { slug } = this.props.params;
                this.getData(slug);
                this.setState({ deleteMode: false });
                
            }

            this.setState({ loadingData: false });

        })
        .catch(function (error) {
          //console.log(error);
          that.setState({ loadingData: false });
        });

    }
    
    renderComment = (item, index) => {
        return (
          <div key={index} className=" mb-4">
                  
                <div className="flex items-start mb-4">
                    <div className="user-icon min-w-[48px] w-[48px] h-[48px] rounded-full bg-gray-500 mr-3 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${item.userImage}&access_token=${ASSCESS_TOKEN}")` }}></div>
                    <div className={`w-full ${item.loading ? 'opacity-50 pointer-events-none' : ''}`}>
                        <div className="flex items-center">
                            <span className="block user-name font-semibold mr-4">{item.userName}</span>
                        </div>
                        <span className="block updated-time text-body/30 text-sm mb-1"> {this.getDiff(item.commentDate)}</span>
                        

                        {
                            (!item.editorMode || this.state.mode !== 'Update') &&
                            <div className="text-sm mb-2" dangerouslySetInnerHTML={{__html :  this.setFilePaths(item.comment)}}></div>
                        }
                        
                        {
                            item.editorMode && this.state.mode === 'Update' &&
                            <>
                            <Editor item={item} toggleEditor={this.toggleEditor} id={item.commentId} baseurl={this.state.baseUrl} mode={this.state.mode} />
                            </>
                        }
                            
                            
                            <div className='flex items-center mt-2 text-sm'>                                                 
                                    {
                                        !item.editorMode &&
                                        <span onClick={() => this.changeStatus(item)}  className='text-sm flex items-center text-primary bg-primary/10 rounded-lg h-10 py-2.5 px-4 cursor-pointer hover:bg-primary/20'>
                                            {
                                                item.isFavorite === "1" &&
                                                <FaThumbsUp className='text-primary/80 w-4 h-4' />
                                            }
                                            {
                                                item.isFavorite !== "1" &&
                                                <FaRegThumbsUp className='text-primary/80 w-4 h-4' />
                                            }
                                            {
                                                item.favCount !== "" && item.favCount > 0 &&
                                                <span className='ml-2'>{item.favCount}</span>
                                            }
                                        </span>
                                    }
                                    {
                                        !item.editorMode &&
                                        <span  onClick={() => this.addComment(item)}  data-id={item.commentId}  className="text-sm flex items-center text-primary bg-primary/10 rounded-lg h-10 py-2.5 px-4 ml-2 cursor-pointer hover:bg-primary/20">
                                            <FaRegCommentAlt className='w-4 h-4 mr-2  text-primary/80' />
                                            {
                                                item.totalChild && item.totalChild !== "" &&
                                                <span className='mr-2'>{item.totalChild}</span>
                                            }
                                            Add Response
                                        </span>
                                    }
                                    <div className="ml-auto flex items-center">
                                        {
                                            !item.editorMode && item.replyBy === USERID &&
                                            <span  onClick={() => this.editComment(item)}  className="text-sm flex items-center text-primary  rounded-lg cursor-pointer">
                                                <BiEdit className='w-5 h-5 text-primary/80' />
                                            </span>
                                        }
                                        {
                                            !item.editorMode && item.replyBy === USERID &&
                                            <span  onClick={() => this.deleteComment(item)}  className="text-sm flex items-center text-primary  rounded-lg ml-2 cursor-pointer">
                                                <RiDeleteBin6Line className='w-5 h-5 text-primary/80' />
                                            </span>
                                        }
                                    </div>

                            </div>
                            {
                                item.editorMode && this.state.mode === 'Reply' &&
                                <>
                                <Editor item={item} toggleEditor={this.toggleEditor} id={item.commentId} baseurl={this.state.baseUrl} mode={this.state.mode} />
                                </>
                            }
                    </div>
                </div>
            
            {item.replies &&
              <div className="pl-4 ml-2 md:ml-4 lg:ml-16">
              {
                item.replies.map((reply, i) => {
                  return this.renderComment(reply, i);
                })
              }
              </div>  
            }
          </div>
        );
    };

    renderData(){
        const { data, loadingData } = this.state;

        return (
             data && 
            <div className={`item-content`}>
                <div className='my-6 flex items-center text-sm text-primary'>
                    <Link to="/">Home</Link><span className='mx-1'>/</span>
                    <Link to="/forum">Forum</Link><span className='mx-1'>/</span>
                    <span>{data.title}</span>
                </div>  
                <h1 className="md:text-3xl font-semibold mt-4 lg:mt-12 mb-4">{data.title}</h1>
                <div className="flex items-center mb-4">
                    <div className="user-icon min-w-[64px] w-[64px] h-[64px] rounded-full bg-gray-500 mr-3 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${data.userImage}&access_token=${ASSCESS_TOKEN}")` }}></div>
                    <div className="">
                        <div className="flex items-center">
                            <span className="block text-lg user-name font-semibold mr-4">{data.userName}</span>
                        </div>


                        <span className="block updated-time text-body/30"> {this.getDiff(data.postDate)}</span>
                    </div>
                </div>
                <div className='my-2 text-primary flex items-center'>
                    {
                        data.tags && data.tags.map((tag, index) => {
                            return (
                                <span key={index} className="m-1">#{tag}</span>
                            )
                        })
                    }
                </div>
                <div className="mb-8">
                    {
                        (!data.editorMode || this.state.mode !== 'Update') &&
                        <div className="text-sm mb-2" dangerouslySetInnerHTML={{__html : this.setFilePaths(data.post)}}></div>
                    }
                    
                    {
                        data.editorMode && this.state.mode === 'Update' &&
                        <>
                        <Editor item={data} toggleEditor={this.toggleEditor} id={data.postId} baseurl={this.state.baseUrl} mode={this.state.mode} />
                        </>
                    }
                    <div className="flex items-center text-sm mt-4">
                        {
                            !data.editorMode &&
                            <span onClick={() => this.changeStatus(data)}  className='text-sm flex items-center text-primary bg-primary/10 rounded-lg h-10 py-2.5 px-4 cursor-pointer hover:bg-primary/20'>
                                {
                                    data.isFavorite === "1" &&
                                    <FaThumbsUp className='text-primary/80 w-4 h-4' />
                                }
                                {
                                    data.isFavorite !== "1" &&
                                    <FaRegThumbsUp className='text-primary/80 w-4 h-4' />
                                }
                                {
                                    data.favCount !== "" && data.favCount > 0 &&
                                    <span className='ml-2'>{data.favCount}</span>
                                }
                            </span>
                        }                        
                        {
                            !data.editorMode &&
                            <span  onClick={() => this.addComment(data)}  data-id={data.postId}  className="text-sm flex items-center text-primary bg-primary/10 rounded-lg h-10 py-2.5 px-4 ml-2 cursor-pointer hover:bg-primary/20">
                                <FaRegCommentAlt className='w-4 h-4 mr-2  text-primary/80' />
                                {
                                    data.totalChild && data.totalChild !== "" &&
                                    <span className='mr-2'>{data.totalChild}</span>
                                }
                                Add Response
                            </span>
                        }
                        <div className="ml-auto flex items-center">
                            {
                                !data.editorMode && data.postedBy === USERID &&
                                <span  onClick={() => this.editComment(data)}  className="text-sm flex items-center text-primary  rounded-lg cursor-pointer">
                                    <BiEdit className='w-5 h-5 text-primary/80' />
                                </span>
                            }
                            {
                                !data.editorMode && data.postedBy === USERID &&
                                <span  onClick={() => this.deleteComment(data)}  className="text-sm flex items-center text-primary  rounded-lg ml-2 cursor-pointer">
                                    <RiDeleteBin6Line className='w-5 h-5 text-primary/80' />
                                </span>
                            }
                        </div>
                    </div>
                    {
                        data.editorMode  && this.state.mode === 'Reply'  &&
                        <>
                            <Editor item={data} toggleEditor={this.toggleEditor} id={data.postId} baseurl={this.state.baseUrl} mode={this.state.mode} />
                        </>
                    }
                </div>

                {
                    data.comments && data.comments.length > 0 &&
                    <div className="pl-2 md:pl-4 lg:pl-8 pr-2">
                    { data.comments.map((item, index) => {
                        return this.renderComment(item, index);
                        })}
                    </div>
                }

             
            </div>
            
        )
    }

  render(){
    const { deleteMode, isLoading, isError } = this.state;
    return (
        <HelmetProvider>
            <div>
            <Helmet>
            <title>DPN - Forum Details</title>
            </Helmet>
            <Header/>
            {
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8  relative z-0 text-body">
                    <div className="lg:w-3/4">
                        
                    {isLoading ? <Loader /> : this.renderData()}
                    {isError && <ApiError />}
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
            }
            {/* {
                editorMode &&
                <Editor toggleEditor={this.toggleEditor}  title={data.title} baseurl={data.baseUrl}  />
            } */}
            {
                deleteMode &&
                <>
                    <div tabIndex="-1" className="fixed  w-[480px] top-1/2  left-1/2  -translate-y-1/2  -translate-x-1/2 transform  z-50 overflow-x-hidden overflow-y-auto ">
                        <div className="relative w-full h-full max-w-md md:h-auto">
                            <div className="relative bg-white rounded-lg shadow">
                                <button  onClick={this.cancelDelete} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-hide="popup-modal">
                                    <MdOutlineClose className='w-6 h-6' />
                                    <span className="sr-only">Close modal</span>
                                </button>
                                <div className="pt-12 p-6 text-center">
                                    
                                    <h3 className="mb-6 text-lg font-normal text-body">Are you sure you want to delete this?</h3>
                                    <button onClick={this.acceptDelete}  data-modal-hide="popup-modal" type="button" className="text-red-600 border border-red-600 hover:bg-red-600 focus:ring-4 focus:outline-none transition-all duration-300 hover:text-white focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                                        Yes
                                    </button>
                                    <button onClick={this.cancelDelete} data-modal-hide="popup-modal" type="button" className="text-primary  bg-white  
                                     hover:bg-primary transition-all duration-300 hover:text-white focus:ring-4 focus:outline-none focus:ring-primary/50 rounded-lg border border-primary text-sm font-medium px-5 py-2.5  focus:z-10 ">No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-20'></div>
                </>
            }
            <Footer />
            </div>
        </HelmetProvider>
    )

  }

}

export default withParams(DetailPage);;


