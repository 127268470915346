import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, Navigate  } from "react-router-dom";
import RegistrationForm from "../compornents/RegistrationForm";
import axios from 'axios';
import { api } from "../Settings";

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;

function withParams(Component) {
  return props => <Component {...props} navigation={useNavigate()} />;
}

class Login extends Component {

    constructor (props) {
        super(props);  

        this.state = {
          componentData: []
        }

    }

    componentDidMount () {
     
      axios.get(api.getRegistrationAddtionalFields).then(response => {

        console.log(response);
          this.setState({
            data: response.data
          })
      });

    }


  render(){

    if (ASSCESS_TOKEN) {
      return <Navigate to="/" />;
    }
    
    return (
      <HelmetProvider>
        <div>
        <Helmet>
          <title> DPN - Registration </title>
        </Helmet>
        <div className="container flex items-center justify-center mx-auto md:px-8 lg:h-screen  w-full">
              <RegistrationForm  />
        </div>
        </div>
        </HelmetProvider>
    )
  }
}

export default withParams(Login);
