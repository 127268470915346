import React, { Component} from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import axios from 'axios';
import { api } from "../Settings";
import Header from "./../compornents/Header";
import Footer from "./../compornents/Footer";
import GridBox from "./../compornents/GridBox";
import Banner from "./../compornents/Banner";
import PartnerFeeds from "./../compornents/PartnerFeeds";
import ApiError from "./../compornents/ApiError";
import Loader from "./../compornents/Loader";

class DilmahUniverse extends Component {

    constructor (props) {
        super(props)

        this.state = {
            isLoading: false,
            isError: false,
            componentData: []
        }
    }

    componentDidMount () {
        const that = this;
        that.setState({ isLoading: true, isError: false })
        axios.get(api.getDilmahUniverse).then(response => {
          this.setState({
            componentData: response.data.components
          })
          this.setState({ isLoading: false });
        })
        .catch(function (error) {
          that.setState({ isLoading: false, isError: true  })
        })
    }

    render(){
        const { componentData, isLoading, isError } = this.state;
        return (
            <HelmetProvider>
                <div>
                <Helmet>
                <title> DPN - Dilmah Universe </title>
                </Helmet>
                <Header/>
                <div className="container min-h-screen mx-auto mt-[120px] lg:flex xl:px-8">
                    <div className="lg:w-3/4">
                        {isLoading ? <Loader /> : <>
                            {
                                componentData.Banner && 
                                <Banner gradient={true} data={componentData.Banner} />
                            }
                            {
                                componentData.BoxList && 
                                <GridBox data={componentData.BoxList} />
                            }
                        </>}
                        {isError && <ApiError />}
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 xl:ml-8">
                        <PartnerFeeds />
                    </div>
                </div>
                <Footer />
                </div>
            </HelmetProvider>
        )
    }
}

export default DilmahUniverse;
