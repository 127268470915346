import React, { Component } from 'react';
import logo from './../assets/logo.png';
import axios from 'axios';
import { useNavigate,NavLink, useLocation  } from "react-router-dom";
import { BiLoaderAlt } from 'react-icons/bi';


function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} location={useLocation()}  />;
}
  

class LoginForm extends Component {

    constructor (props) {
        super(props);
        this.state = {
          username: '', 
          password: '',
          emailError: '',
          passwordError: '',
          apiError: '',
          isLoading: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.validate = this.validate.bind(this);


    /*     const { navigation } = this.props;
        const { state } = this.props.location;
        const { redirectTo, previousPath } = state */

      /*   console.log("bbbb");
  
        console.log(state);
     //   console.log(redirectTo, previousPath);

        console.log(redirectTo.state.previousPath);
        console.log( this.props.location);
        console.log("cccc"); */

    }

    handleChange(event) {
        const target = event.target;
        const val = (target.value) ? target.value : "";
        const value = target.type === 'checkbox' ? target.checked : val;
        const name = target.name;
        this.setState({ [name]: value    });
        this.setState({  apiError: "",  passwordError : "", emailError : ""});
    }

    validate() {
      let emailError = "";
      let passwordError = "";
      let apiError = "";

      this.setState({  apiError: "" });

      const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.state.username || reg.test(this.state.username) === false) {
        emailError = "Email Field is Invalid ";
      }
      if (!this.state.password) {
        passwordError = "Password field is required";
      }
      if (emailError || passwordError) {
        this.setState({  emailError, passwordError });
        return false;
      }
      return true;
    }
 

    handleSubmit(event) {

      const { navigation } = this.props;
      const { state } = this.props.location;
      const redirectTo = (state && state.redirectTo) ? state.redirectTo : null;

     /*  console.log(state);
      console.log("previousPath", redirectTo.state.previousPath);
      console.log( this.props.location);
      console.log("88888888"); */
      //console.log(location);

    

      if (this.validate()) {

      
        const that = this;

        that.setState({  isLoading: true });

      /*   let response =  { "data" : {"data":{"access_token":"278390603001f77d601a58bf1668fdd869c0f304","refresh_token":"5a348a59d7cce27f1304da6be22d9b1fdbd116e5","expires_in":3600,"expires_on":null},"details":{"user_id":"16","username":"dilmah@ebeyonds.com","first_name":"eBEYONDS (PVT) LTD","last_name":"","contact_no":"+94 11 2840617-9","fb_id":"","skype_id":"","countries_name":"Sri Lanka","countries_id":"196","change_credentials":"1","inv_id":null,"notification":"1","lang_details":{"id":"1","code":"en","text":"English"}},"profiles":[{"client_id":"4","client_name":"Dilmah t-Lounge","url_prefix":"dilmah"},{"client_id":"5","client_name":"Dilmah t-Lounge Forum","url_prefix":"forumd"},{"client_id":"6","client_name":"Dilmah reservation","url_prefix":"resvd"},{"client_id":"11","client_name":"Dilmah Staging","url_prefix":"dilmahstaging"},{"client_id":"16","client_name":"Dilmah School of Tea | e-Learning Platform","url_prefix":"elearning"},{"client_id":"26","client_name":"Dilmah Partner Network","url_prefix":"partner"}],"success":true,"outside_clients":[{"client_id":"5","url":"https://forum.dilmaht-lounge.com","login_check_url":"check-login.php","logout_url":"logout.php"},{"client_id":"6","url":"https://reservation.dilmaht-lounge.com","login_check_url":"check-login.php","logout_url":"logout.php"}],"user_key":"c74d97b01eae257e44aa9d5bade97baf","admin":true} }; 
      // let response = { "data" : {"success":false,"message":"Invalid username and password combination","outside_clients":[]} } 

      let response = { "data" : {"data":{"access_token":"0100b170e91b785813c6e4ebe470ac5494446ab3","refresh_token":"0b643970336ff6b3f93a343773b524fd9f36f24c","expires_in":3600,"expires_on":1677135555},"details":{"user_id":"4","username":"chathura.perera@ebeyonds.com","first_name":"Chathura Madhuranga Perera","last_name":null,"contact_no":"","fb_id":"","skype_id":"","countries_name":null,"countries_id":null,"change_credentials":"1","inv_id":null,"notification":"0","lang_details":{"id":"1","code":"en","text":"English"}},"profiles":[{"client_id":"7","client_name":"Chathura Madhuranga Perera","url_prefix":"chathura-madhuranga-perera"},{"client_id":"48","client_name":"Software Quality Assurance","url_prefix":"ishara"}],"success":true,"outside_clients":[],"user_key":"a87ff679a2f3e71d9181a67b7542122c","dash_permissions":{"DASHBOARD_ACCESS":true,"DASHBOARD_MANAGE":false,"DASHBOARD_MODULE_ACCESS":true,"MANAGE_DASHBOARD_ACCESS":true,"ACCESS_MEDIA_TREE":true,"ASSIGN_CLIENT_LEVELS":true,"MANAGE_CLIENT_LEVELS":true,"MEDIA_ALLOW_ACCESS_TO_MY_FILES":true,"MEDIA_FILES_MANAGE_PUBLIC":true,"MEDIA_FILE_ACCESS":true,"MEDIA_FILE_ASSIGN_TAGS":true,"MEDIA_FILE_DOWNLOAD":true,"MEDIA_FILE_MANAGEMENT":true,"MEDIA_MANAGER_ACCESS":true,"MEDIA_MANAGE_COLLECTION":true,"MEDIA_MANAGE_CUSTOM_TAGS":true,"MEDIA_MANAGE_PUBLIC_COLLECTION":false,"MEDIA_MANAGE_STANDARD_TAGS":true,"MEDIA_TAG_MANAGEMENT":true},"base_url":"https://ishara.ebeyonds-qa.dammore.com","base_account_url":"https://accounts-ishara.ebeyonds-qa.dammore.com", "dash_admin": true, "file_upload_dir":"1538"} };
      let data = response.data;
       localStorage.setItem("_userData",  JSON.stringify(data)); */

      axios.post('/checklogin', {
        username: this.state.username,
        password: this.state.password
      })
      .then(function (response) {

          let data = response.data;
          if(data.success ){
              localStorage.setItem("_userData",  JSON.stringify(data));
             //

             if( redirectTo && redirectTo?.pathname !== "/logout" && redirectTo?.pathname !== "/login" ){
              /// console.log("redirect true");
                 navigation(`${redirectTo.pathname}${redirectTo.search}`);
                 navigation(0);
              }else if( redirectTo && redirectTo.state && redirectTo?.state?.previousPath ){
             //  console.log("redirect to prev path", redirectTo.state.previousPath );
                 navigation(`${redirectTo.state.previousPath}`);
                 navigation(0);
              }else{
             //  console.log("redirect false");
                 navigation("/");
                 navigation(0);
              }
           
             
          }else{
            that.setState({  apiError: data.message });
          }

          that.setState({  isLoading: false });

      })
      .catch(function (error) {
        that.setState({  isLoading: false });
        console.log(error);
      });
 

      }

      event.preventDefault();


    }
    

    render() {
      return (
        <>
        <div className='mx-auto w-[380px]'>
            <div className="w-full text-left text-sm">
                <form className="bg-white" onSubmit={this.handleSubmit}>
                    <div className='text-center'>
                        <img className="logo mx-auto mb-6" width="138" height="auto" src={logo} />
                        <h1 className='text-2xl md:text-3xl mb-8 font-normal'>Welcome to Dilmah Partner Network</h1>
                    </div>
                    <div className="mb-2 md:mb-4">
                      <label className="block text-gray-700  font-semibold mb-2" htmlFor="username">Email address</label>
                      <input  onChange={this.handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.username || ''}    name="username" type="email" placeholder="yourname@company.com"></input>
                      <span className="text-red-500 text-xs italic">{this.state.emailError}</span>
                    </div>
                    <div className="mb-2 md:mb-4">
                    <label className="block text-gray-700 font-semibold mb-2"  htmlFor="password">
                        Password
                    </label>
                    <input  onChange={this.handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={this.state.password || ''}   name="password" type="password" placeholder="************"></input>
                    <span className="text-red-500 text-xs italic">{this.state.passwordError}</span>
                    </div>
                    <div className="flex justify-between">
                        <label className="flex items-center">
                            {/* <input className="mr-2" type="checkbox"></input> */}
                            <span>Forget your password ?</span>
                        </label>
                        <NavLink to="/forget-password" 
                        className='font-semibold'>Reset here</NavLink>
                    </div>
                    <div className="text-center">

                      {
                        !this.state.isLoading &&
                        <button type='submit' className="inline-block w-full  mt-2 md:mt-5 btn bg-primary shadow-md py-3 px-5 rounded-md text-white hover:shadow-lg hover:opacity-80">Log in</button>
                      }
                      {
                        this.state.isLoading &&
                        <button type='button' className="w-full  mt-2 md:mt-5 btn bg-primary py-3 px-5 rounded-md text-white shadow-lg opacity-80 flex items-center justify-center cursor-default">
                            <BiLoaderAlt className="animate-spin h-5 w-5 mr-2"  /> Loading...
                        </button>
                      }

                        <span className="text-red-500 text-sm italic mt-2 text-left block">{this.state.apiError}</span>
                       {/*  <p className='mt-2 md:mt-4'>Forget your password ? <NavLink to="/forget-password" 
                        className='font-semibold'>Reset here</NavLink></p>  */}
                        <p className='mt-4'>Don’t have an account ? <NavLink to="/registration" 
                        className='font-semibold'>Sign up here</NavLink></p>
                    </div>
                </form>
            </div>
          
        </div>
        </>
      );
    }
}

export default withParams(LoginForm);