import React, { Component } from 'react';
import { useNavigate,Link  } from "react-router-dom";
import { FiDownload } from "react-icons/fi";
import { BsEye, BsPlus, BsShareFill } from "react-icons/bs";
import Share from './Share';
const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;

function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} />;
}

class SubCollections extends Component {

    constructor (props) {
        super(props);
        this.state = {
            data: { items:[{}]},
            shareMode: false,
            selectedIds: [],
            selectedTitle: null,
        }

        this.shareMedia = this.shareMedia.bind(this);
        this.toggleShare = this.toggleShare.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {data: props.data };
    }

    shareMedia(e){

        let id = e.currentTarget.getAttribute("data-id");
        let title = e.currentTarget.getAttribute("data-title");

        if(id){
            this.setState({ selectedIds: [id], selectedTitle: title, shareMode: true });
        }
    }

    toggleShare(state){
        this.setState({ shareMode: state, selectedIds: [] });
    }

    render() {
        const { data, shareMode, selectedIds, selectedTitle } = this.state;
        return (
            data &&
            <>
                <div className="items grid grid-cols-2 md:grid-cols-3  lg:grid-cols-3 2xl:grid-cols-4 gap-2 md:gap-4 xl:gap-8">
            {
            data.items.length > 0 &&
            data.items.map((item, index) => {
                const thumbnails = item.thumbnails;
                return (
                    item.title &&
                    <div key={index} className="flex items-start flex-wrap mb-8">
                        <div className="item-content text-sm w-full">
                            <Link to={`/collections/${item.id}`} className='block w-full'>
                                <div className="mb-2 grid gap-1 grid-cols-1 rounded-xl overflow-hidden">
                                        <span className="bg-primary/20 w-full h-0 pt-full bg-no-repeat bg-cover bg-center relative">
                                            {
                                                item.cover_image &&
                                                <span key={index} className="w-full h-full absolute left-0 top-0 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${item.cover_image}&access_token=${ASSCESS_TOKEN}&width=480&height=0")` }}>
                                                </span>
                                            }
                                        
                                            {!item.cover_image && thumbnails && thumbnails.length > 0 &&
                                                thumbnails.map((thumb, index) => {
                                                    return (
                                                    index < 1 &&
                                                    <span key={index} className="w-full h-full absolute left-0 top-0  bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${thumb.path}&access_token=${ASSCESS_TOKEN}&width=480&height=0")` }}>
                                                        {index == "3" &&
                                                            <span className="bg-black/70 absolute w-full h-full top-0 left-0">
                                                            <span className='text-white text-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center'>{item.mediaCount}<BsPlus className='text-2xl'/></span>
                                                            </span>
                                                        }
                                                    </span>
                                                    );
                                                })
                                            }
                                        </span>
                                </div>
                            </Link>
                            <h3 className="mb-3 font-semibold text-xl">
                                <Link to={`/collections/${item.id}`} className='block w-full'>
                                    {item.title}
                                </Link>
                            </h3>
                            {/* {
                                item.mediaCount &&
                                <Link to={`/collections/${item.id}`} className='block w-full'>
                                    <span className='block my-1'>{item.mediaCount} Assets</span>
                                </Link>
                            } */}
                            <div className='line-clamp-3 mb-2'>
                                <Link to={`/collections/${item.id}`} className='block w-full'>
                                    <p>{item.description}</p>
                                </Link>
                            </div>
                        </div>
                        <div className="actions flex items-start mt-4 h-8 self-end w-full">
                            <Link to={`/collections/${item.id}`}  className="view btn text-primary border border-primary hover:bg-primary transition-all duration-300  rounded-md text-sm hover:text-white  flex items-center py-2 px-3 mr-2 h-full" ><BsEye/><span className='ml-2'>{data.detailLabel}</span></Link>
                           {/*  <a href={item.downloadLink} className="download btn text-primary border border-primary hover:bg-primary transition-all duration-300  rounded-md text-sm hover:text-white  py-2 px-3  mr-2 h-full"><FiDownload /></a> */}
                           <a  onClick={this.shareMedia} data-id={item.id}   data-title={item.title}  className="share btn text-primary border border-primary hover:bg-primary transition-all duration-300  rounded-md text-sm hover:text-white cursor-pointer py-2 px-3 h-full" ><BsShareFill /></a>
                        </div>
                    </div>
                );
            })}         
                </div>

                {
                shareMode &&
                    <Share toggleShare={this.toggleShare} type="collection" ids={selectedIds} title={selectedTitle} baseUrl={data.baseUrl} />
                }
            </>
        );
    }
}

export default withParams(SubCollections);