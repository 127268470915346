import React, { Component } from 'react';
import { BsFacebook,BsInstagram,BsYoutube,BsTwitter,BsLinkedin,BsPinterest } from "react-icons/bs";
import { SiIssuu } from "react-icons/si";
import logo from './../assets/logo_mini.png';

const navigation = [
  {
      url: "https://www.facebook.com/dilmah/",
      key: "facebook",
      icon: <BsFacebook />
  },
  {
      url: "https://www.instagram.com/dilmahceylontea/",
      key: "instagram",
      icon: <BsInstagram />
  },
  {
      url: "https://www.youtube.com/dilmahrealtea/",
      key: "youtube",
      icon: <BsYoutube />
  },
  {
      url: "https://twitter.com/dilmah/",
      key: "twitter",
      icon: <BsTwitter />
  },
  {
      url: "https://www.linkedin.com/company/dilmah-mjf/",
      key: "linkedin",
      icon: <BsLinkedin />
  },
  {
      url: "https://www.pinterest.com/dilmah/",
      key: "pinterest",
      icon: <BsPinterest />
  },
  {
      url: "https://issuu.com/dilmah/",
      key: "issuu",
      icon: <SiIssuu />
  }

];


class Footer extends Component {

    render() {
      
      return (
        <>
          <footer className="md:flex w-full z-10 bg-white border-t text-body/50 py-4">
            <div className="container mx-auto px-8 py-8 md:flex justify-between items-center text-center">
                <a href="http://dilmahtea.com/" target="_blank" className='text-2xl font-bold text-primary inline-block mb-4 md:mb-0'> <img className="logo w-16" src={logo} /></a>
                <div className='social-links flex items-center mb-4 md:mb-0'>
                  {navigation.map((item, index) => {
                    return (
                        <a key={index} href={item.url} target="_blank" className='text-white bg-primary text-lg flex items-center justify-center rounded-full w-[32px] h-[32px] lg:w-[48px] lg:h-[48px] mx-1 hover:opacity-70 transition-all duration-200'>
                           {item.icon}
                        </a>
                    );
                  })} 
              </div>
                <span className='text-sm'>© 2023 DAMMORE by <a href='http://ebeyonds.com'>eBEYONDS</a></span>
            </div>   
          </footer>
        </>
    
      );
    }
  }

  export default Footer;
  
  