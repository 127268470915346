import React, { Component, createRef } from 'react';
import Select, { components } from "react-select";
import axios from 'axios';
import { AiOutlineSearch } from "react-icons/ai";
import { BsTags, BsCalendar3 } from "react-icons/bs";
import { MdArrowDropDown, MdOutlineSort, MdOutlineClose } from "react-icons/md";
import AssetsMenu from './AssetsMenu';
import { api } from "../Settings";
import { GrRestaurant } from 'react-icons/gr';
import { GiMeal, GiWaterBottle } from 'react-icons/gi';
import { FiCoffee } from 'react-icons/fi';

const { Option } = components;

const filters = [
  {
      label: "Chef",
      key: "chef",
      icon: <GrRestaurant className='stroke-body ' />
  },
  {
      label: "Category",
      key: "category",
      icon: <GiMeal />
  },
  {
      label: "Varity",
      key: "varity",
      icon: <GiWaterBottle />
  },
  {
      label: "Range",
      key: "range",
      icon: <FiCoffee />
  },
  {
      label: "Sort",
      key: "sort",
      icon:  <MdOutlineSort />
  }
];

const CustomOption = props => (
  <Option {...props}>
    <div className='flex items-center'>
     {/*  <BsTags className='text-primary mr-1 min-w-[24px]' /> */}
      <span className='text-sm'>{props.data.label}</span>
    </div>
  </Option>
);

const Control = ({ children, ...props }) => {

  return (
    <components.Control {...props}>
      <span >
      <AiOutlineSearch className='text-xl text-gray-400 ml-2' />
      </span>
      {children}
    </components.Control>
  );
};

class RecipeFilter extends Component {

    constructor (props) {
        super(props)
        this.selectRef  = createRef();
        this.wrapperRef  = createRef();
        this.selectChefRef  = createRef();
        this.selectRangeRef  = createRef();
        this.selectVarityRef  = createRef();

        this.state = {
          tags: [],
          selected:{
            category: {},
            chef: {},
            sort: {},
            varity: {},
            range: {},
            tags:{}
          },
          activeFilter: '',
          activeTags:'',
          active: false,
          searchparams: '',
          showMenu: false,
          filterOpt:{
            "category":[ 
                {
                    "value":"1",
                    "label":"Food"
                },
                {
                    "value":"2",
                    "label":"Drink"
                },
                {
                    "value":"20",
                    "label":"Combo"
                }
            ],
            "sort":[ 
                {
                    "code":"ASC-name",
                    "label":"Name ascending"
                },
                {
                    "code":"DESC-name",
                    "label":"Name descending"
                },
                {
                    "code":"ASC-created_date",
                    "label":"Date ascending"
                },
                {
                    "code":"DESC-created_date",
                    "label":"Date descending"
                }
              
            ],
            "chef":[],
            "range":[],
            "varity":[]
          },
          optChefs:[],
          optRanges:[],
          optVarities:[],
          allChefs: false,
          allRanges: false,
          allVarities: false,
          isLoading:false,
          loadedChefs:0,
          loadedRanges:0,
          loadedVarities:0,
        };
        this.showMenu = props.showMenu;
        this.mode = props.mode;
        this.toggleSubMenu = this.toggleSubMenu.bind(this);
        this.seletFilterItem = this.seletFilterItem.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.clearSelectedItem = this.clearSelectedItem.bind(this);
        this.selectTags = this.selectTags.bind(this);
        this.selectOptions = this.selectOptions.bind(this);
        this.updateSelection = props.updateSelection.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleMenuScrollToBottom = this.handleMenuScrollToBottom.bind(this);
      //  this.renderOption = this.renderOption.bind(this);
    }

    

    componentDidMount () {

      /*  axios.get(api.getNewsTags).then(response => {
         this.setState({
           tags: response.data.data
         })
       }); */

       axios.get(api.getRecipeChefs, { params: { st: this.state.loadedChefs } }).then(response => {

        this.setState({ optChefs: response.data.data, loadedChefs: this.state.loadedChefs + 20 });

 /*        this.setState({ filterOpt[chef]:  response.data.data} },() => {
          console.log('set chefs ', this.state.filterOpt, response.data.data);
        });
        */
        
       });

       axios.get(api.getRecipeVarities, { params: { st: this.state.loadedVarities }}).then(response => {

        this.setState({ optVarities: response.data.data, loadedVarities: this.state.loadedVarities + 20  });

        /* this.setState({ filterOpt: { ...this.state.filterOpt, varity:  response.data.data} },() => {
          console.log('set varity ', this.state.filterOpt, response.data.data);
        }); */
      
       });

       axios.get(api.getRecipeRanges, { params: { st: this.state.loadedRanges }} ).then(response => {

        this.setState({ optRanges: response.data.data, loadedRanges: this.state.loadedRanges + 20  });

       /*  this.setState({ filterOpt: { ...this.state.filterOpt, range:  response.data.data} },() => {
          console.log('set range ', this.state.filterOpt, response.data.data);
        }); */
      
       });

       document.addEventListener("mousedown", this.handleClickOutside);

    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleMenuScrollToBottom(type) {

          let dataKey;
          let loadedFlag;
          let loadedCount;
      
          switch(type){

              case 'getRecipeChefs':
                dataKey = 'optChefs';
                loadedFlag = 'allChefs';
                loadedCount = 'loadedChefs';
              break;

              case 'getRecipeRanges':
                dataKey = 'optRanges';
                loadedFlag = 'allRanges';
                loadedCount = 'loadedRanges';
              break;

              case 'getRecipeVarities':
                dataKey = 'optVarities';
                loadedFlag = 'allVarities';
                loadedCount = 'loadedVarities';
              break;

          }


        if( this.state[loadedFlag]){
          return;
        }
      
        this.setState({ isLoading: true });

    //  if(this.state.isLoading){
        this.setState({ [dataKey]: [ ...this.state[dataKey], { value: 'loading', label: 'Loading more...', disabled: true  }] })
   //   }

   //   [{ value: 'loading', label: 'Loading more...', disabled: true }]



     // setTimeout(() => {

        
            axios.get(api[type], { params: { st: this.state[loadedCount] } }).then(response => {
      
              //this.setState({ optChefs: response.data.data });

              if(response.data.data.length === 0 ){
                this.state[loadedFlag] = true;
              }
      
              var array = [...this.state[dataKey]]; // make a separate copy of the array
              array.map((item, i) => {
                  if(item.value === 'loading'){
                    array.splice(i, 1);
                  }
              });
      
             // this.setState({ isLoading: false });
               this.setState({ [dataKey]: [ ...array, ...response.data.data ] },() => {

                this.setState({ [loadedCount]: this.state[loadedCount] + 20 });
                  
              //  that.setState({ contentLoading: false, isError: false, isLoading: false })
                
                //if(!reset){
                //    const newCount = this.state.pageSize + this.state.loadedCount;
                    //this.setState({ loadedCount: newCount })
               // }
      
            });
       
            });
        
    //  }, 3000);

    }

   /*  renderOption({
      focusedOption,
      focusOption,
      key,
      labelKey,
      option,
      selectValue,
      style,
      valueArray
    }){

      const className = ['VirtualizedSelectOption']

      if (option === focusedOption) {
        className.push('VirtualizedSelectFocusedOption')
      }
  
      if (option.disabled) {
        className.push('VirtualizedSelectDisabledOption')
      }
  
      if (valueArray && valueArray.indexOf(option) >= 0) {
        className.push('VirtualizedSelectSelectedOption')
      }
  
      if (option.className) {
        className.push(option.className)
      }
  
      const events = option.disabled
        ? {}
        : {
          onClick: () => selectValue(option),
          onMouseEnter: () => focusOption(option)
        };
  
      if (option.disabled) {
        return (
          <div
            className={className.join(' ')}
            key={key}
            style={style}
            title={option.title}
            {...events}
          >
            <div style={{ textAlign: 'center', width: '100%' }}>
              <span className="Select-loading" style={{ marginRight: '0.5em' }}></span>
              <span>{option[labelKey]}</span>
            </div>
          </div>
        )

    }

  } */


    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.setState({activeFilter: ""});
        }
    }

    toggleSubMenu(e) {
        let activeKey = e.currentTarget.getAttribute("data-key");
        const { activeFilter } = this.state;

        if(activeKey === activeFilter ){
          this.setState({activeFilter: ""});
        }else{
          this.setState({activeFilter: activeKey});
        }

/*         console.log(this.state.filterOpt);
        console.log(this.state.optChefs);
        console.log(this.state.optRanges);
        console.log(this.state.optVarities); */
      /*   if(activeKey === 'tags'){
          console.log("focuse menu");
          console.log(this.selectRef.current);
          
                  if (this.selectRef.current) {
                    this.selectRef.current.focus();
                  }
        } */

    }

    seletFilterItem(e) {

        let fType = e.currentTarget.getAttribute("data-type");
        let fVal = e.currentTarget.getAttribute("data-id");
        let fLabel = e.currentTarget.getAttribute("data-label");

        let selectedFilters =  this.state.selected;

        let obj = {
          code: fVal,
          label: fLabel,
          type: fType
        };
       
      
        switch (fType) {
          case "chef":
            this.setState({ selected: { ...this.state.selected, chef: obj} });
            selectedFilters.chef = obj;
            break;
        
          case "category":
            this.setState({ selected: { ...this.state.selected, category: obj} });
            selectedFilters.category = obj;
            break;

          case "varity":
            this.setState({ selected: { ...this.state.selected, varity: obj} });
            selectedFilters.varity = obj;
            break;

          case "range":
            this.setState({ selected: { ...this.state.selected, range: obj} });
            selectedFilters.range = obj;
            break;

          case "sort":
            this.setState({ selected: { ...this.state.selected, sort: obj} });
            selectedFilters.sort = obj;
            break;
        
          default:
            break;
        }

        this.updateSelection(selectedFilters);

        this.setState({
          activeFilter: ""
        })
    }

    selectTags(selectedTags) {

     // console.log('tags updated', selectedTags);

      let selectedFilters =  this.state.selected;
    
      let obj = {
        type: 'tags',
        items: selectedTags
      };
    
      this.setState({ selected: { ...this.state.selected, tags: obj} });
      selectedFilters.tags = obj;
      this.updateSelection(selectedFilters);

      this.setState({ activeFilter: ""})
    
    }

    selectOptions(data, type) {

     console.log('tags updated', data, type);

     let selectedFilters =  this.state.selected;

      let obj = {
        type: type,
        items: data
      };

      this.setState({ selected: { ...this.state.selected, [type]: obj} });
      selectedFilters[type] = obj;
      this.updateSelection(selectedFilters);

      this.setState({ activeFilter: ""})


      console.log(this.state.selected);

/*       let selectedFilters =  this.state.selected;
    
      let obj = {
        type: 'tags',
        items: selectedTags
      };
    
      this.setState({ selected: { ...this.state.selected, tags: obj} });
      selectedFilters.tags = obj;
      this.updateSelection(selectedFilters);

      this.setState({ activeFilter: ""}) */
    
    }

    clearSelectedItem(e){
      
      let fType = e.currentTarget.getAttribute("data-type");
      let fVal = e.currentTarget.getAttribute("data-id");

      let selectedFilters =  this.state.selected;

      console.log(fType);

      switch (fType) {
        case "chef":
          /* this.setState({ selected: { ...this.state.selected, chef: {}} });
          selectedFilters.chef = {}; */

          selectedFilters.chef.items.map((tag, i) => {
            if(tag.value === fVal){
              selectedFilters.chef.items.splice(i, 1);
            }
          });

          this.setState({ selected: { ...this.state.selected, chef: selectedFilters.chef.items} }, () => {

            console.log(this.state.selected.chef);
            console.log(this.state.selected.chef.items);
           // this.selectChefRef.current.setValue([]);
            this.selectChefRef.current.setValue(this.state.selected.chef);
          });

         //
         

          break;
      
        case "category":
          this.setState({ selected: { ...this.state.selected, category: {}} });
          selectedFilters.category = {};
          break;

        case "varity":
          /* this.setState({ selected: { ...this.state.selected, varity: {}} });
          selectedFilters.varity = {}; */

          selectedFilters.varity.items.map((tag, i) => {
            if(tag.value === fVal){
              selectedFilters.varity.items.splice(i, 1);
            }
          });

          this.setState({ selected: { ...this.state.selected, varity: selectedFilters.varity.items} }, () => {
            this.selectVarityRef.current.setValue(this.state.selected.varity);
          });

          console.warn(this.state.selected);

          break;

        case "range":
          /* this.setState({ selected: { ...this.state.selected, range: {}} });
          selectedFilters.range = {}; */

          selectedFilters.range.items.map((tag, i) => {
            if(tag.value === fVal){
              selectedFilters.range.items.splice(i, 1);
            }
          });

          this.setState({ selected: { ...this.state.selected, range: selectedFilters.range.items} }, () => {
            this.selectRangeRef.current.setValue(this.state.selected.range);
          });


          break;

        case "sort":
          this.setState({ selected: { ...this.state.selected, sort: {}} });
          selectedFilters.sort = {};
          break;

        case "tags": 

          selectedFilters.tags.items.map((tag, i) => {
              if(tag.value === fVal){
                selectedFilters.tags.items.splice(i, 1);
              }
          });

          //console.log(selectedFilters.tags.items);
          //this.selectRef.current.setValue([]);
          //this.selectRef.current.setValue(selectedFilters.tags.items);

         
      
         // this.setState({ selected: { ...this.state.selected, size: {}} });
          //selectedFilters.size = {};
          break;
      
        default:
          break;
      }
      
      this.updateSelection(selectedFilters);

    }

    clearSelection(){
      this.selectChefRef.current.clearValue();
      this.selectRangeRef.current.clearValue();
      this.selectVarityRef.current.clearValue();
      this.setState({ selected:{
        chef: {},
        varity: {},
        sort: {},
        category: {},
        range: {},
        tags:{}
      }});

    
      this.updateSelection(null);
    }


    render() {
      const { filterOpt, optChefs, optRanges, optVarities, activeFilter, tags, selected } = this.state;
      return (
        <div className='z-10 relative '>
          <div className="flex items-center text-body"> 
            {
              
              this.showMenu === true &&
              <AssetsMenu />
               
            }
                <div  ref={this.wrapperRef} className='filters flex items-center'>

                    {filters.map((item, index) => {
                    return (
                    <div key={index} className={`relative`}>
                        <span onClick={this.toggleSubMenu} data-key={item.key} className={ `px-1 md:px-4 group cursor-pointe transition-all duration-300 border-b-4 py-3 border-transparent cursor-pointer block ${item.key === activeFilter ? "text-primary" : "text-body "}` }>
                        <div className="flex  items-center">
                            <div className="menu-icon text-xl opacity-50">
                                {item.icon}
                            </div>
                            <div className="menu-text text-sm ml-1.5 ">
                                {item.label}
                            </div>
                            <MdArrowDropDown className={`text-lg text-body/50 transition-all duration-300  ${item.key === activeFilter ? "rotate-180" : ""}`} />
                        </div>
                        </span>
                       {/*  {
                            item.key === 'tags' &&
                            <div className={`filter-popup bg-white absolute z-10 w-[330px] shadow-xl rounded-lg px-3   ${'tags' === activeFilter ? "block" : "hidden"}`}>
                            <Select
                                ref={this.selectRef}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                hideSelectedOptions={false}
                                value={this.state.selected.tags.items}
                                maxMenuHeight={200}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                placeholder='Search...'
                                components={{ Option: CustomOption, Control  }}
                                isMulti
                                options={tags}
                                onChange={this.selectTags}
                            />
                        </div>
                        } */}
                        {
                            item.key === 'chef' &&
                        <div className={`filter-popup bg-white absolute left-0 z-10  w-[300px] md:w-[330px] shadow-2xl rounded-lg px-3 max-h-80 overflow-auto ${'chef' === activeFilter ? "block" : "hidden"}`}>
                             <Select
                                ref={this.selectChefRef}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                hideSelectedOptions={false}
                                value={this.state.selected.chef.items}
                                maxMenuHeight={200}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                placeholder='Search...'
                                components={{ Option: CustomOption, Control  }}
                                isMulti
                                options={optChefs}
                                onChange={(e) => this.selectOptions(e, 'chef')} 
                                onMenuScrollToBottom={() => this.handleMenuScrollToBottom('getRecipeChefs')} 
                            />
                         
                        </div>
                        }
                        {
                            item.key === 'range' &&
                        <div className={`filter-popup bg-white absolute  right-0 md:right-auto md:left-0 z-10 w-[300px] md:w-[330px]  shadow-2xl rounded-lg px-3 max-h-80 overflow-auto ${'range' === activeFilter ? "block" : "hidden"}`}>
                            {
                            optRanges.length > 0 &&


                            <Select
                                ref={this.selectRangeRef}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                hideSelectedOptions={false}
                                value={this.state.selected.range.items}
                                maxMenuHeight={200}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                placeholder='Search...'
                                components={{ Option: CustomOption, Control  }}
                                isMulti
                                options={optRanges}
                                onChange={(e) => this.selectOptions(e, 'range')} 
                                onMenuScrollToBottom={() => this.handleMenuScrollToBottom('getRecipeRanges')} 
                            />
                            
                           /*  optRanges.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.value} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            }) */
                            }
                        </div>
                        }
                        {
                            item.key === 'varity' &&
                        <div className={`filter-popup bg-white absolute right-0 md:right-auto md:left-0 z-10 w-[240px] md:w-[330px]  shadow-2xl rounded-lg px-3 max-h-80 overflow-auto ${'varity' === activeFilter ? "block" : "hidden"}`}>
                            {
                            optVarities.length > 0 &&

                            <Select
                                ref={this.selectVarityRef}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                hideSelectedOptions={false}
                                value={this.state.selected.varity.items}
                                maxMenuHeight={200}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                closeMenuOnSelect={false}
                                placeholder='Search...'
                                components={{ Option: CustomOption, Control  }}
                                isMulti
                                options={optVarities}
                                onChange={(e) => this.selectOptions(e, 'varity')} 
                                onMenuScrollToBottom={() => this.handleMenuScrollToBottom('getRecipeVarities')} 
                            />

                            /* optVarities.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.value} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            }) */
                            }
                        </div>
                        }
                        {
                            item.key === 'category' &&
                        <div className={`filter-popup bg-white absolute right-0 z-10 shadow-2xl rounded-lg w-28 px-3 ${'category' === activeFilter ? "block" : "hidden"}`}>
                            {
                            filterOpt.category &&
                            filterOpt.category.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.value} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            })
                            }
                        </div>
                        }
                        {
                            item.key === 'sort' &&
                        <div className={`filter-popup bg-white absolute left-0 z-10 shadow-2xl rounded-lg px-3 ${'sort' === activeFilter ? "block" : "hidden"}`}>
                            {
                            filterOpt.sort &&
                            filterOpt.sort.map((opt, index) => {
                                return (
                                    <span onClick={this.seletFilterItem} key={index} data-type={item.key} data-label={opt.label} data-id={opt.code} className="whitespace-nowrap rounded-lg  mb-2 text-sm font-medium cursor-pointer block hover:text-primary">
                                    {opt.label}
                                    </span>
                                );                         
                            })
                            }
                        </div>
                        }
        
                    </div>
                    );
                    })}        
                </div>
          </div> 
            {
             (Object.keys(selected.tags).length > 0 ||  Object.keys(selected.chef).length > 0 ||  Object.keys(selected.category).length > 0 ||  Object.keys(selected.sort).length > 0 ||  Object.keys(selected.varity).length > 0  ||  Object.keys(selected.range).length > 0 ) &&
             <div className='filtered-opt  mt-4 md:mt-8 flex items-center flex-wrap container mx-auto'>
              <span className='mr-4 text-sm md:text-base'>Resulats showing for:</span>
              {
                Object.keys(selected).map((item, index) => {
              
                  return (
                    <div className="flex items-center" key={index}>
                      <>
                      {
                        (selected[item].type === 'category' ||  selected[item].type === 'sort') && Object.keys(selected[item]).length > 0 && 
                        <span key={index} className="bg-ash rounded-lg py-1 md:py-2 px-1.5 md:px-4  my-1 md:my-0 md:mb-1.5 mx-1.5 text-xs md:text-base font-medium flex items-center" >
                          <span dangerouslySetInnerHTML={{__html : selected[item].label}}></span>
                          <span  onClick={this.clearSelectedItem} data-type={selected[item].type} data-id={selected[item].code} className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                        </span>
                      }
                      </>
                      <>
                      {
                        (selected[item].type !== 'category' ||  selected[item].type !== 'sort')  && selected[item].items && selected[item].items.length > 0  && 
                        selected[item].items.map((tag, index) => {
                          return (
                          <span key={index} className="bg-ash rounded-lg py-1 md:py-2 px-1.5 md:px-4  my-1 md:my-0 md:mb-1.5 mx-1.5 text-xs md:text-base font-medium flex items-center" >
                            <span>{tag.label}</span>
                            <span onClick={this.clearSelectedItem} data-type={selected[item].type} data-id={tag.value}  className='remove-selection cursor-pointer ml-1.5 hover:text-red-500'><MdOutlineClose /></span>
                          </span>
                          )
                        })
                      }
                      </>
                    </div>
                  )
                })
              }
              <span  onClick={this.clearSelection}  className='ml-4 cursor-pointer hover:text-primary text-sm md:text-base'>Clear</span>
             </div>
            }
        </div>
    
    
      );
    }
}

export default RecipeFilter;
  
  