import React, { Component } from 'react';
import { Link  } from "react-router-dom";
import { BsCalendar3, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdAccessTime } from "react-icons/md";
import { RiMapPin2Line } from "react-icons/ri";
import { GrRestaurant } from "react-icons/gr";
import { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { api } from "../Settings";
import axios from 'axios';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FiCoffee, FiEdit } from 'react-icons/fi';
import { GiMeal, GiWaterBottle } from 'react-icons/gi';
import { FaCaretRight } from 'react-icons/fa';
const dayjs = require('dayjs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class FeaturedRecipeSlider extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }

        this.formatDate = this.formatDate.bind(this);
    }

    componentDidMount () {
        axios.get(api.getFeaturedRecipes).then(response => {
          this.setState({
            data: response.data
          })
        })
    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }
  
    

  render() {
    const { data } = this.state;
    return (
    <div className='slider_recipe relative z-0 mt-8 mb-4'>
    <h2  className="text-2xl md:text-3xl mb-8 font-semibold">Featured Recipes</h2>
      { data && data.items &&
          <div className={`relative pb-14`}>
             <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, A11y]}
      spaceBetween={30}
      slidesPerView={1}
      navigation={{
        nextEl: ".slider_recipe .image-swiper-button-next",
        prevEl: ".slider_recipe .image-swiper-button-prev",
        disabledClass: "opacity-50 cursor-default"
      }}
      breakpoints={{
        1280: {
          slidesPerView: 2,
        },
      }}
      pagination={false}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
                {
                  data.items.map((item, index) => {
                    return(
                      <SwiperSlide key={index} className="md:flex  pb-4 md:pb-0 overflow-hidden">

                      <div className={`relative rounded-xl bg-gray-300 bg-no-repeat bg-cover bg-center group w-full md:min-w-[276px] md:w-[276px] h-[240px] md:h-[276px]`} style={{ backgroundImage: `url("${item.image}")` }}> <Link to={`/recipe/${item.hash}`} className="absolute w-full h-full"></Link></div>
                        <div className='w-full md:w-[calc(100%_-_276px)] md:pl-6 pt-2 md:pt-0 flex flex-wrap'>
                            <h3 className="w-full text-xl md:text-2xl line-clamp-2 max-h-16"><Link to={`/recipe/${item.hash}`} className="block">{item.title}</Link></h3>
                            <div className='w-full text-sm  mt-3'>
                                      {
                                          item.chefs && item.chefs.length > 0 &&
                                            <span className='text-body/50 font-medium flex items-start mb-1'>
                                              <GrRestaurant className='mr-2 text-lg stroke-primary ' />
                                              <span className=''>
                                              {
                                                item.chefs.map((obj, index) => {
                                                  return(
                                                    <span  key={index} className='inline-block'>
                                                      <span>{obj.label}</span>
                                                      {
                                                        index !== (item.chefs.length - 1 ) &&
                                                        <span className='mr-0.5'>,</span>
                                                      }
                                                    </span>
                                                  )}
                                                )
                                              }
                                              </span>
                                            </span>
                                      }
                                      {
                                          item.category && item.category.length > 0 &&
                                            <div className='text-body/50 font-medium mb-1 flex items-start'>
                                              <GiMeal className='mr-2 text-lg text-primary inline-block' />
                                              <div className='truncate'>
                                              Category&nbsp;-&nbsp;
                                                  {
                                                    item.category.map((obj, index) => {
                                                      return(
                                                        <React.Fragment key={index}>
                                                          {obj.label}
                                                          {
                                                            index !== (item.category.length - 1 ) &&
                                                            <>/</>
                                                          }
                                                        </React.Fragment>
                                                      )}
                                                    )
                                                  }
                                              </div>
                                            </div>
                                      }
                                      {
                                          item.variety && item.variety.length > 0 &&
                                            <div className='text-body/50 font-medium mb-1 flex items-start'>
                                              <GiWaterBottle className='mr-2 text-lg text-primary inline-block' />
                                              <div className='truncate'>
                                              Variety&nbsp;-&nbsp;
                                                  {
                                                    item.variety.map((obj, index) => {
                                                      return(
                                                        <React.Fragment key={index}>
                                                          {obj.label}
                                                          {
                                                            index !== (item.variety.length - 1 ) &&
                                                            <>/</>
                                                          }
                                                        </React.Fragment>
                                                      )}
                                                    )
                                                  }
                                              </div>
                                            </div>
                                      }
                                      {
                                          item.festivals && item.festivals.length > 0 &&
                                            <div className='text-body/50 font-medium mb-1 flex items-start'>
                                              <FaCaretRight className='mt-0.5 mr-2  min-w-[16px] text-base text-primary inline-block' />
                                              <div className='truncate'>
                                              Festival Names&nbsp;-&nbsp;
                                                  {
                                                    item.festivals.map((obj, index) => {
                                                      return(
                                                        <React.Fragment key={index}>
                                                          {obj.label}
                                                          {
                                                            index !== (item.festivals.length - 1 ) &&
                                                            <>/</>
                                                          }
                                                        </React.Fragment>
                                                      )}
                                                    )
                                                  }
                                              </div>
                                            </div>
                                      }
                                      {/* {
                                          item.festivals && item.festivals.length > 0 &&
                                            <span className='text-body/50 font-medium flex items-start mb-1'>
                                              <FaCaretRight className='mt-0.5 mr-2 text-base text-primary ' />
                                              <span className='mr-0.5 whitespace-nowrap'>Festival Names -</span>
                                              <span className=''>
                                                {
                                                  item.festivals.map((obj, index) => {
                                                    return(
                                                      <span className='inline-block'>
                                                        <span>{obj.label}</span>
                                                        {
                                                          index !== (item.festivals.length - 1 ) &&
                                                          <span className=''>/</span>
                                                        }
                                                      </span>
                                                    )}
                                                  )
                                                }
                                              </span>
                                            </span>
                                      } */}
                                      {
                                          item.teas && item.teas.length > 0 &&
                                            <div className='text-body/50 font-medium mb-1 flex items-start'>
                                              <FaCaretRight className='mt-0.5 mr-2 min-w-[16px] text-base text-primary inline-block' />
                                              <div className='truncate'>
                                                Tea Used&nbsp;-&nbsp;
                                                  {
                                                    item.teas.map((obj, index) => {
                                                      return(
                                                        <React.Fragment key={index}>
                                                          {obj.label}
                                                          {
                                                            index !== (item.teas.length - 1 ) &&
                                                            <>/</>
                                                          }
                                                        </React.Fragment>
                                                      )}
                                                    )
                                                  }
                                              </div>
                                            </div>
                                      }

                            </div>
                            { item.hash && 
                                <div className='w-full self-end '>
                                    <Link to={`/recipe/${item.hash}`} className="inline-block mt-4 btn text-primary border border-primary hover:bg-primary transition-all duration-300  py-2.5 px-6 rounded-md text-sm hover:text-white">
                                        View Recipe
                                    </Link>
                                </div>
                            }
                        </div>
                          {
                              EDIT_ACCESS && item.editLink &&
                              <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                          }
                      </SwiperSlide>
                    )
                  })
                }
                </Swiper>
                <div className='swiper-container-pagination text-center mt-2'></div>
                <div className='flex gap-2 absolute right-0 bottom-0 z-10'>
                    <span className='image-swiper-button-prev w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronLeft /></span>
                    <span className='image-swiper-button-next  w-12 h-12 rounded-full bg-ash flex items-center justify-center text-primary text-xl'><BsChevronRight /></span>
                </div>

        </div>
      }
      </div>
    );
  }
}

export default FeaturedRecipeSlider;