import React, { Component } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation  } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroller';
import { api } from "../Settings";
import ApiError from "./../compornents/ApiError";
import EmptyData from "./EmptyData";
import Loader from "./../compornents/Loader";
import { FaRegCommentAlt, FaRegThumbsUp, FaThumbsUp } from 'react-icons/fa';

const dayjs = require('dayjs');
const qs = require('qs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
const ASSCESS_TOKEN = (userData) && userData.data.access_token;

function withParams(Component) {
    return props => <Component {...props} navigation={useNavigate()} location={useLocation()} />;
}

class ForumFeaturedFeeds extends Component {

    constructor (props) {
        super(props);
        this.state = {
            feeds: [],
            title:null,
            total:0,
            hasMore: true,
            loading: false,
            isLoading: false,
            isError: false,
            loadedCount:0,
            pageSize: 25,
            baseUrl: null,
        }

        this.state.title = props.title;
        this.getDiff = this.getDiff.bind(this);
        this.getData = this.getData.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
        this.setFilePaths = this.setFilePaths.bind(this);
      
    }

    componentDidMount () {

        const that = this;
        that.setState({ isLoading: true, isError: false })

        this.getData(0, true);

    }

    getData(count, reset){

        const that = this;
        const { navigation } = this.props;
        const { pathname  } = this.props.location;

        if (this.state.loading) {
            return;
        }

        if (this.state.loadedCount !== 0 && this.state.total < this.state.loadedCount ) {
            this.setState({ hasMore: false })
            return;
        }else{
            this.setState({ hasMore: true })
        }
        
        this.setState({ loading: true })

        if(count === 0 ){
            this.setState({ loadedCount: 0  })
        }

        if(reset){
          that.setState({ isLoading: true, isError: false })
        }

        axios.get(api.getForumFeedItems, { params: { gf: 1, lt:  this.state.pageSize,  st: this.state.loadedCount } }).then( response => {

            //Set total
            const tot = response.data.total ? response.data.total : response.data.items.length;
            this.setState({ total: tot });

            if(response.data.baseUrl && response.data.baseUrl !== ""){

                this.setState({
                  baseUrl: response.data.baseUrl
                });
                
            }
            
            
            this.setState({ isLoading: false });

            this.setState({ feeds: [ ...this.state.feeds, ...response.data.items ] },() => {

                this.setState({ isLoading: false, loading: false });
  
            //    if(!reset){
                    const newCount = this.state.pageSize + this.state.loadedCount;
                    //console.log(newCount);
                    this.setState({ loadedCount: newCount })
             //   }
  
            });
        })
        .catch(function (error) {

            if (error.response && error.response.status === 401 ) {
                navigation("/logout", { state: { previousPath: pathname } } );
                navigation(0);
            }

            

            that.setState({ isLoading: false, isError: true , loading: false })
        });
        
    }

    getDiff(param) {

        if( dayjs(param).isValid()){
            const evtDate = dayjs(param);
            const today = dayjs();
    
            let hours = today.diff(evtDate, 'hours');
            const days = Math.floor(hours / 24);
            hours = hours - (days * 24);
    
            if(days === 0){
                return ( <>{hours} hours ago</>);
            }else{
                return dayjs(param).format('MMMM DD, YYYY h:mm A');
            }
        }
    }

    setFilePaths(content){

        if(!content){ return }

        const baseUrl = this.state.baseUrl;

        content = content.replace(/="\/forum(.*?)\"/g, function (matched, str) {
            return `="${baseUrl}/forum${str}&access_token=${ASSCESS_TOKEN}"`;
        });

        return content;
    }
    
    changeStatus(item){

        let id = (item.postId) ? item.postId : item.commentId;
        let fav = item.isFavorite;
        let baseUrl = this.state.baseUrl;
        let f = (fav === "1") ? "1" : "0";
        let fCount = item.favCount ? Number(item.favCount) : 0;
        const that = this;

        this.setState({ loadingData: true });

        axios.post( api.forumChangeFavoriteStatus , qs.stringify({ fav : f , pid: id, is_token_base: 1 }) ).then(response => {

           // console.log(response.data);
            if(response.data && response.data.success){
                item.isFavorite = (fav === "1") ? "0" : "1";
                item.favCount = (fav === "1") ? fCount - 1 :  fCount + 1;
            }

            this.setState({ loadingData: false });

        })
        .catch(function (error) {
         // console.log(error);
          that.setState({ loadingData: false });
        });

    }

    render() {
        const { title, feeds, isLoading, isError, total, hasMore } = this.state;
        return (
            <>
             {
                <div className="text-body">
                    {
                        title && 
                        <h3 className="text-2xl md:text-3xl mt-4 md:mt-0 mb-8 font-semibold">{title}</h3>
                    }
                    <div className="items">
                    {isLoading ? <Loader /> : <>     
                        {
                            total === 0 && !isError &&
                            <EmptyData/>
                        }
                        {                
                            feeds && feeds.length > 0 && total > 0 &&
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={this.getData}
                                initialLoad={false}
                                hasMore={hasMore}
                                loader={<div className="loader" key={0}><Loader /></div>}
                            >
                                {   
                                    feeds.map((item, index) => {
                                        return (
                                        <div key={index} className="mb-8 shadow-lg rounded-xl px-4 lg:px-8 py-4">
                                    
                                            <div className="item-content text-sm">
                                                <h3 className="my-4  block font-semibold text-base  md:text-2xl">{item.title}</h3>
                                                <div className="flex items-center mb-4">
                                                    <div className="user-icon min-w-[48px] w-[48px] h-[48px] rounded-full bg-gray-500 mr-3 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: `url("${item.userImage}&access_token=${ASSCESS_TOKEN}")` }}></div>
                                                    <div className="">
                                                        <span className="block user-name font-semibold mb-1">{item.userName}</span>
                                                        <span className="block updated-time text-body/30"> {this.getDiff(item.postDate)}</span>
                                                    </div>
                                                </div>
                                                <div className='my-2 text-primary flex items-center'>
                                                    {
                                                        item.tags.map((tag, index) => {
                                                            return (
                                                                <span key={index} className="m-1">#{tag}</span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                                <div dangerouslySetInnerHTML={{__html : this.setFilePaths(item.post)}}>
                                                </div>
                                            </div>
                                            <div className="flex items-center mt-4 md:my-4 pt-4">
                                               
                                                <div className="flex items-center text-sm">
                                                    
                                                    <span  onClick={() => this.changeStatus(item)}  className='text-sm flex items-center text-primary bg-primary/10 rounded-lg h-10 py-2.5 px-4 cursor-pointer hover:bg-primary/20'>
                                                        {
                                                            item.isFavorite === "1" &&
                                                            <FaThumbsUp className='text-primary/80 w-4 h-4' />
                                                        }
                                                        {
                                                            item.isFavorite !== "1" &&
                                                            <FaRegThumbsUp className='text-primary/80 w-4 h-4' />
                                                        }
                                                        {
                                                            item.favCount !== "" && item.favCount > 0 &&
                                                            <span className='ml-2'>{item.favCount}</span>
                                                        }
                                                    </span>
                                                    <Link to={`/forum/${item.postId}?reply=1`} className="text-sm flex items-center text-primary bg-primary/10 rounded-lg h-10 py-2.5 px-4 ml-2 cursor-pointer hover:bg-primary/20">
                                                        <FaRegCommentAlt className='w-4 h-4 mr-2  text-primary/80' />
                                                        {
                                                            item.totalChild && item.totalChild !== "" &&
                                                            <span className='mr-2'>{item.totalChild}</span>
                                                        }
                                                        <span>Join to Discussion</span>
                                                       
                                                    </Link>

                                                </div>
                                            </div>
                                        </div>
                                        );
                                    })
                                }
                            </InfiniteScroll>
                        }
                    </>}
                    {isError && <ApiError />}
                    </div>
                </div>
            }
            </>
        );
    }
}

export default withParams(ForumFeaturedFeeds);