import React, { Component } from 'react';
import { FiEdit } from 'react-icons/fi';
import { Link  } from "react-router-dom";
const dayjs = require('dayjs');

const userData =  JSON.parse(localStorage.getItem("_userData"));
let EDIT_ACCESS = userData && userData.dash_admin ? true : false; 


class FeaturedNews extends Component {

    constructor (props) {
        super(props)
    
        this.state = {
          data: []
        }

        this.formatDate = this.formatDate.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
      return {data: props.data };
    }

    formatDate(param) {
        return dayjs(param).format('MMMM DD, YYYY')
    }
    

  render() {
    const { data } = this.state;
    return (
      <>
      { data && data.items &&
          <div className={`mb-12`}>
            <div className="grid gap-4 grid-cols-2 md:grid-cols-4 overflow-hidden rounded-2xl">
                {
                  data.items.map((item, index) => {
                      return (
                        <div key={index} className={`relative bg-gray-300 bg-no-repeat bg-cover bg-center group ${index !== 0 ? "pb-[200px]" : ""} ${index === 0  || index === 3 ? "md:col-span-2" : "md:col-span-1"} ${index === 0 ? "md:row-span-2" : "md:row-span-1"}`} style={{ backgroundImage: `url("${item.image}")` }}>
                          <Link to={`/news/${item.hash}`} className="w-full h-full absolute top-0 left-0">
                            <div className='bottom-0 left-0 absolute w-full p-2 md:pb-2 md:pt-6 md:px-4 group-hover:pb-4 group-hover:from-body/50 duration-300 transition-all  text-white from-body/20 bg-gradient-to-t'>
                                <span className='text-sm inline-block mb-0.5'>{this.formatDate(item.date)}</span>
                                <h3 className="text-base md:text-lg">{item.title}</h3>
                            </div>
                          </Link>
                          {
                              EDIT_ACCESS && item.editLink &&
                              <a href={item.editLink.replace("ITEM_ID", item.hash)} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm editMode'><FiEdit className='text-lg' title="Edit" /></a>
                          }
                        </div>
                      );
                  })
                }
            </div>
        </div>
      }
      </>
    );
  }
}

export default FeaturedNews;